import { ReactNode, useEffect, useState } from "react";

interface LoadingTextProps {
  text: string;
  className?: string;
}
const LoadingText = ({ text, className }: LoadingTextProps) => {
  // states
  const [ellipsis, setEllipsis] = useState<string>("...");

  useEffect(() => {
    const interval = setInterval(() => {
      setEllipsis((prev: string) => {
        if (prev === "...") return ".";
        if (prev === ".") return "..";
        if (prev === "..") return "...";
        return "...";
      });
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <p className={className}>
      {text}
      <span className={className}>{ellipsis} </span>
    </p>
  );
};

export default LoadingText;
