import { IconType } from "../icon-props";

const Happy: IconType = (
  <>
    <g clipPath="url(#clip0_2382_38464)">
      <path d="M16 0C24.8249 0 32 7.17691 32 16.0039C32 24.8231 24.8249 32 16 32C7.17514 32 0 24.8231 0 15.9961C0.00789344 7.17691 7.18303 0 16 0ZM30.3187 15.9961C30.3187 8.10067 23.8934 1.68172 16.0079 1.68172C8.10656 1.68172 1.6892 8.10067 1.6892 15.9961C1.6892 23.8914 8.10656 30.3104 16 30.3104C23.8934 30.3183 30.3187 23.8914 30.3187 15.9961Z" />
      <path d="M23.491 12.2537C23.6963 12.6722 23.5305 13.1775 23.1121 13.3828C22.7017 13.5881 22.1886 13.4302 21.9834 13.0038C21.565 12.159 21.0125 11.7879 20.2153 11.7879C19.4101 11.7879 18.8576 12.1669 18.4392 13.0038C18.234 13.4223 17.7209 13.5881 17.3105 13.3828C16.8921 13.1775 16.7264 12.6722 16.9316 12.2537C17.6341 10.8484 18.7708 10.1062 20.2153 10.1062C21.6519 10.1062 22.7885 10.8484 23.491 12.2537Z" />
      <path d="M22.5519 19.6752C22.8835 20.0068 22.8835 20.5358 22.5519 20.8674C20.7444 22.6755 18.3763 23.5755 16.0004 23.5755C13.6245 23.5755 11.2564 22.6676 9.44884 20.8674C9.11731 20.5358 9.11731 20.0068 9.44884 19.6752C9.78036 19.3436 10.3092 19.3436 10.6407 19.6752C13.5929 22.6281 18.4079 22.6281 21.36 19.6752C21.6916 19.3436 22.2204 19.3436 22.5519 19.6752Z" />
      <path d="M15.0687 12.2537C15.2819 12.6722 15.1082 13.1775 14.6899 13.3828C14.2794 13.5881 13.7663 13.4302 13.5611 13.0038C13.1427 12.159 12.5902 11.7879 11.793 11.7879C10.9878 11.7879 10.4353 12.1669 10.0169 13.0038C9.80381 13.4223 9.30653 13.5881 8.88817 13.3828C8.47771 13.1775 8.30406 12.6722 8.50929 12.2537C9.2118 10.8484 10.3485 10.1062 11.793 10.1062C13.2296 10.1062 14.3662 10.8484 15.0687 12.2537Z" />
    </g>
    <defs>
      <clipPath id="clip0_2382_38464">
        <rect width="32" height="32" fill="white" />
      </clipPath>
    </defs>
  </>
);

export default Happy;
