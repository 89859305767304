import { Transition, Menu } from "@headlessui/react";
import { Fragment, MutableRefObject, RefObject } from "react";
import Icon from "../icons/icon";
import { Option } from "./types";
import RecursiveMenuItems from "./recursive-menu-items";
import User from "../icons/lib/user";

interface MenuItemsProp {
  options: Option[];
  onItemClick?: (option: Option) => void;
  dropdownRef?: RefObject<HTMLDivElement>,
  onMouseEnter?: (open: boolean) => void,
  onMouseLeave?: (open: boolean) => void,
  open?: boolean,
  useHover?: boolean,
  selectedOption?: string;
  position?:
    | "top-left"
    | "top-right"
    | "bottom-left"
    | "bottom-right"
    | "right-top"
    | "right-bottom"
    | "left-top"
    | "left-bottom";
  className?: string;
}

const MenuItems = ({
  position,
  options,
  selectedOption,
  onItemClick,
  dropdownRef,
  onMouseEnter,
  onMouseLeave,
  useHover = false,
  open,
  className,
}: MenuItemsProp) => {
  const renderOption = (option: Option, index: number) => {
    return (
      <Menu.Item key={`option-${index}`}>
        <button
          id={`dropdown-button-option-${index}`}
          onClick={() => !option.children && onItemClick?.(option)}
          className={`navigation-button-${index} flex h-big w-full items-center px-4 hover:bg-backgroundLight group/parent first:rounded-t-[14px] last:rounded-b-[14px]`}
        >
          {option.icon === User ? (
            <div className="h-[20px] w-[20px] min-w-[20px] mr-3">
              <div className="flex justify-center items-center h-full w-full rounded-full">
                <Icon icon={option.icon} className="h-full w-full" />
              </div>
            </div>
          ) : (
            <Icon
              icon={option.icon}
              className={`h-[20px] w-[20px] min-w-[20px] mr-3 ${
                option.name === "Audiolab" && "rotate-90"
              }`}
            />
          )}
          <p
            className={
              selectedOption !== option.name ? "font-light" : "font-bold"
            }
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {option.name}
          </p>
          {option.children && (
            <RecursiveMenuItems option={option} onItemClick={onItemClick} />
          )}
        </button>
      </Menu.Item>
    );
  };

  const getPosition = () => {
    switch (position) {
      case "top-right":
        return "bottom-10 left-0";
      case "top-left":
        return "bottom-10 right-0";

      case "bottom-right":
        return "top-10 left-0";
      case "bottom-left":
        return "top-10 right-0";

      case "right-top":
        return "bottom-0 left-10";
      case "right-bottom":
        return "top-0 left-10";

      case "left-top":
        return "bottom-0 right-10";
      case "left-bottom":
        return "top-0 right-10";
    }
  };

  return (
    <Transition
      as={Fragment}
      enter="transition ease-out duration-100"
      enterFrom="transform opacity-0 scale-95"
      enterTo="transform opacity-100 scale-100"
      leave="transition ease-in duration-75"
      leaveFrom="transform opacity-100 scale-100"
      leaveTo="transform opacity-0 scale-95"
    >
      <Menu.Items
        id="dropdown-items"
        ref={dropdownRef}
        onMouseEnter={() => useHover && onMouseEnter!(!open!)}
        onMouseLeave={() => useHover && onMouseLeave!(open!)}
        static
        className={`absolute w-56 z-20 origin-top-right ${getPosition()}
        divide-y divide-grayLight rounded-2xl bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none overflow-visible ${className}`}
      >
        {options.map((item, index) => renderOption(item, index))}
      </Menu.Items>
    </Transition>
  );
};

export default MenuItems;
