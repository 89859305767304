import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useEffect, useState } from "react";
import { isSoundkit } from "../../lib/helpers";
import { Beat, CartItem, SoundKit } from "../../models/models";
import Icon from "../icons/icon";
import PricingInfo from "./pricing-info";
import PricingSoundkit from "./pricing-soundkit";
import ArrowLeft from "../icons/lib/arrow-left";
import { useAudioPlayer } from "@/src/contexts/AudioPlayerContext";

type props = {
  item: Beat | SoundKit;
  cartInfo?: CartItem;
  isProductDetail?: boolean;
  isOpen: boolean;
  closeModal: () => void;
  closeMessage: string;
};

const PricingPopup = ({
  item,
  cartInfo,
  isOpen,
  closeModal,
  closeMessage,
  isProductDetail,
}: props) => {
  const { playing, playerVisible, setPlaying, openPlayer } = useAudioPlayer();

  const [audioPlayerPlaying, setAudioPlayerPlaying] = useState<{
    visible: boolean;
    playing: boolean;
  }>({ visible: false, playing: false });

  useEffect(() => {
    if (isOpen) {
      if (playing) {
        setAudioPlayerPlaying({
          visible: true,
          playing: true,
        });
        setPlaying(false);
      } else if (playerVisible) {
        setAudioPlayerPlaying({
          visible: true,
          playing: false,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const onCloseFunction = () => {
    if (audioPlayerPlaying.visible) {
      openPlayer();
    }
    if (audioPlayerPlaying.playing) {
      setPlaying(true);
    }
    closeModal();
  };

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-[200000000]"
        onClose={onCloseFunction}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25 backdrop-blur-sm" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex h-full items-center justify-center text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-100"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-100"
            >
              <Dialog.Panel className="flex flex-col w-full h-full md:h-auto lg:w-1/2 md:max-w-[572px] transform overflow-hidden bg-background md:bg-transparent pt-[30px] pb-6 text-left transition-all">
                <div
                  className="flex space-x-4 md:invisible pb-5 border-b-[1px] border-b-grayLight px-4"
                  onClick={onCloseFunction}
                >
                  <Icon icon={ArrowLeft} color="#111" />
                  <Dialog.Title
                    as="h4"
                    className="text-lg font-bold leading-6 text-gray-900"
                  >
                    {closeMessage}
                  </Dialog.Title>
                </div>

                <div className="flex px-4 mt-6 h-full pb-20">
                  {isSoundkit(item) ? (
                    <PricingSoundkit
                      soundkit={item}
                      actionOnAddToCart={onCloseFunction}
                    />
                  ) : (
                    <PricingInfo
                      item={item}
                      isProductDetail={isProductDetail}
                      cartInfo={cartInfo}
                      actionOnAddToCart={onCloseFunction}
                      onItemSave={onCloseFunction}
                    />
                  )}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default PricingPopup;
