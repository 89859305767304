import { getArtistsCountAsync } from "@/lib/artists-manager";
import { getBeatsCountAsync } from "@/lib/beats-manager";
import { getSongsCountAsync } from "@/lib/songs-manager";
import { getSoundKitsCountAsync } from "@/lib/soundkits-manager";
import { Filter } from "@/models/models";

export type GetCountEntitiesByCategoryParams = {
  category: string;
  query: string;
  appliedFilters?: Filter[];
};

/**
 * Obtain the count of entities given a category and filters. It will select the
 * proper endpoint call with the category.
 */
export const getCountEntitiesByCategory = async ({
  category,
  query,
  appliedFilters,
}: GetCountEntitiesByCategoryParams) => {
  try {
    switch (category) {
      case "beats":
        const { count } = await getBeatsCountAsync(query, appliedFilters);
        return count as number;
      case "sounds":
        const { count: soundKitsCount } = await getSoundKitsCountAsync(
          query,
          appliedFilters
        );
        return soundKitsCount as number;
      case "songs":
        const { count: songsCount } = await getSongsCountAsync(
          query,
          appliedFilters
        );
        return songsCount as number;
      case "creators":
        const { count: artistsCount } = await getArtistsCountAsync(
          query,
          appliedFilters
        );
        return artistsCount as number;
      default:
        return null;
    }
  } catch (error) {
    console.error(error);
    return null;
  }
};
