import useSWRImmutable from "swr/immutable";
import { FilterType } from "../models/enums";
import { Filter } from "../models/models";
import api from "./api-config";
import { fetcher } from "./fetcher";

export type UseKindsParams = {
  includeAny?: boolean;
};

export const useKinds = (
  { includeAny }: UseKindsParams = { includeAny: true }
) => {
  const { data, isLoading, error } = useSWRImmutable<Filter[]>(
    "/soundkit-kinds",
    fetcher<Filter[]>()
  );

  const kinds = includeAny
    ? [
        { id: "1", name: "Any", type: FilterType.kindsType },
        ...(data ?? [])?.map((kind) => ({
          ...kind,
          type: FilterType.kindsType,
        })),
      ]
    : data?.map((kind) => ({ ...kind, type: FilterType.kindsType }));

  return {
    kinds,
    loading: isLoading,
    error,
  };
};

export const getKindsAsync = async () => {
  const res = await api.get("/soundkit-kinds");

  const kinds = res.data.map((kind: any) => {
    return {
      id: kind.id,
      name: kind.name,
      type: FilterType.kindsType,
    } as Filter;
  }) as Filter[];

  return {
    kinds: [{ id: "1", name: "Any", type: FilterType.kindsType }, ...kinds],
  };
};
