import { IconType } from "../icon-props";

const VolumeMute: IconType = (
  <>
    <path d="M10.2574 7.22002L6.86039 10H3.25739V14H6.86039L10.2574 16.78V7.22002ZM6.14639 16H2.25739C1.99217 16 1.73781 15.8947 1.55028 15.7071C1.36274 15.5196 1.25739 15.2652 1.25739 15V9.00002C1.25739 8.73481 1.36274 8.48045 1.55028 8.29291C1.73781 8.10538 1.99217 8.00002 2.25739 8.00002H6.14639L11.4404 3.66802C11.5136 3.60797 11.6024 3.56996 11.6965 3.55841C11.7905 3.54687 11.8858 3.56227 11.9714 3.60282C12.057 3.64337 12.1294 3.7074 12.18 3.78745C12.2306 3.86751 12.2574 3.9603 12.2574 4.05502V19.945C12.2574 20.0397 12.2306 20.1325 12.18 20.2126C12.1294 20.2926 12.057 20.3567 11.9714 20.3972C11.8858 20.4378 11.7905 20.4532 11.6965 20.4416C11.6024 20.4301 11.5136 20.3921 11.4404 20.332L6.14639 16Z" />
    <path d="M15.2574 9.17139L16.6716 7.75717L23.7427 14.8282L22.3285 16.2425L15.2574 9.17139Z" />
    <path d="M23.7427 9.17139L22.3285 7.75717L15.2574 14.8282L16.6716 16.2425L23.7427 9.17139Z" />
  </>
);

export default VolumeMute;
