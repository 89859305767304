import { Popover, Transition } from "@headlessui/react";
import React, { Fragment } from "react";
import SearchBarMobile from "@/components/searchbars/search-bar-mobile";

export type MobileSearchProps = {
  open?: boolean;
  onClose?: () => void;
};

const MobileSearch = ({ open, onClose }: MobileSearchProps) => {
  return (
    <Popover>
      <Transition
        as={Fragment}
        show={open}
        enter="duration-200 ease-out"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="duration-100 ease-in"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <Popover.Panel
          focus
          static
          className="fixed inset-x-0 top-[50px] origin-top-right transform z-50 transition lg:hidden"
        >
          <div className="flex flex-col bg-background h-[100dvh] shadow-lg p-4 overflow-y-auto">
            <div className="relative flex flex-col min-h-[60vh]">
              <SearchBarMobile onClose={onClose!} />
            </div>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
};

export default MobileSearch;
