import { SEARCH_QUERY_KEY } from "../constants/search-query";

export const getSearchParamsFromSearchQuery = (
  query: string,
  includeExisting = false
) => {
  const params = new URLSearchParams(
    includeExisting ? window.location.search : ""
  );
  params.set(SEARCH_QUERY_KEY, query);
  return params;
};
