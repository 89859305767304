import React from "react";
import { NavbarLink } from "./types";
import DisclosureMenu from "@/components/disclosure-menu";
import Link from "next/link";
import Icon from "@/components/icons/icon";
import { useRouter } from "next/router";
import { ClassProps } from "@/lib/types/class-props";
import { cx } from "@/lib/utils/className.utils";

export type OptionalHrefNavbarLink = Omit<NavbarLink, "href">;

export type MobileMenuLinkProps = {
  href?: string;
  onClick?: (link: OptionalHrefNavbarLink) => void;
  selectedId?: string;
} & OptionalHrefNavbarLink &
  ClassProps;

const MobileMenuLink = ({
  onClick,
  selectedId,
  className,
  ...link
}: MobileMenuLinkProps) => {
  const router = useRouter();

  const handleNavigationLinkClick = (link: NavbarLink) => {
    onClick?.(link);
  };

  if (link.subLinks) {
    return (
      <>
        <DisclosureMenu
          key={link.name}
          button={link.name}
          className={cx("bg-white", className)}
          buttonContainerClassName="pe-6"
          defaultOpen={link.expanded}
        >
          {link.subLinks.map((sublink: NavbarLink) => (
            <Link
              id={sublink.id}
              className={`py-6 flex items-center duration-300 cursor-pointer gap-3 px-12 hover:bg-gray/30 ${
                sublink.href === router.asPath || selectedId === sublink.id
                  ? "font-bold"
                  : "text-darkGreen"
              }`}
              key={sublink.name}
              onClick={() => handleNavigationLinkClick(sublink)}
              href={sublink.href ?? ""}
            >
              {sublink.prefix}
              {sublink.icon && (
                <Icon
                  icon={sublink.icon}
                  className={
                    sublink.id === "account-link-uploads" ? "rotate-90" : ""
                  }
                />
              )}
              {sublink.name}
            </Link>
          ))}
        </DisclosureMenu>
      </>
    );
  } else {
    return (
      <div key={link.name} className={cx("", className)}>
        {link.href ? (
          <Link
            id={`navigation-disclosure-expand-${link.name}`}
            href={link.href ?? ""}
          >
            <div
              className={cx(
                "flex flex-row gap-3 w-full py-6 px-6 cursor-pointer hover:bg-gray/30",
                link.href === router.asPath || selectedId === link.id
                  ? "font-bold"
                  : "text-darkGreen"
              )}
              onClick={() => handleNavigationLinkClick(link)}
            >
              {link.prefix}
              {link.icon && (
                <Icon
                  icon={link.icon}
                  className={
                    link.id === "account-link-uploads" ? "rotate-90" : ""
                  }
                />
              )}
              <div>{link.name}</div>
            </div>
          </Link>
        ) : (
          <div
            id={`navigation-disclosure-expand-container-${link.name}`}
            className={cx(
              "flex flex-row gap-3 w-full py-6 px-6 cursor-pointer hover:bg-gray/30",
              link.href === router.asPath || selectedId === link.id
                ? "font-bold"
                : "text-darkGreen"
            )}
            onClick={() => handleNavigationLinkClick(link)}
          >
            {link.prefix}
            {link.icon && (
              <Icon
                icon={link.icon}
                className={
                  link.id === "account-link-uploads" ? "rotate-90" : ""
                }
              />
            )}
            <div>{link.name}</div>
          </div>
        )}
      </div>
    );
  }
};

export default MobileMenuLink;
