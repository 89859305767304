import { SearchSorting } from "@/lib/types/search/search-sorting";
import { SortOptions } from "@/models/enums";
import { Option } from "@/models/sorting-filtering";

export const getSortingFromOption = (sorting?: Option | null) => {
  return {
    sort_isAlphabetical:
      sorting?.name === SortOptions.az
        ? true
        : sorting?.name === SortOptions.za
        ? false
        : null,
    sort_isNewest:
      sorting?.name === SortOptions.new && sorting?.value !== 1
        ? true
        : sorting?.name === SortOptions.old && sorting?.value !== 1
        ? false
        : null,
    sort_isNewest_publishing:
      sorting?.name === SortOptions.new && sorting?.value === 1
        ? true
        : sorting?.name === SortOptions.old && sorting?.value === 1
        ? false
        : null,
    sort_isCheapest:
      sorting?.name === SortOptions.cheap
        ? true
        : sorting?.name === SortOptions.expensive
        ? false
        : null,
    sort_random: sorting?.name === SortOptions.random ? sorting?.value : null,
  } as SearchSorting;
};
