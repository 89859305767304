import { CartItem, SoundKit } from "../../models/models";
import { useCart } from "../../src/contexts/CartContext";
import PrimaryButton from "../buttons/primary-button";
import toast from "react-hot-toast";
import AppLink from "@/components/app-link";
import { Icon } from "@/components/icons/icon";
import Check from "@/components/icons/lib/check";

interface PricingSoundkit {
  soundkit: SoundKit;
  actionOnAddToCart?: () => void;
  className?: string;
}

const PricingSoundkit = ({
  soundkit,
  actionOnAddToCart,
  className,
}: PricingSoundkit) => {
  // hooks
  const { addItem, cartItems } = useCart();
  const cartPayload: CartItem = {
    id: soundkit.id,
    name: soundkit.name,
    price: soundkit.price,
    cover: soundkit.cover,
    productType: "sounds",
    fullItem: soundkit,
  };

  const goToLicensingAgreement = () => {
    let name = `Sound-RFL`;
    if (soundkit.royaltyFree) {
      name = `Sound-RF`;
    }
    // open file in new tab
    window.open(`/assets/licensing-agreements/${name}.pdf`, "_blank");
  };

  const getButtonText = () => {
    return cartItems.find((item) => item.id === soundkit.id)
      ? "Added!"
      : "Add to Cart";
  };

  const handleShowAddedToCartNotification = () => {
    toast(
      <div className="flex flex-row items-center gap-2">
        <Icon icon={Check} className="fill-green stroke-green" />
        <span>License added to cart.</span>
        <AppLink href={`/cart`} className="underline text-purple">
          Checkout now!
        </AppLink>
      </div>,
      { duration: 7 * 1000, position: "top-center" }
    );
  };

  return (
    <div
      className={`flex flex-col w-full lg:w-[500px] max-h-[700px] h-[75vh] bg-white rounded-2xl pb-5 ${className}`}
    >
      {/* Included */}
      {/* <div className="flex flex-col pb-5 space-y-3 mt-5 px-5">
        <p className="text-small text-gray">INCLUDED:</p>

        {packages.map((pkg, index) => (
          <div key={`package-${index}`} className="flex space-x-2 items-center">
            <CheckIcon height={20} className="text-green" />
            <p className="text-small">{pkg} </p>
          </div>
        ))}
      </div> */}

      {/* About this kit */}
      <div className="px-5 my-5 h-full overflow-y-auto">
        <div className="flex flex-col h-[40px] sticky top-0 bg-white border-b-[1px] border-grayLight ">
          <p className=" text-medium text-gray bg-white">ABOUT THIS KIT</p>
        </div>
        <p className="text-gray mt-5">
          {soundkit.description ||
            "This soundkit doesn't have a description yet"}
        </p>
      </div>
      <div className=" h-[1px] bg-grayLight mx-5" />

      <div className="flex justify-between items-center mt-7 mb-2 px-5">
        <div className="flex flex-col">
          <h3>{`$${soundkit.price?.toFixed(2)}`} </h3>
          <button
            id="pricing-button-viewLicense"
            onClick={goToLicensingAgreement}
            className="font-evolventa mt-2 md:mt-4 font-bold text-sm md:text-base"
          >
            View sample license &gt;
          </button>
        </div>
        <PrimaryButton
          id="pricing-button-addCart"
          disabled={
            cartItems.find((cart) => cart.fullItem.id === soundkit.id)
              ? true
              : false
          }
          text={getButtonText()}
          onClick={() => {
            addItem(cartPayload);

            if (localStorage.getItem("dont-ask") === "true") {
              actionOnAddToCart && actionOnAddToCart();
            } else {
              handleShowAddedToCartNotification();
            }
          }}
        />
      </div>

      {/* Continue shopping or Checkout Modal */}
      {/* <Modal
        size="max-w-xl"
        open={continueShoppingModal}
        onClose={() => {
          setContinueShoppingModal(false);
          actionOnAddToCart && actionOnAddToCart();
        }}
        title=""
      >
        <ContinueShopping
          onNegative={() => {
            setContinueShoppingModal(false);
            actionOnAddToCart && actionOnAddToCart();
          }}
        />
      </Modal> */}
    </div>
  );
};

export default PricingSoundkit;
