import { AccountsListResponse, NavbarLink } from "../types";
import Icon from "@/components/icons/icon";
import User from "@/components/icons/lib/user";
import SafeImage from "@/components/safe-image";

export const getSubLinksFromLabelArtists = (
  labelArtists: AccountsListResponse[]
): NavbarLink[] => {
  return labelArtists.map((labelArtist) => ({
    id: labelArtist.id,
    name: labelArtist.name,
    href: "",
    prefix: (
      <SafeImage
        defaultIcon={
          <div className="flex justify-center items-center w-[28px] h-[28px] bg-gradient-to-br from-purple to-green rounded-full">
            <Icon icon={User} color="white" className="h-full w-full mx-2" />
          </div>
        }
        src={labelArtist.profilePicUrl}
        className="w-[28px] h-[28px] rounded-full"
        width={500}
        height={500}
        alt="Profile picture"
        noCache={false}
      />
    ),
  }));
};
