import { Disclosure, Transition } from "@headlessui/react";
import React, { PropsWithChildren, ReactNode } from "react";
import Icon from "./icons/icon";
import { ClassProps } from "@/lib/types/class-props";
import { cx } from "@/lib/utils/className.utils";
import ArrowDown from "./icons/lib/arrow-down";

export type DisclosureMenuProps = {
  button: ReactNode;
  buttonContainerClassName?: string;
  defaultOpen?: boolean;
} & PropsWithChildren &
  ClassProps;

const DisclosureMenu = ({
  button,
  children,
  defaultOpen,
  className,
  buttonContainerClassName,
}: DisclosureMenuProps) => {
  return (
    <Disclosure defaultOpen={defaultOpen}>
      {({ open }: { open: boolean }) => (
        <>
          <Disclosure.Button
            className={cx(
              "text-left hover:bg-gray/30 py-6 transition",
              buttonContainerClassName
            )}
          >
            <div className="flex justify-between align-baseline">
              <div className="px-6">{button}</div>

              <Icon
                icon={ArrowDown}
                className={`${
                  open ? `rotate-180 transition-all ease-linear` : null
                }`}
              />
            </div>
          </Disclosure.Button>

          <Transition
            enter="transition duration-100 ease-out"
            enterFrom="transform scale-95 opacity-0"
            enterTo="transform scale-100 opacity-100"
            leave="transition duration-75 ease-out"
            leaveFrom="transform scale-100 opacity-100"
            leaveTo="transform scale-95 opacity-0"
          >
            <Disclosure.Panel className={cx("flex flex-col", className)}>
              {children}
            </Disclosure.Panel>
          </Transition>
        </>
      )}
    </Disclosure>
  );
};

export default DisclosureMenu;
