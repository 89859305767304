import { FilterType, SortOptions } from "../models/enums";
import { Artist, FeaturedArtist, Filter } from "../models/models";
import api from "./api-config";
import { RelatedArtistsQueryFilters } from "./types/search/related-artists-query-filters";
import { SearchConfig } from "./types/search/search-config";

export const getArtistsAsyncController = new AbortController();

export const getArtistsAsync = async (
  query?: string,
  appliedFilters?: Filter[],
  sorting?: any,
  order?: boolean,
  limit?: number,
  offset?: number
) => {
  const res = await api.post<Artist[]>(
    "/artists/search",
    {
      filter: {
        name: query,
        isFeatured: appliedFilters?.some(
          (f) => f.type == FilterType.featuredType
        )
          ? true
          : null,
      },
      sorting: {
        sort_isAlphabetical:
          sorting?.name === SortOptions.az
            ? true
            : sorting?.name === SortOptions.za
            ? false
            : null,
        sort_random:
          sorting?.name === SortOptions.random ? sorting?.value : null,
        sort_isFeatured: sorting?.name === SortOptions.featured ? true : null,
      },
      limit: limit,
      offset: offset,
    },
    { signal: getArtistsAsyncController.signal }
  );

  const artists = res.data.map((artist: any) => {
    return { ...artist, type: "creators" } as Artist;
  });

  return { artists };
};

/**
 * Perform a search to obtain artists by the given filters and sorting options.
 *
 * @param config the configuration for performing this search
 * @returns an array of {@link Artist}.
 */
export const getArtists = async ({
  filter,
  sorting,
  limit,
  offset,
  includeAll,
}: SearchConfig) => {
  const res = await api.post(
    "/artists/search",
    {
      filter,
      sorting,
      limit,
      offset,
      includeAll,
    },
    { signal: getArtistsAsyncController.signal }
  );

  const artists = res.data.map((artist: any) => {
    return { ...artist, type: "creators" } as Artist;
  });
  return artists as Artist[];
};

export const getFeaturedArtists = async () => {
  const res = await api.get<FeaturedArtist[]>("/artists/featured");
  return res.data;
};

export type GetRelatedArtistsParams = {
  token?: string | null;
} & RelatedArtistsQueryFilters;

export const getRelatedArtistsController = new AbortController();

export type GetRelatedArtistsResponse = {
  artists: Artist[];
  total: number;
};

/**
 * Obtain the related artists given the identifiers for its entities.
 *
 * @param params is the params object for the filtering
 * @returns an array of {@link Artist}.
 */
export const getRelatedArtists = async ({
  filter,
  limit,
  offset,
  token,
}: GetRelatedArtistsParams) => {
  const res = await api.post<GetRelatedArtistsResponse>(
    "/artists/related",
    { filter, limit, offset },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      signal: getRelatedArtistsController.signal,
    }
  );
  return res.data;
};

export const getArtistsCountAsync = async (
  query?: string,
  appliedFilters?: Filter[]
) => {
  const res = await api.post("/artists/count", {
    filter: {
      name: query,
      isFeatured: appliedFilters?.some((f) => f.type == FilterType.featuredType)
        ? true
        : null,
    },
  });
  return { count: res.data };
};

export const getArtistByIdAsync = async (id: string) => {
  const res = await api.get(`/artists/${id}`);

  const artistJson = res.data;

  const artist = {
    // ...artistJson,
    id: artistJson.id,
    name: artistJson.name,
    firstName: artistJson.firstName,
    lastName: artistJson.lastName,
    bio: artistJson.bio,
    createdAt: artistJson.createdAt,
    profilePicUrl: artistJson.profilePicUrl,
    location: {
      city: artistJson.location?.city || null,
      state: artistJson.location?.state || null,
      country: artistJson.location?.country || null,
      zip: artistJson.location?.zip_code || null,
    },
    socialMedia: {
      youtube: artistJson.socialMedia?.youTube || null,
      instagram: artistJson.socialMedia?.instagram || null,
      twitter: artistJson.socialMedia?.twitter || null,
      facebook: artistJson.socialMedia?.facebook || null,
      tiktok: artistJson.socialMedia?.tiktok || null,
      soundcloud: artistJson.socialMedia?.soundCloud || null,
    },
  } as Artist;

  return { artist: artist };
};

export const updateArtistByIdAsync = async (
  id: string,
  artist: Partial<Artist>,
  token: string
) => {
  try {
    const updatedArtist = await api.put(
      `/artists/${id}`,
      {
        ...artist,
        profilePicUrl: artist.profilePicUrl,
        location: {
          ...artist.location,
          zip_code: artist.location?.zip,
        },
        socialMedia: {
          facebook: artist.socialMedia?.facebook,
          instagram: artist.socialMedia?.instagram,
          twitter: artist.socialMedia?.twitter,
          tiktok: artist.socialMedia?.tiktok,
          soundCloud: artist.socialMedia?.soundcloud,
          youTube: artist.socialMedia?.youtube,
        },
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const artistJson = updatedArtist.data;

    return {
      artist: {
        id: artistJson.id,
        name: artistJson.name,
        bio: artistJson.bio,
        firstName: artistJson.firstName,
        lastName: artistJson.lastName,
        createdAt: artistJson.createdAt,
        profilePicUrl: artistJson.profilePicUrl,
        location: {
          city: artistJson.location?.city || null,
          state: artistJson.location?.state || null,
          country: artistJson.location?.country || null,
          zip: artistJson.location?.zip_code || null,
        },
        socialMedia: {
          youtube: artistJson.socialMedia?.youTube || null,
          instagram: artistJson.socialMedia?.instagram || null,
          twitter: artistJson.socialMedia?.twitter || null,
          facebook: artistJson.socialMedia?.facebook || null,
          tiktok: artistJson.socialMedia?.tiktok || null,
          soundcloud: artistJson.socialMedia?.soundCloud || null,
        },
      } as Artist,
    };
  } catch (error) {
    console.log(error);
  }
};
