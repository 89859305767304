import React, { Fragment, useState } from "react";
import { Option } from "./types";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import Icon from "../icons/icon";
import { Transition } from "@headlessui/react";

export type RecursiveMenuItemsProps = {
  option: Option;
  onItemClick?: (option: Option) => void;
  setShow?: React.Dispatch<React.SetStateAction<boolean>>;
};

/**
 * Display a custom recursive function for displaying menu items with children.
 *
 * Intended for using along with DropdownMenu component (Radix UI).
 */
const RecursiveMenuItems = ({
  option,
  onItemClick,
  setShow,
}: RecursiveMenuItemsProps) => {
  const [showMenu, setShowMenu] = useState(false);

  return (
    <DropdownMenu.Sub onOpenChange={setShowMenu}>
      <DropdownMenu.SubTrigger
        id={`dropdown-button-option-${option.id}`}
        className={`navigation-button-${option.id} flex flex-row h-big w-full items-center px-4 hover:bg-backgroundLight first:rounded-t-[14px] last:rounded-b-[14px] cursor-pointer data-[state=open]:bg-backgroundLight`}
        onClick={() => {
          if (!option.children && setShow) {
            setShow(false);
          }
          onItemClick?.(option);
        }}
      >
        {option.icon && (
          <Icon
            icon={option.icon}
            className={`h-[20px] w-[20px] min-w-[20px] mr-3 ${
              option.name === "Audiolab" && "rotate-90"
            }`}
          />
        )}
        <p
          className="font-light"
          style={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          {option.name}
        </p>
      </DropdownMenu.SubTrigger>
      <DropdownMenu.Portal>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
          show={showMenu}
        >
          <DropdownMenu.SubContent
            className="overflow-hidden divide-y divide-grayLight rounded-2xl bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-[2000]"
            sideOffset={2}
            alignOffset={-5}
          >
            {option.children?.map((subOption, index) =>
              subOption.children ? (
                <RecursiveMenuItems
                  key={subOption.id}
                  option={subOption}
                  onItemClick={onItemClick}
                />
              ) : (
                <DropdownMenu.Item
                  key={subOption.id}
                  className={`recursive-button-item-${subOption.id} flex flex-row h-big w-full items-center px-4 hover:bg-backgroundLight first:rounded-t-[14px] last:rounded-b-[14px] cursor-pointer data-[state=open]:bg-backgroundLight`}
                  onClick={() => {
                    if (!subOption.children && setShow) {
                      setShow(false);
                    }
                    onItemClick?.(subOption);
                  }}
                >
                  {subOption.icon && (
                    <Icon
                      icon={subOption.icon}
                      className={`h-[20px] w-[20px] min-w-[20px] mr-3 ${
                        subOption.name === "Audiolab" && "rotate-90"
                      }`}
                    />
                  )}
                  <p
                    className="font-light"
                    style={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {subOption.name}
                  </p>
                </DropdownMenu.Item>
              )
            )}
          </DropdownMenu.SubContent>
        </Transition>
      </DropdownMenu.Portal>
    </DropdownMenu.Sub>
  );
};

export default RecursiveMenuItems;
