import { IconType } from "../icon-props";

const ArrowLeft: IconType = (
        <>
            <g clipPath="url(#clip0_621_17862)">
            <path d="M10.828 11.9997L15.778 16.9497L14.364 18.3637L8 11.9997L14.364 5.63574L15.778 7.04974L10.828 11.9997Z" />
            </g>
            <defs>
            <clipPath id="clip0_621_17862">
            <rect width="24" height="24" fill="white" transform="matrix(1 0 0 -1 0 24)"/>
            </clipPath>
            </defs>
        </>
    );

export default ArrowLeft;