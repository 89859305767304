import { useEffect, useRef, useState } from "react";

export const useToggleGridList = () => {
  const [listType, setListType] = useState(false);

  const toggleGridList = () => {
    setListType(!listType);
  };

  return {
    listType,
    toggleGridList,
    setListType,
  };
};

function usePrevious(value: any) {
  const ref = useRef<any>(null);
  useEffect(() => {
    ref.current = value; //assign the value of ref to the argument
  }, [value]); //this code will run when the value of 'value' changes
  return ref.current; //in the end, return the current ref value.
}
export default usePrevious;

export const MAX_BIO_LENGTH = 250;
export const useExpandBio = () => {
  // states
  const [bioExpanded, setBioExpanded] = useState(false);

  const toggleExpandBio = () => {
    setBioExpanded(!bioExpanded);
  };

  return {
    toggleExpandBio,
    bioExpanded,
  };
};

export function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState<{
    width: number | undefined;
    height: number | undefined;
  }>({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    // only execute all the code below in client side
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount
  return windowSize;
}
