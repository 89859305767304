import { compact, first } from "lodash";
import { SEARCH_QUERY_KEY } from "../constants/search-query";

export const parseQueryFromUrl = () => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const query = first(
    compact(
      Array.from(urlSearchParams.entries())
        .filter(([key]) => key === SEARCH_QUERY_KEY)
        .map(([_, value]) => value)
    )
  );
  return query ?? "";
};
