import { cx } from "@/lib/utils/className.utils";
import { useRef, useEffect } from "react";

interface MarqueeProps {
  children: React.ReactNode;
  className?: string;
}
const Marquee = ({ children, className }: MarqueeProps) => {
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    window.addEventListener("resize", checkWidth);
    checkWidth();
  }, []);

  const checkWidth = () => {
    const container = containerRef.current;
    if (container)
      if (container.scrollWidth > container.clientWidth)
        container.classList.add("marquee");
      else container.classList.remove("marquee");
  };

  return (
    <div ref={containerRef} className={cx("w-full truncate", className)}>
      {children}
    </div>
  );
};

export default Marquee;
