import Energized from "../../components/icons/lib/energized";
import Angry from "../../components/icons/lib/angry";
import Chill from "../../components/icons/lib/chill";
import Funny from "../../components/icons/lib/funny";
import Happy from "../../components/icons/lib/happy";
import Sad from "../../components/icons/lib/sad";
import Tense from "../../components/icons/lib/tense";
import Inspired from "../../components/icons/lib/inspired";
import Loved from "../../components/icons/lib/loved";

export const getIconFromMood = (mood: string) => {
  switch (mood) {
    case "Energized":
      return Energized;
    case "Angry":
      return Angry;
    case "Chill":
      return Chill;
    case "Funny":
      return Funny;
    case "Happy":
      return Happy;
    case "Sad":
      return Sad;
    case "Tense":
      return Tense;
    case "Loved":
      return Loved;
    case "Inspired":
      return Inspired;
  }
};
