import { cva } from "class-variance-authority";
import React, { ButtonHTMLAttributes } from "react";
import { VariantProps } from "class-variance-authority";
import { cx } from "@/lib/utils/className.utils";

const button = cva(
  "bg-transparent outline-none w-auto break-keep whitespace-nowrap px-5 hover:opacity-60 flex flex-row items-center",
  {
    variants: {
      color: {
        white: "text-white",
        black: "text-black",
      },
    },
    defaultVariants: {
      color: "black",
    },
  }
);

export type TransparentButtonProps = ButtonHTMLAttributes<HTMLButtonElement> &
  VariantProps<typeof button>;

const TransparentButton = ({
  className,
  color,
  ...props
}: TransparentButtonProps) => {
  return (
    <button className={cx(button({ color }), className)} {...props}></button>
  );
};

export default TransparentButton;
