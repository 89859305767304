import { Popover, Transition } from "@headlessui/react";
import { useSession } from "next-auth/react";
import React, { Fragment } from "react";
import { defaultLinks } from "./data";
import { baseAuthLinks } from "@/data/navbar-links";
import MobileMenuLink, { OptionalHrefNavbarLink } from "./mobile-menu-link";
import { useLogoutConfirmationModal } from "./state";
import { isNil } from "lodash";
import Music from "@/components/icons/lib/music";
import User from "@/components/icons/lib/user";
import Star from "@/components/icons/lib/star";
import { getSubLinksFromLabelArtists } from "./utils/getSubLinksFromLabelArtists";
import useFetchLabelArtists from "./hooks/useFetchLabelArtists";
import { useSelectedArtistContext } from "./atoms/selected-artist-atom";
import { useCookies } from "react-cookie";
import {
  removeLabelImpersonator,
  updateLabelImpersonator,
} from "@/lib/label-manager";

export type MobileMenuProps = {
  open?: boolean;
  onClose?: () => void;
};

const MobileMenu = ({ open, onClose }: MobileMenuProps) => {
  const session = useSession();
  const [_, setLogoutConfirmationModal] = useLogoutConfirmationModal();
  const { artists } = useFetchLabelArtists();
  const [, setCookie, removeCookie] = useCookies();
  const [selectedArtist, setSelectedArtist] = useSelectedArtistContext();

  const handleNavigationLinkClick = (link: OptionalHrefNavbarLink) => {
    if (link.id === "logout") {
      setLogoutConfirmationModal(true);
    }
    onClose?.();
  };

  const handleArtistOptionClick = async (link: OptionalHrefNavbarLink) => {
    if (!session?.data?.user?.isLabelAccount) {
      onClose?.();
      return;
    }

    if (selectedArtist?.id === link.id) {
      // Remove labelImpersonate from table in backend
      await removeLabelImpersonator(session.data?.user.accessToken as string);

      // Update Client Side Frontend API calls to include artist_id in header
      removeCookie("impersonate-artist-id");
      setSelectedArtist(link);
      sessionStorage.setItem("selectedArtist", JSON.stringify({ id: link.id }));
      const storageEvent = new CustomEvent("onSessionStorageChange", {
        detail: {
          key: "selectedArtist",
          newValue: link.id,
        },
      });

      // Update ServerSide Frontend API calls to include artist_id in header
      await session.update({ impersonate_artist_id: "delete" });
      window.dispatchEvent(storageEvent);
    } else {
      try {
        // Send to the impersonate endpoint
        await updateLabelImpersonator(
          link.id as string,
          session.data?.user.accessToken as string
        );
      } catch (err) {
        console.error(err);
      }

      // Update Client Side Frontend API calls to include artist_id in header
      setCookie("impersonate-artist-id", link.id);
      setSelectedArtist(link);
      sessionStorage.setItem("selectedArtist", JSON.stringify({ id: link.id }));
      const storageEvent = new CustomEvent("onSessionStorageChange", {
        detail: {
          key: "selectedArtist",
          newValue: link.id as string,
        },
      });
      // Update ServerSide Frontend API calls to include artist_id in header
      await session.update({ impersonate_artist_id: link.id });
      window.dispatchEvent(storageEvent);
    }
    onClose?.();
  };

  return (
    <Popover>
      <Transition
        as={Fragment}
        show={open}
        enter="duration-200 ease-out"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="duration-100 ease-in"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <Popover.Panel
          focus
          static
          className="fixed inset-0 h-[93vh] top-[50px] origin-top-right transform z-50 transition lg:hidden"
        >
          <div className=" bg-background shadow-lg ring-1 ring-black ring-opacity-5 h-full">
            <div className="grid h-full overflow-auto">
              <div className="space-y-0 flex flex-col">
                <div className="flex flex-col">
                  {!session?.data?.user && (
                    <>
                      <MobileMenuLink
                        id="signup"
                        name="Sign Up"
                        href="/auth/sign-up"
                        onClick={handleNavigationLinkClick}
                        icon={Star}
                      />

                      <MobileMenuLink
                        id="login"
                        name="Login"
                        href="/auth/login"
                        onClick={handleNavigationLinkClick}
                        icon={User}
                      />
                    </>
                  )}

                  {session.data?.user &&
                    !session.data?.user.hasActiveSubscription && (
                      <MobileMenuLink
                        id="signup"
                        name="Upgrade"
                        href="/account/upgrade"
                        icon={Star}
                        onClick={handleNavigationLinkClick}
                      />
                    )}

                  {defaultLinks.map((link) => (
                    <MobileMenuLink
                      key={link.id}
                      {...link}
                      expanded={
                        link.id === "discover" && isNil(session?.data?.user)
                      }
                      icon={Music}
                      onClick={handleNavigationLinkClick}
                    />
                  ))}

                  {/* Label account artist options */}
                  {session.data?.user?.isLabelAccount && (
                    <MobileMenuLink
                      name="Artists"
                      subLinks={getSubLinksFromLabelArtists(artists ?? [])}
                      selectedId={selectedArtist?.id as string}
                      onClick={handleArtistOptionClick}
                    />
                  )}
                </div>

                <div className="flex flex-col w-full">
                  {session?.data?.user && (
                    <>
                      <MobileMenuLink
                        id="profile"
                        name={session?.data?.user?.email}
                        icon={User}
                        href="/account/profile"
                        onClick={handleNavigationLinkClick}
                      />

                      {baseAuthLinks
                        .filter((link) =>
                          link.visible
                            ? link.visible(session?.data!.user)
                            : true
                        )
                        .map((link) => (
                          <MobileMenuLink
                            key={link.id}
                            id={link.id}
                            name={link.name}
                            icon={link.icon}
                            href={link.href}
                            onClick={handleNavigationLinkClick}
                          />
                        ))}
                    </>
                  )}
                </div>

                {session?.data?.user && (
                  <MobileMenuLink
                    id="logout"
                    name="Log out"
                    onClick={handleNavigationLinkClick}
                  />
                )}
              </div>
            </div>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
};

export default MobileMenu;
