import axios, { AxiosError } from "axios";
import { isEmpty } from "lodash";
import { getSession } from "next-auth/react";

const baseURL = process.env.NEXT_PUBLIC_API_URL,
  isClient = typeof window !== "undefined";

const api = axios.create({
  baseURL: baseURL || "https://staging-api.licenselounge.com",
  timeout: 300000,
});

if (isClient) {
  api.interceptors.request.use(
    async (config) => {
      const impersonate_artist_id = document.cookie.replace(
        /(?:(?:^|.*;\s*)impersonate-artist-id\s*=\s*([^;]*).*$)|^.*$/,
        "$1"
      );
      if (!isEmpty(impersonate_artist_id)) {
        config.headers["impersonate-artist-id"] = impersonate_artist_id;
      }

      return config;
    },
    (error: AxiosError) => {
      console.log("request", error.status, error.code);
    }
  );

  api.interceptors.response.use(
    async (response) => response,
    (error) => {
      // If there's an issue with the network (e.g. API is down), we should
      // navigate to a page that does three things:
      // 1. shows a message that the API server is temporarily down,
      // 2. holds a reference to the page the user was before the error,
      // 3. navigate to the page when the network is back
      if (
        error.code === "ERR_NETWORK" &&
        !window.location.pathname.startsWith("/api-down")
      ) {
        const relativeUrl = window.location.pathname;
        window.location.href = `/api-down?redirectUrl=${relativeUrl}`;
      }
    }
  );
} else {
  getSession().then((session) => {
    if (session?.user?.impersonate_artist_id) {
      api.defaults.headers.common["impersonate-artist-id"] =
        session?.user?.impersonate_artist_id;
    }
  });
}

export default api;
