/* eslint-disable @next/next/no-img-element */
import { useAudioPlayer } from "../../src/contexts/AudioPlayerContext";
import { useWindowSize } from "../../src/hooks";
import { ViewObject } from "../../models/models";
import { formatTime } from "./utils/formatTime";

interface WaveProgressSliderProps {
  id?: string;
  item: ViewObject;
  duration: number;
  onSlide: Function;
  thumb?: boolean;
  thumbLine?: boolean;
  className?: string;
}

const WaveProgressSlider = ({
  id,
  item,
  duration,
  onSlide,
  thumb = false,
  thumbLine = false,
  className,
}: WaveProgressSliderProps) => {
  // hooks
  const { currentTime } = useAudioPlayer();
  const windowSize = useWindowSize();

  return (
    <div
      className={`relative flex flex-col lg:flex-row lg:items-center lg:space-x-3 w-full h-full lg:ml-5 lg:mr-10 ${
        className ?? ""
      }`}
    >
      {/* Current time */}
      <p className="hidden lg:flex text-white w-[60px]">
        {formatTime(currentTime)}
      </p>

      {/* Progress Bar */}
      <div className="relative flex bg-red-50 h-full w-full">
        <input
          id={id}
          type="range"
          min={0}
          max={duration}
          value={currentTime}
          onChange={(e) => onSlide(e.currentTarget.value)}
          className="appearance-none outline-none w-full h-full overflow-hidden audioProgress cursor-pointer"
          style={{
            background: `linear-gradient(to right, #1DF364 ${
              (currentTime * 100) / duration
            }%, #d1d5db ${(currentTime * 100) / duration}% 100%)`,
          }}
        />
        <img
          src={`${
            windowSize.width! > 1280
              ? item.waveForms?.desktopUrl ||
                "/assets/images/waveform-desktop.png"
              : item.waveForms?.mobileUrl ||
                "/assets/images/waveform-mobile.png"
          }`}
          className="absolute h-full object-fill w-full pointer-events-none"
          alt="Waveforms"
        />

        {/* Vertical Line */}
        {thumbLine && (
          <div
            className="absolute top-0 w-[1px] h-[80px] bg-gradient-to-b from-green to-black z-10 pointer-events-none"
            style={{ left: `${(currentTime * 100) / duration}%` }}
          />
        )}
        {/* Thumb Slider */}
        {thumb && (
          <div
            className="absolute top-0 -mt-[5px] -ml-[5px] rotate-45 h-[10px] w-[10px] bg-green pointer-events-none"
            style={{ left: `${(currentTime * 100) / duration}%` }}
          />
        )}
      </div>

      {/* Total duration time */}
      <p className="hidden lg:flex text-white w-[60px] justify-end">
        {`-${formatTime(duration - currentTime)}`}
      </p>

      {/* Mobile only */}
      <div className="lg:hidden flex  w-full h-1/3 justify-between mt-2">
        <p className="flex text-white text-opacity-70 text-small w-[60px]">
          {formatTime(currentTime)}
        </p>
        <p className="flex text-white text-opacity-70 text-small w-[60px] justify-end">
          {`-${formatTime(duration - currentTime)}`}
        </p>
      </div>
    </div>
  );
};

export default WaveProgressSlider;
