import { Session } from "next-auth";
import { IconType } from "../components/icons/icon-props";
import Download from "../components/icons/lib/download";
import FilterIcon from "../components/icons/lib/filter-icon";
import SettingsIcon from "../components/icons/lib/settings";
import Coin from "../components/icons/lib/coin";
import UserIcon from "../components/icons/lib/user";

export interface Link {
  id: string;
  name: string;
  href: string;
  visible?: (user: Session["user"]) => boolean;
  onClick?: (e?: MouseEvent) => void;
  icon?: IconType;
}

export const baseAuthLinks: Link[] = [
  {
    id: "account-link-uploads",
    name: "Audiolab",
    icon: FilterIcon,
    href: "/account/audiolab",
    visible: (user: Session["user"]) => user?.artistId !== null,
  },
  {
    id: "account-my-purchases",
    name: "My Purchases",
    icon: Download,
    href: "/account/purchases",
  },
  {
    id: "account-sales-and-earnings",
    name: "Sales & Earnings",
    icon: Coin,
    href: "/account/sales-earnings",
    visible: (user: Session["user"]) => user?.artistId !== null,
  },
  {
    id: "account-link-settings",
    name: "Account Settings",
    icon: SettingsIcon,
    href: "/account/settings",
  },
];

export const authenticatedLinks: Link[] = [
  {
    id: "account-link-profile",
    name: "Profile",
    icon: UserIcon,
    href: "/account/profile",
    visible: (user: Session["user"]) => user?.artistId !== null,
  },
  ...baseAuthLinks,
  {
    id: "account-link-logout",
    name: "Logout",
    href: "/auth/logout",
    visible: (user: Session["user"]) => user?.artistId !== null,
  },
];

export const discoverLinks = [
  {
    name: "Beats",
    href: "/beats",
  },
  {
    name: "Sounds",
    href: "/sounds",
  },
  {
    name: "Songs",
    href: "/songs",
  },
  {
    name: "Creators",
    href: "/creators",
  },
];
