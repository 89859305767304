import useSWRImmutable from "swr/immutable";
import { FilterType } from "../models/enums";
import { Filter, Mood } from "../models/models";
import api from "./api-config";
import { fetcher } from "./fetcher";

export const MOODS_ENDPOINT = "/moods";

export type UseMoodsParams = {
  includeAny?: boolean;
};

export const useMoodsFilters = (
  { includeAny }: UseMoodsParams = { includeAny: true }
) => {
  const { data, isLoading, error } = useSWRImmutable<Mood[]>(
    MOODS_ENDPOINT,
    fetcher<Mood[]>()
  );

  // We need to return the Any option for filtering in modals if this is
  // specified.
  const moods = includeAny
    ? ([
        {
          id: "1",
          name: "Any",
          type: FilterType.moodsType,
        } as Filter,
        ...(data ?? []).map((mood) => ({
          ...mood,
          type: FilterType.moodsType,
        })),
      ] as Mood[] | undefined)
    : (data?.map((mood) => ({
        ...mood,
        type: FilterType.moodsType,
      })) as Mood[] | undefined);

  return {
    moods,
    loading: isLoading,
    error,
  };
};

/**
 * Obtain all the moods from the endpoint. Use the same data endpoint as
 * {@link useMoodsFilters} so data is shared between the two.
 *
 * @param params is the params object for obtaining the data (not used)
 * @returns the moods data and current loading state
 */
export const useMoods = ({}: UseMoodsParams = { includeAny: true }) => {
  const { data, isLoading, error } = useSWRImmutable<Mood[]>(
    MOODS_ENDPOINT,
    fetcher<Mood[]>()
  );

  return {
    moods: data,
    loading: isLoading,
    error,
  };
};

export const getMoodsAsync = async () => {
  const res = await api.get<Mood[]>(MOODS_ENDPOINT);

  const moods = res.data.map((mood) => {
    return {
      id: mood.id,
      name: mood.name,
      type: FilterType.moodsType,
      relatedMoods: mood.related_moods.map(
        (m: any) =>
          ({
            id: m.id,
            name: m.name,
            type: FilterType.moodsType,
          } as Filter)
      ),
    } as Mood;
  });

  return {
    moods,
  };
};
