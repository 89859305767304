import { IconType } from "../icon-props";
const Menu: IconType = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="18"
    viewBox="0 0 22 18"
    fill="none"
  >
    <line
      x1="1.64062"
      y1="1"
      x2="21"
      y2="1"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <line
      x1="1.64062"
      y1="9"
      x2="21"
      y2="9"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <line
      x1="1.64062"
      y1="17"
      x2="21"
      y2="17"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);

export default Menu;
