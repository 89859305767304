import Link from "next/link";
import Icon from "../icons/icon";
import { useState, useEffect } from "react";
import Close from "../icons/lib/close";

const TermsBanner = () => {
  const [show, setShow] = useState<boolean>(false);

  useEffect(() => {
    const isoDate = localStorage.getItem("terms");
    if (isoDate) {
      const date = new Date(isoDate);
      if (date > new Date()) {
        setShow(false);
        return;
      }
    }
    // Disable terms banner
    // setShow(true);
    setShow(false);
  }, []);

  const saveTerms = () => {
    const date = new Date();
    date.setDate(date.getDate() + 14);
    localStorage.setItem("terms", date.toISOString());
    setShow(false);
  };

  return show ? (
    <div className="fixed bottom-0 w-full h-12 bg-black flex justify-between items-center z-50 px-5">
      <div></div>
      <div>
        <p className="text-white text-center">
          See our updated{" "}
          <Link href="/terms" className="text-green">
            Terms of Use
          </Link>{" "}
          and{" "}
          <Link href="/privacy" className="text-green">
            Privacy Policy
          </Link>
        </p>
      </div>
      <Icon
        icon={Close}
        className="text-white w-6 h-6 cursor-pointer"
        color="white"
        onClick={saveTerms}
      />
    </div>
  ) : null;
};

export default TermsBanner;
