import { IconType } from "../icon-props";

const GridFill: IconType = (
  <>
    <g clipPath="url(#clip0_621_17914)">
      <path d="M22 12.999V20C22 20.2652 21.8946 20.5196 21.7071 20.7071C21.5196 20.8946 21.2652 21 21 21H13V12.999H22ZM11 12.999V21H3C2.73478 21 2.48043 20.8946 2.29289 20.7071C2.10536 20.5196 2 20.2652 2 20V12.999H11ZM11 3V10.999H2V4C2 3.73478 2.10536 3.48043 2.29289 3.29289C2.48043 3.10536 2.73478 3 3 3H11ZM21 3C21.2652 3 21.5196 3.10536 21.7071 3.29289C21.8946 3.48043 22 3.73478 22 4V10.999H13V3H21Z" />
    </g>
    <defs>
      <clipPath id="clip0_621_17914">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </>
);

export default GridFill;
