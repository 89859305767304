import { IconType } from "../icon-props";

const Note: IconType = (
        <>
                <g clipPath="url(#clip0_621_17890)">
                <path d="M12 13.535V3H20V5H14V17C13.9998 17.8805 13.7091 18.7363 13.1729 19.4348C12.6368 20.1332 11.8852 20.6352 11.0346 20.863C10.1841 21.0907 9.28218 21.0315 8.46875 20.6944C7.65533 20.3573 6.97584 19.7613 6.53568 18.9987C6.09552 18.2361 5.91927 17.3496 6.03427 16.4767C6.14927 15.6037 6.5491 14.7931 7.17174 14.1705C7.79438 13.5479 8.60504 13.1482 9.47801 13.0333C10.351 12.9184 11.2375 13.0948 12 13.535ZM10 19C10.5304 19 11.0391 18.7893 11.4142 18.4142C11.7893 18.0391 12 17.5304 12 17C12 16.4696 11.7893 15.9609 11.4142 15.5858C11.0391 15.2107 10.5304 15 10 15C9.46957 15 8.96087 15.2107 8.58579 15.5858C8.21072 15.9609 8.00001 16.4696 8.00001 17C8.00001 17.5304 8.21072 18.0391 8.58579 18.4142C8.96087 18.7893 9.46957 19 10 19Z" />
                </g>
                <defs>
                <clipPath id="clip0_621_17890">
                <rect width="24" height="24" fill="white"/>
                </clipPath>
                </defs>
        </>
    );

export default Note;