import { createContext, useContext, useEffect, useState } from "react";
import { Beat, SoundKit, ViewObject } from "../../models/models";

export const useAudioPlayerController = () => {
  // states
  const [selectedAudio, setSelectedAudio] = useState<SoundKit | Beat>();
  const [playerVisible, setPlayerVisible] = useState(false);
  const [playing, setPlaying] = useState(false);
  const [duration, setDuration] = useState<number>(0);
  const [volumeLevel, setVolumeLevel] = useState(80);
  const [currentTime, setCurrentTime] = useState(0);

  // playlist states
  const [currentPlaylist, setCurrentPlaylist] = useState<ViewObject[]>([]);

  // effects
  useEffect(() => {
    if (
      currentTime !== 0 &&
      duration !== 0 &&
      currentTime === duration &&
      playing
    ) {
      playNext();
    }
    // TODO: need to improve this effect to simplify the deps or make the deps stable
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTime]);

  // actions
  const selectAudio = (audio: Beat | SoundKit) => {
    setSelectedAudio(audio);
    setPlaying(true);
  };

  const openPlayer = () => {
    setPlayerVisible(true);
  };

  const closePlayer = () => {
    setPlayerVisible(false);
    setPlaying(false);
    setCurrentTime(0);
    setSelectedAudio(undefined);
  };

  const hidePlayer = () => {
    setPlayerVisible(false);
    if (playing) {
      setPlaying(false);
    }
  };

  const handlePlayTrack = (item: Beat | SoundKit) => {
    selectAudio(item);
    if (!playerVisible) {
      openPlayer();
    }
    if (item?.id === selectedAudio?.id) {
      setPlaying(!playing);
    } else {
      setCurrentTime(0);
      setPlaying(true);
    }
  };

  const findFirstAvailableTrack = (): ViewObject => {
    return currentPlaylist!.find((track: ViewObject) => track.sample)!;
  };

  const findLastAvailableTrack = (): ViewObject => {
    return currentPlaylist!
      .slice()
      .reverse()
      .find((track: ViewObject) => track.sample)!;
  };

  const playNext = () => {
    if (currentPlaylist.length === 0) return;
    const currentlyPlayingPosition: number = currentPlaylist!.findIndex(
      (track: ViewObject) => track.id === selectedAudio?.id
    );

    // Find next track that can be played and that is later on the list.
    let nextAvailableTrack = currentPlaylist!.find(
      (track: ViewObject, index: number) =>
        index > currentlyPlayingPosition && track.sample && track
    );

    // If the track was not found start from the beggining.
    if (!nextAvailableTrack) {
      nextAvailableTrack = findFirstAvailableTrack();
    }

    if (currentlyPlayingPosition !== -1) {
      handlePlayTrack(nextAvailableTrack as any);
    } else {
      // If the user changed the playlist and the currently playing track is not there reset.
      handlePlayTrack(findFirstAvailableTrack() as any);
    }
  };

  const playPrevious = () => {
    if (currentPlaylist.length === 0) return;
    const invertedResults = currentPlaylist!.slice().reverse();

    const currentlyPlayingPosition: number = invertedResults.findIndex(
      (track: ViewObject) => track.id === selectedAudio?.id
    );

    // Find prev track that can be played and that appears earlier on the list.
    let prevAvailableTrack = invertedResults.find(
      (track: ViewObject, index: number) =>
        index > currentlyPlayingPosition && track.sample && track
    );

    // If the track was not found start from the end.
    if (!prevAvailableTrack) {
      prevAvailableTrack = findLastAvailableTrack();
    }

    if (currentlyPlayingPosition !== -1) {
      handlePlayTrack(prevAvailableTrack as any);
    } else {
      // If the user changed the playlist and the currently playing track is not there reset.
      handlePlayTrack(findFirstAvailableTrack() as any);
    }
  };

  return {
    selectedAudio,
    openPlayer,
    closePlayer,
    hidePlayer,
    selectAudio,
    playerVisible,
    playing,
    setPlaying,
    handlePlayTrack,
    currentTime,
    setCurrentTime,
    duration,
    setDuration,
    volumeLevel,
    setVolumeLevel,
    playNext,
    playPrevious,
    setCurrentPlaylist,
    currentPlaylist,
  };
};

const AudioPlayerContext = createContext<
  ReturnType<typeof useAudioPlayerController>
>({
  selectedAudio: {} as SoundKit | Beat,
  openPlayer: () => {},
  closePlayer: () => {},
  hidePlayer: () => {},
  selectAudio: () => {},
  playerVisible: false,
  playing: true,
  setPlaying: () => {},
  handlePlayTrack: () => {},
  currentTime: 0,
  setCurrentTime: () => {},
  duration: 0,
  setDuration: () => {},
  volumeLevel: 80,
  setVolumeLevel: () => {},
  playNext: () => {},
  playPrevious: () => {},
  setCurrentPlaylist: () => {},
  currentPlaylist: [],
});

export const AudioPlayerProvider = ({ children }: { children: any }) => {
  return (
    <AudioPlayerContext.Provider value={useAudioPlayerController()}>
      {children}
    </AudioPlayerContext.Provider>
  );
};

export const useAudioPlayer = () => useContext(AudioPlayerContext);
