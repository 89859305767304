import { searchCategories } from "@/data/dummy";
import { SearchCategory } from "@/models/models";
import { atom, useAtom } from "jotai";

export const navbarCategoryAtom = atom<SearchCategory | null>(
  searchCategories[0]
);

export const useNavbarCategoryContext = () => useAtom(navbarCategoryAtom);

export const pageCategoryAtom = atom<SearchCategory | null>(
  searchCategories[0]
);

export const usePageCategoryContext = () => useAtom(pageCategoryAtom);
