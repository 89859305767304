import { IconType } from "../icon-props";

const Play: IconType = (
        <>
            <g clipPath="url(#clip0_621_17874)">
            <path d="M19.376 12.4158L8.777 19.4818C8.70171 19.5319 8.61423 19.5607 8.52389 19.565C8.43355 19.5693 8.34373 19.5491 8.264 19.5064C8.18427 19.4637 8.1176 19.4002 8.07111 19.3226C8.02462 19.245 8.00005 19.1563 8 19.0658V4.93382C8.00005 4.84338 8.02462 4.75465 8.07111 4.67707C8.1176 4.59949 8.18427 4.53597 8.264 4.49328C8.34373 4.45059 8.43355 4.43033 8.52389 4.43465C8.61423 4.43897 8.70171 4.46772 8.777 4.51782L19.376 11.5838C19.4445 11.6295 19.5006 11.6913 19.5395 11.7639C19.5783 11.8365 19.5986 11.9175 19.5986 11.9998C19.5986 12.0821 19.5783 12.1632 19.5395 12.2357C19.5006 12.3083 19.4445 12.3702 19.376 12.4158Z" />
            </g>
            <defs>
            <clipPath id="clip0_621_17874">
            <rect width="24" height="24" fill="white"/>
            </clipPath>
            </defs>
        </>
    );

export default Play;