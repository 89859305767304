import { createContext, useContext, useEffect, useState } from "react";
import { CartItem } from "../../models/models";

export const useCartController = () => {
  //states
  const [cartItems, setCartItems] = useState<CartItem[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const changeItems = (items: CartItem[]) => {
    setCartItems(items);
    localStorage.setItem("cart", JSON.stringify(items));
  };

  useEffect(() => {
    const fetchCartItems = async () => {
      const cart = await localStorage.getItem("cart");
      if (cart) {
        await setCartItems(JSON.parse(cart));
      }
      setIsLoading(false);
    };

    fetchCartItems();
  }, []);

  // actions
  const addItem = (item: CartItem) => {
    if (cartItems.find((cartItem) => cartItem.id === item.id)) return;
    changeItems([...cartItems, item]);
  };

  const removeItem = (id: string) => {
    changeItems(cartItems.filter((item) => item.id !== id));
  };
  const modifyItem = (item: CartItem) => {
    changeItems(
      cartItems.map((cartItem) => (cartItem.id === item.id ? item : cartItem))
    );
  };

  return {
    addItem,
    removeItem,
    modifyItem,
    removeAll: () => changeItems([]),
    cartItems,
    isLoading,
  };
};

const CartContext = createContext<ReturnType<typeof useCartController>>({
  modifyItem: (item: CartItem) => {},
  addItem: (item: CartItem) => {},
  removeItem: (id: string) => {},
  removeAll: () => {},
  cartItems: [],
  isLoading: true,
});

export const CartProvider = ({ children }: { children: any }) => {
  return (
    <CartContext.Provider value={useCartController()}>
      {children}
    </CartContext.Provider>
  );
};

export const useCart = () => useContext(CartContext);
