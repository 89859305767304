export const mapUndefinedToNull = <T>(obj: T) => {
  if (Array.isArray(obj)) {
    obj.map((o) => mapUndefinedToNull(o));
  }
  const object = { ...obj };
  if (typeof object === "object") {
    for (const key in object) {
      if (object.hasOwnProperty(key)) {
        if (object[key] === undefined) {
          object[key] = null as typeof object[typeof key];
        } else if (typeof object[key] === "object") {
          mapUndefinedToNull(object[key]);
        }
      }
    }
  }
  return object;
};
