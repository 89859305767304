import Icon from "@/components/icons/icon";
import { cx } from "@/lib/utils/className.utils";
import { useCart } from "@/src/contexts/CartContext";
import { VariantProps, cva } from "class-variance-authority";
import Link from "next/link";
import React, { ButtonHTMLAttributes } from "react";
import ShoppingCart from "@/components/icons/lib/buy";

const icon = cva("", {
  variants: {
    color: {
      default: "fill-white",
      light: "fill-darkGreen",
      dark: "fill-white",
    },
  },
  defaultVariants: {
    color: "default",
  },
});

export type CartButtonProps = VariantProps<typeof icon> &
  ButtonHTMLAttributes<HTMLButtonElement>;

const CartButton = ({ className, color, ...props }: CartButtonProps) => {
  const { cartItems } = useCart();

  return (
    <Link href="/cart">
      <button
        className={cx(
          "px-[30px] relative flex items-center justify-center cursor-pointer",
          className
        )}
        {...props}
      >
        <Icon icon={ShoppingCart} className={icon({ color })} />

        {cartItems.length > 0 && (
          <span className="absolute text-[9px] rounded-[15px] bg-green w-[15px] mt-[-30px] mr-[-15px] font-open-sans flex items-center justify-center">
            {cartItems.length}
          </span>
        )}
      </button>
    </Link>
  );
};

export default CartButton;
