import { IconType } from "../icon-props";

const Angry: IconType = (
  <>
    <g clipPath="url(#clip0_2382_38395)">
      <path d="M16 0C24.825 0 32 7.175 32 16C32 24.825 24.825 32 16 32C7.175 32 0 24.825 0 16C0 7.175 7.175 0 16 0ZM30.6688 16C30.6688 10.5875 27.7125 5.85625 23.3312 3.31875C23.3375 3.49375 23.275 3.675 23.1375 3.80625L21.8062 5.1375C21.675 5.26875 21.5062 5.33125 21.3312 5.33125C21.1625 5.33125 20.9875 5.26875 20.8625 5.1375L19.5312 3.80625C19.2687 3.54375 19.2687 3.125 19.5312 2.8625C19.7938 2.6 20.2125 2.6 20.475 2.8625L21.3375 3.725L22.2 2.8625C22.2375 2.825 22.2812 2.80625 22.3188 2.78125C20.4062 1.8625 18.2688 1.33125 16.0063 1.33125C7.9125 1.33125 1.33125 7.9125 1.33125 16C1.33125 24.0875 7.9125 30.6688 16 30.6688C24.0875 30.6688 30.6688 24.0875 30.6688 16Z" />
      <path d="M25.1379 4.8626C25.4004 5.1251 25.4004 5.54385 25.1379 5.80635L24.2754 6.66885L25.1379 7.53135C25.4004 7.79385 25.4004 8.2126 25.1379 8.4751C25.0066 8.60635 24.8379 8.66885 24.6691 8.66885C24.5004 8.66885 24.3254 8.6001 24.1941 8.4751L22.8629 7.14385C22.6004 6.88135 22.6004 6.4626 22.8629 6.2001L24.1941 4.86885C24.4566 4.6001 24.8754 4.6001 25.1379 4.8626Z" />
      <path d="M23.9313 11.1311C24.0938 11.4624 23.9625 11.8624 23.6313 12.0249L22.8 12.4374C23.5188 12.9124 24 13.7311 24 14.6624C24 16.1311 22.8063 17.3311 21.3313 17.3311C19.8563 17.3311 18.6625 16.1374 18.6625 14.6624C18.6625 14.6061 18.675 14.5561 18.6813 14.4999L18.2938 14.6936C18.2 14.7436 18.0938 14.7624 17.9938 14.7624C17.75 14.7624 17.5125 14.6249 17.3938 14.3936C17.2313 14.0624 17.3625 13.6624 17.6938 13.4999L23.025 10.8311C23.3625 10.6686 23.7625 10.8061 23.9313 11.1311ZM22.6688 14.6686C22.6688 13.9311 22.0688 13.3374 21.3375 13.3374C20.6 13.3374 20.0063 13.9374 20.0063 14.6686C20.0063 15.3999 20.6063 15.9999 21.3375 15.9999C22.0688 15.9999 22.6688 15.3999 22.6688 14.6686Z" />
      <path d="M23.8691 22.9312C24.0878 23.2249 24.0316 23.6437 23.7378 23.8624C23.6191 23.9499 23.4753 23.9937 23.3378 23.9937C23.1378 23.9937 22.9378 23.8999 22.8066 23.7249C22.7316 23.6312 20.8941 21.3249 16.0066 21.3249C16.0066 21.3249 16.0066 21.3249 16.0003 21.3249C11.1128 21.3249 9.27535 23.6312 9.20035 23.7312C8.97535 24.0187 8.56285 24.0749 8.2691 23.8562C7.97535 23.6374 7.9191 23.2187 8.13785 22.9249C8.22535 22.8062 10.4003 19.9937 16.0003 19.9937C16.0003 19.9937 16.0003 19.9937 16.0066 19.9937C21.6003 19.9999 23.7753 22.8124 23.8691 22.9312Z" />
      <path d="M23.1375 9.5313C23.4 9.7938 23.4 10.2125 23.1375 10.475C23.0063 10.6063 22.8375 10.6688 22.6688 10.6688C22.5 10.6688 22.325 10.6 22.1938 10.475L21.3312 9.61255L20.4688 10.475C20.2063 10.7375 19.7875 10.7375 19.525 10.475C19.2625 10.2125 19.2625 9.7938 19.525 9.5313L20.8563 8.20005C21.1188 7.93755 21.5375 7.93755 21.8 8.20005L23.1375 9.5313Z" />
      <path d="M19.8064 6.1938C20.0689 6.4563 20.0689 6.87505 19.8064 7.13755L18.4752 8.4688C18.3439 8.60005 18.1752 8.66255 18.0064 8.66255C17.8377 8.66255 17.6627 8.5938 17.5377 8.4688C17.2752 8.2063 17.2752 7.78755 17.5377 7.52505L18.4002 6.66255L17.5377 5.80005C17.2752 5.53755 17.2752 5.1188 17.5377 4.8563C17.8002 4.5938 18.2189 4.5938 18.4814 4.8563L19.8064 6.1938Z" />
      <path d="M14.2998 13.4999C14.6311 13.6686 14.7623 14.0624 14.5998 14.3936C14.4811 14.6249 14.2498 14.7624 14.0061 14.7624C13.9061 14.7624 13.8061 14.7374 13.7061 14.6936L13.3186 14.4999C13.3186 14.5561 13.3373 14.6061 13.3373 14.6624C13.3373 16.1311 12.1436 17.3311 10.6686 17.3311C9.19982 17.3311 7.99982 16.1374 7.99982 14.6624C7.99982 13.7374 8.47482 12.9186 9.19982 12.4374L8.36857 12.0249C8.03732 11.8561 7.90607 11.4624 8.06857 11.1311C8.23107 10.8061 8.63732 10.6686 8.96232 10.8311L14.2998 13.4999ZM11.9998 14.6686C11.9998 13.9311 11.3998 13.3374 10.6686 13.3374C9.93732 13.3374 9.33732 13.9374 9.33732 14.6686C9.33732 15.3999 9.93732 15.9999 10.6686 15.9999C11.3998 15.9999 11.9998 15.3999 11.9998 14.6686Z" />
    </g>
    <defs>
      <clipPath id="clip0_2382_38395">
        <rect width="32" height="32" fill="white" />
      </clipPath>
    </defs>
  </>
);

export default Angry;
