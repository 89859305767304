import { IconType } from "../icon-props";

const Track: IconType = (
        <>
            <g clipPath="url(#clip0_621_17893)">
            <path d="M15 4.5818V11.9998C14.9997 12.6366 14.7967 13.2567 14.4206 13.7705C14.0444 14.2843 13.5145 14.665 12.9076 14.8577C12.3007 15.0503 11.6482 15.0448 11.0446 14.842C10.441 14.6392 9.91755 14.2497 9.55006 13.7296C9.18256 13.2096 8.99005 12.5862 9.0004 11.9495C9.01074 11.3128 9.2234 10.6959 9.60759 10.1882C9.99179 9.68036 10.5276 9.30797 11.1375 9.1249C11.7473 8.94182 12.3997 8.95755 13 9.1698V2.0498C18.053 2.5508 22 6.8148 22 11.9998C22 17.5228 17.523 21.9998 12 21.9998C6.477 21.9998 2 17.5228 2 11.9998C2 6.8148 5.947 2.5508 11 2.0498V4.0618C8.98271 4.31849 7.13885 5.33367 5.84319 6.90102C4.54752 8.46837 3.89728 10.4703 4.02462 12.4998C4.15196 14.5294 5.04733 16.4343 6.52874 17.8274C8.01016 19.2205 9.96645 19.9973 12 19.9998C13.8573 20.0008 15.6572 19.3556 17.0908 18.1747C18.5244 16.9938 19.5024 15.3509 19.8572 13.5278C20.2119 11.7046 19.9213 9.81485 19.035 8.18258C18.1488 6.55031 16.7222 5.27727 15 4.5818Z" />
            </g>
            <defs>
            <clipPath id="clip0_621_17893">
            <rect width="24" height="24" fill="white"/>
            </clipPath>
            </defs>
        </>
    );

export default Track;