import { getLabelArtistList } from "@/lib/label-manager";
import { useSession } from "next-auth/react";
import { useQuery } from "react-query";
import { Option } from "@/models/sorting-filtering";
import { mapAccountToOption } from "@/lib/helpers";
import { AccountsListResponse } from "../types";
import { partition } from "lodash";
import { isNil } from "lodash";

const mapLabelArtistToOptions = (artistOptions: AccountsListResponse[]) => {
  const mappedOptions = artistOptions.map(mapAccountToOption) as Option[];
  return mappedOptions;
};

/**
 *
 * @returns an ordered label artists to be shown on the dropdown.
 */
const useFetchLabelArtists = () => {
  const session = useSession();

  const { data, isLoading, isError } = useQuery(
    "/auth/impersonate-list",
    async () => {
      const artistsOptions = await getLabelArtistList(
        session.data?.user.accessToken as string
      );
      const [ownLabelArtist, otherArtistOptions] = partition(
        artistsOptions,
        (option) =>
          option.id === session.data?.user?.impersonate?.labelArtistId ||
          (isNil(session.data?.user?.impersonate) &&
            option.id === session?.data?.user?.artistId)
      );
      const sortedArtistsOptions = [
        ...ownLabelArtist,
        ...otherArtistOptions.sort((a, b) => a.name.localeCompare(b.name)),
      ];

      const mappedOptions = mapLabelArtistToOptions(sortedArtistsOptions);
      sessionStorage.setItem("labelArtists", JSON.stringify(mappedOptions));

      return sortedArtistsOptions;
    },
    {
      enabled:
        session?.status === "authenticated" &&
        session.data?.user.isLabelAccount,
    }
  );

  return {
    artists: data,
    options: mapLabelArtistToOptions(data ?? []),
    isLoading,
    isError,
  };
};

export default useFetchLabelArtists;
