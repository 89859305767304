import { RadioGroup, Tab } from "@headlessui/react";
import CheckIcon from "@heroicons/react/20/solid/CheckIcon";
import { Fragment, useEffect, useState } from "react";
import { addOns, formats, packages } from "../../../data/dummy";
import { isBeat, isSong } from "../../../lib/helpers";
import { Beat, CartItem } from "../../../models/models";
import { useCart } from "../../../src/contexts/CartContext";
import PrimaryButton from "../../buttons/primary-button";
import { distributions } from "../../../data/distributions";
import { Right } from "../../../data/licenses";
import { isEqual } from "lodash";
import toast from "react-hot-toast";
import AppLink from "@/components/app-link";
import { Icon } from "@/components/icons/icon";
import Tooltip from "../../tooltip";
import Check from "@/components/icons/lib/check";
import { cx } from "@/lib/utils/className.utils";
import PricingInfoFormatsRadioGroup from "./PricingInfoFormatsRadioGroup";

interface PricingInfoProps {
  className?: string;
  item: Beat;
  cartInfo?: CartItem;
  isProductDetail?: boolean;
  actionOnAddToCart?: () => void;
  onItemSave?: (cartItem: CartItem) => void;
}

export type CartItemLike = Pick<
  CartItem,
  "licenseRights" | "format" | "distribution" | "addons" | "licenseType"
>;

const isCartItemSimilar = (
  cartItem: CartItem | undefined,
  to: CartItemLike
): boolean => {
  return (Object.keys(to) as (keyof CartItemLike)[]).every((key) =>
    isEqual(cartItem?.[key], to[key])
  );
};

const hasLicense = (item: Beat, license: string) => {
  return (
    item.licenseRights?.find((lr) => lr.name === license) !== undefined ||
    license === "Exclusive" ||
    license === "Enterprise"
  );
};

const createDistributionSaveSates = (item: Beat, cartInfo?: CartItem) => {
  let distribution: Record<string, string> = {};
  for (const value of Object.values(item.licenseRights)) {
    switch (value.name) {
      case "Basic":
        distribution[value.name] =
          cartInfo?.licenseRights === "Basic"
            ? (cartInfo?.distribution as string)
            : "Limited";
        distribution["Enterprise"] = "Unlimited";
        break;
      case "Pro":
        distribution[value.name] =
          cartInfo?.licenseRights === "Pro"
            ? (cartInfo?.distribution as string)
            : "Limited";
        distribution["Enterprise"] = "Unlimited";
        break;
      case "Non-Exclusive":
        distribution[value.name] =
          cartInfo?.licenseRights === "Non-Exclusive"
            ? (cartInfo?.distribution as string)
            : "Limited";
        distribution["Exclusive"] = "Unlimited";
        break;
      case "Buy-Out":
        distribution[value.name] =
          cartInfo?.licenseRights === "Buy-Out"
            ? (cartInfo?.distribution as string)
            : "Unlimited";
        break;
      default:
        break;
    }
  }
  return distribution;
};

const createLicenseRightsSaveStates = (item: Beat, cartInfo?: CartItem) => {
  let licenseRight: Record<string, Right | undefined> = {};
  if (isBeat(item)) {
    licenseRight["recording"] =
      cartInfo?.licenseType === "recording"
        ? cartInfo?.licenseRights
        : (getLicenseRights(item, "recording", "Exclusive") as Right);
  }
  licenseRight["sync"] =
    cartInfo?.licenseType === "sync"
      ? cartInfo?.licenseRights
      : (getLicenseRights(item, "sync", "Enterprise") as Right);
  return licenseRight;
};

const formatInLicenseByRight = (
  item: Beat,
  format: string,
  licenseRight: string
): boolean => {
  const license = item.licenseRights?.find(
    (right) => right.name === licenseRight
  );
  const file = license?.files?.find((file) => file.type === format);
  return file ? true : false;
};

const getDefaultTypeSelection = (item: Beat, licenseRight: string) => {
  if (isBeat(item) || isSong(item)) {
    if (formatInLicenseByRight(item, "MP3", licenseRight)) {
      return "MP3";
    } else if (formatInLicenseByRight(item, "WAV", licenseRight)) {
      return "WAV";
    } else if (formatInLicenseByRight(item, "STEMS + WAV", licenseRight)) {
      return "STEMS + WAV";
    }
  }
  return "";
};

const createFormatSaveStates = (item: Beat, cartInfo?: CartItem) => {
  let formats: Record<string, string> = {};
  for (const value of Object.values(item.licenseRights)) {
    switch (value.name) {
      case "Basic":
        formats[value.name] =
          cartInfo?.licenseRights === "Basic"
            ? (cartInfo?.format as string)
            : (getDefaultTypeSelection(item, "Basic") as string);
        formats["Enterprise"] = "STEMS + WAV";
        break;
      case "Pro":
        formats[value.name] =
          cartInfo?.licenseRights === "Pro"
            ? (cartInfo?.format as string)
            : (getDefaultTypeSelection(item, "Pro") as string);
        formats["Enterprise"] = "STEMS + WAV";
        break;
      case "Non-Exclusive":
        formats[value.name] =
          cartInfo?.licenseRights === "Non-Exclusive"
            ? (cartInfo?.format as string)
            : (getDefaultTypeSelection(item, "Non-Exclusive") as string);
        formats["Exclusive"] = "STEMS + WAV";
        break;
      default:
        break;
    }
  }
  return formats;
};

const getLicenseRights = (
  item: Beat,
  licenseType: string | undefined,
  baseLicense: string | undefined
) => {
  if (licenseType === "recording") {
    if (hasLicense(item, "Non-Exclusive")) {
      return "Non-Exclusive";
    }
    return "Exclusive";
  }
  if (licenseType === "sync") {
    if (hasLicense(item, "Basic")) {
      return "Basic";
    } else if (hasLicense(item, "Pro")) {
      return "Pro";
    }
    return "Enterprise";
  }
  return baseLicense;
};

const PricingInfo = ({
  className,
  item,
  cartInfo,
  actionOnAddToCart,
  onItemSave,
  isProductDetail,
}: PricingInfoProps) => {
  // properties
  const baseLicense =
    isBeat(item) && !hasLicense(item, "recording") ? "recording" : "sync";
  const hasSyncLicense = item.licenseRights?.find(
    (lr) => lr.name === "Basic" || lr.name === "Pro"
  );

  // states
  const [licenseType, setLicenseType] = useState(
    cartInfo! ? cartInfo.licenseType : isBeat(item) ? "recording" : "sync"
  );

  const [licenseRightState, setLicenseRightState] = useState<
    Record<string, Right | undefined>
  >(createLicenseRightsSaveStates(item, cartInfo));

  const [licenseRights, setLicenseRights] = useState<Right | undefined>(
    cartInfo
      ? cartInfo.licenseRights!
      : (getLicenseRights(item, licenseType, baseLicense) as Right)
  );

  const [formatState, setFormatState] = useState<Record<string, string>>(
    createFormatSaveStates(item, cartInfo)
  );

  const [distribution, setDistribution] = useState<Record<string, string>>(
    createDistributionSaveSates(item, cartInfo)
  );

  const [addons, setAddons] = useState<string[] | undefined>(
    cartInfo?.addons ? cartInfo.addons : []
  );

  const [mailtoUrl, setMailtoUrl] = useState("");

  const [shouldShowUpdateItem, setShowUpdateItem] = useState<
    boolean | undefined
  >(true);

  // hooks
  const { addItem, cartItems, modifyItem } = useCart();

  useEffect(() => {
    const cItem = cartItems.find((cartItem) => cartItem.id === item.id);
    setShowUpdateItem(
      cartItems.find((cartItem) => cartItem.id === item.id) &&
        !isCartItemSimilar(cItem, {
          licenseType,
          licenseRights,
          format: formatState[licenseRights as string],
          distribution: distribution[licenseRights as string],
          addons,
        })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    cartItems,
    licenseType,
    licenseRights,
    formatState,
    distribution,
    addons,
  ]);

  useEffect(() => {
    setMailtoUrl(
      `mailto:info@lutely.com?subject=Request%20Quote-%20${
        item?.name
      }&body=Hi%20Lutely%2C%0A%0AI%20would%20like%20to%20request%20a%20${licenseRights}%20License%20for%20${
        item?.name
      }%20from%20${item.artist?.name}%0A%0A${window ? window.location : ""}`
    );
  }, [item.artist?.name, item?.name, licenseRights]);

  useEffect(() => {
    setDistribution(createDistributionSaveSates(item, cartInfo));
    setLicenseType(
      cartInfo! ? cartInfo.licenseType : isBeat(item) ? "recording" : "sync"
    );
    setLicenseRightState(createLicenseRightsSaveStates(item, cartInfo));
    setLicenseRights(
      cartInfo
        ? cartInfo.licenseRights!
        : (getLicenseRights(item, licenseType, baseLicense) as Right)
    );
    setFormatState(createFormatSaveStates(item, cartInfo));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item.id]);

  // actions
  const handleSelectAddOn = (addon: string) => {
    if (addons?.includes(addon)) {
      setAddons(addons?.filter((ad) => ad !== addon));
    } else {
      setAddons([...(addons ?? []), addon]);
    }
  };

  const handleFormatChange = (form: string) => {
    setFormatState((prevFormats) => ({
      ...prevFormats,
      [licenseRights as string]: form,
    }));
  };

  const handleDistributionChange = (dist: string) => {
    setDistribution((prevDistribution) => ({
      ...prevDistribution,
      [licenseRights as string]: dist,
    }));
  };

  const handleLicenseRightsChange = (licRight: string) => {
    setLicenseRights(licRight as Right);
    setLicenseRightState((prevLicenseRights) => ({
      ...prevLicenseRights,
      [licenseType as string]: licRight as Right,
    }));
  };

  const goToLicensingAgreement = () => {
    const base =
      licenseRights === "Basic" || licenseRights === "Pro" ? "Sync-" : "Beat-";
    const name = `${base}${licenseRights}${
      licenseRights === "Non-Exclusive"
        ? `-${distribution[licenseRights as string]}`
        : ""
    }${
      addons?.length && licenseRights === "Non-Exclusive"
        ? `-${addons?.map((addon) => addon.split(" ")[0]).join("-")}`
        : ""
    }`;
    // open file in new tab
    window?.open(`/assets/licensing-agreements/${name}.pdf`, "_blank");
  };

  const handleAddToCart = () => {
    const payload = {
      id: item.id,
      name: item.name,
      price: getTotalPrice() || 0,
      cover: item.cover,
      licenseRights,
      productType: item.type,
      licenseType,
      format: formatState[licenseRights as string],
      distribution: distribution[licenseRights as string],
      addons,
      fullItem: item,
    };
    if (cartItems.find((cartItem) => cartItem.id === item.id)) {
      modifyItem(payload);
      onItemSave?.(payload);
      return;
    }
    addItem(payload);
    onItemSave?.(payload);

    toast(
      <div className="flex flex-row items-center gap-2">
        <Icon icon={Check} className="fill-green stroke-green" />
        <span>License added to cart.</span>
        <AppLink href={`/cart`} className="underline text-purple">
          Checkout now!
        </AppLink>
      </div>,
      { duration: 7 * 1000, position: "top-center" }
    );
  };

  const getFormats = () => {
    if (licenseRights === "Non-Exclusive" || licenseRights === "Basic") {
      return formats;
    }
    if (licenseRights === "Pro") {
      return formats.slice(1, 3);
    }
    if (
      licenseRights === "Buy-Out" ||
      licenseRights === "Enterprise" ||
      licenseRights === "Exclusive"
    ) {
      return [formats.at(2) as string];
    }
    return formats;
  };

  const getIncludedPackages = () => {
    switch (licenseRights) {
      case "Exclusive":
        return packages.concat("Monetize your music video");
      case "Buy-Out":
        return ["Full transfer of copyright ownership"];
      case "Basic":
        return [
          "Publish your videos anywhere online (social media + websites)",
        ];
      case "Pro":
        return [
          "Publish your videos anywhere online (social media + websites)",
          "Includes sub-licensing rights: make videos for clients and businesses to post on their channels",
          "Use in digital ads",
          "Industrial events, trade shows and in-store displays",
        ];
      default:
        return packages;
    }
  };

  const getTotalPrice = () => {
    if ((isBeat(item) || isSong(item)) && "licenseRights" in item) {
      const rightObj = item.licenseRights?.find(
        (right) => right.name === licenseRights
      );

      const formatObj = rightObj?.files?.find(
        (file) => file.type === formatState[licenseRights as string]
      );

      const distributionPrice: number =
        distribution[licenseRights as string] === "Unlimited" &&
        licenseRights === "Non-Exclusive"
          ? Number(rightObj?.unlimitedDistribution)
          : 0;

      const addonPrice: number =
        (addons?.length ?? 0) > 0 && licenseRights === "Non-Exclusive"
          ? Number(rightObj?.monetizeMusic)
          : 0;

      let total: number = 0;
      total =
        Number(rightObj?.price || "0") +
        Number(formatObj?.extraPrice || "0") +
        distributionPrice +
        addonPrice;

      return total;
    }
  };

  const renderOptionItem = (val: string) => {
    return (
      <RadioGroup.Option
        id={`pricing-radioOption-${val}`}
        value={val}
        className={`flex w-full ${
          hasLicense(item, val) ? "cursor-pointer text-gray" : "text-grayLight"
        }`}
        disabled={!hasLicense(item, val)}
      >
        {({ checked }) => (
          <Tooltip
            title={!hasLicense(item, val) && "Not available for this track"}
            placement="top"
            arrow
            triggerClassName="w-full"
          >
            <div
              className={`flex items-center justify-center rounded-full w-full py-2 px-2 text-center ${
                checked && "bg-purple"
              }`}
            >
              <p className={`text-sm md:text-base ${checked && "text-white"}`}>
                {val}
              </p>
            </div>
          </Tooltip>
        )}
      </RadioGroup.Option>
    );
  };

  const getButtonText = (isProductDetail?: boolean) => {
    const itemAdded = !!cartItems.find((p) => p.id === item.id);

    // if (itemAdded) return "Save";
    if (isProductDetail && shouldShowUpdateItem) return "Update Cart Item";
    if (isProductDetail && itemAdded && !shouldShowUpdateItem) return "Added!";
    else if (itemAdded) return "Save";

    return "Add to Cart";
  };

  const formatInLicense = (format: string): boolean => {
    const license = item.licenseRights?.find(
      (right) => right.name === licenseRights
    );
    const file = license?.files?.find((file) => file.type === format);
    return file ? true : false;
  };

  const shouldDisableAddToCartButton =
    isProductDetail &&
    cartItems.some((cart) => cart.id === item.id) &&
    !shouldShowUpdateItem;

  return (
    <div
      className={`flex flex-col w-full lg:h-[670px] bg-white rounded-2xl justify-start overflow-hidden ${className}`}
    >
      <Tab.Group selectedIndex={licenseType === "recording" ? 0 : 1}>
        <Tab.List className="flex items-center rounded-t-2xl ">
          <Tab as={Fragment}>
            {({ selected }) => (
              <Tooltip
                title={isSong(item) && "Not available for songs"}
                placement="top"
                arrow
                triggerClassName={cx(
                  "w-full flex-1",
                  selected ? "bg-grayLight" : "bg-white"
                )}
              >
                <button
                  id="pricing-button-recording"
                  onClick={() => {
                    if (
                      isBeat(item) &&
                      (hasLicense(item, "Exclusive") ||
                        hasLicense(item, "Non-Exclusive"))
                    ) {
                      setLicenseType("recording");
                      setLicenseRights(licenseRightState["recording"]);
                    }
                  }}
                  className={`py-4 w-full text-xs sm:text-sm md:text-medium outline-none ${
                    selected
                      ? "rounded-t-2xl bg-white text-black"
                      : "rounded-br-2xl rounded-tl-2xl bg-grayLight text-gray font-light"
                  }`}
                >
                  Recording License
                </button>
              </Tooltip>
            )}
          </Tab>
          <Tab as={Fragment}>
            {({ selected }) => (
              <Tooltip
                title={!hasSyncLicense && "Not available for this track"}
                placement="top"
                arrow
                triggerClassName={cx(
                  "w-full flex-1",
                  selected ? "bg-grayLight" : "bg-white"
                )}
              >
                <button
                  id="pricing-button-sync"
                  onClick={() => {
                    if (hasSyncLicense) {
                      setLicenseType("sync");
                      setLicenseRights(licenseRightState["sync"]);
                    }
                  }}
                  className={`py-4 w-full text-xs sm:text-sm md:text-medium outline-none ${
                    selected
                      ? "rounded-t-2xl bg-white text-black"
                      : "rounded-bl-2xl rounded-tr-2xl bg-grayLight text-gray font-light"
                  }`}
                >
                  Sync License
                </button>
              </Tooltip>
            )}
          </Tab>
        </Tab.List>
        <Tab.Panels className="flex px-5 h-full overflow-hidden">
          {/* Recording licensing */}
          <Tab.Panel className="flex flex-col h-full w-full">
            {/* licenses types */}
            <RadioGroup
              value={licenseRightState[licenseType as string]}
              onChange={handleLicenseRightsChange}
              className="mb-7"
            >
              <div className="flex w-full rounded-full border-[1px] border-grayLight py-1 mt-5 p-1">
                {renderOptionItem("Non-Exclusive")}
                {renderOptionItem("Exclusive")}
                {/* {renderOptionItem("Buy-Out")} */}
              </div>
            </RadioGroup>

            <div className="flex flex-col h-full pb-7 lg:pb-0 overflow-y-auto overflow-x-hidden">
              {/* Radio Groups (Format, Distribution) */}
              <div className="flex flex-col space-y-5 pr-2">
                {/* Format group */}
                <PricingInfoFormatsRadioGroup
                  value={formatState[licenseRights as string]}
                  onChange={handleFormatChange}
                  item={item}
                  licenseRight={licenseRights}
                />

                {/* Uses */}
                <div className="flex items-start space-x-2">
                  <div>
                    <p className="lg:text-small text-gray font-bold font-evolventa">
                      USES:
                    </p>
                  </div>
                  <div className={`flex w-full justify-start ml-1 lg:ml-0`}>
                    <p className="lg:text-small text-gray font-evolventa">
                      Record 1 new song and/or Remix
                    </p>
                  </div>
                </div>

                {/* Distribution group */}
                <RadioGroup
                  value={distribution[licenseRights as string]}
                  onChange={handleDistributionChange}
                  className="flex flex-col lg:flex-row lg:items-start lg:space-x-11"
                >
                  <div className="mb-5 lg:mb-0">
                    <RadioGroup.Label className="lg:text-small text-gray font-bold font-evolventa lg:w-32">
                      DISTRIBUTION:
                    </RadioGroup.Label>
                  </div>
                  <div
                    className={`flex flex-col space-y-4 w-full justify-start gap-x-16 ml-1 lg:ml-0 ${
                      licenseRights === "Non-Exclusive"
                        ? "lg:justify-end"
                        : "lg:justify-start"
                    }`}
                  >
                    {licenseRights === "Non-Exclusive" ? (
                      distributions.map((distribution, index) => (
                        <RadioGroup.Option
                          id={`pricing-radio-distribution-${index}`}
                          key={distribution.name}
                          value={distribution.name}
                          className="cursor-pointer"
                        >
                          {({ checked }) => (
                            <div className="flex items-center">
                              <div
                                className={`flex justify-center items-center h-[17px] w-[17px] rounded-full border-[3px] ring-2 border-white mr-3 ${
                                  checked
                                    ? "ring-black bg-black"
                                    : "ring-grayLight"
                                }`}
                              />
                              <p className="lg:text-small text-gray font-evolventa">
                                {distribution.text}
                              </p>
                            </div>
                          )}
                        </RadioGroup.Option>
                      ))
                    ) : (
                      <p className="lg:text-small text-gray font-evolventa">
                        {distributions.find(
                          (dis) =>
                            dis.name === distribution[licenseRights as string]
                        )?.text ?? distribution[licenseRights as string]}
                      </p>
                    )}
                  </div>
                </RadioGroup>

                {/* Rights */}
                <div className="flex items-start space-x-2">
                  <div>
                    <p className="lg:text-small text-gray font-bold font-evolventa">
                      RIGHTS:
                    </p>
                  </div>
                  <div
                    className={`flex w-full justify-start gap-x-6 lg:gap-x-14 ml-1 lg:ml-0 text-gray font-evolventa`}
                  >
                    <p className="lg:text-small">
                      {licenseRights}
                      {licenseRights === "Non-Exclusive" &&
                        ". Others can license this after you"}
                      {licenseRights === "Exclusive" &&
                        ". No one will license this again after you"}
                    </p>
                  </div>
                </div>

                {/* Included */}
                <div className="flex flex-col space-y-3">
                  <div className={`flex flex-col gap-3`}>
                    {getIncludedPackages().map((pkg, index) => (
                      <div
                        key={`package-${index}`}
                        className="flex space-x-2 items-center text-gray font-evolventa"
                      >
                        <CheckIcon
                          height={20}
                          width={20}
                          className="text-green"
                        />
                        <p className="lg:text-small w-full">{pkg} </p>
                      </div>
                    ))}
                  </div>

                  {/* Add-ons */}
                  {licenseRights === "Non-Exclusive" && (
                    <div className="flex flex-col gap-5 mt-0 text-gray font-evolventa">
                      {addOns.map((addon, index) => (
                        <div
                          key={`addon-${index}`}
                          className="flex items-center space-x-3"
                        >
                          <button
                            id={`pricing-button-addon-${index}`}
                            onClick={() => handleSelectAddOn(addon.name)}
                            className={`flex items-center justify-center rounded-sm h-[20px] w-[20px] border-[2px] border-grayLight`}
                          >
                            {addons?.includes(addon.name) && (
                              <CheckIcon
                                height={20}
                                width={20}
                                className={
                                  addons?.includes(addon.name)
                                    ? "text-green"
                                    : ""
                                }
                              />
                            )}
                          </button>
                          <p className="lg:text-small w-full">
                            {addon.name} (Add-on)
                          </p>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="h-[143px] lg:h-0 w-full invisible"></div>
          </Tab.Panel>

          {/* Sync License */}
          <Tab.Panel className="flex flex-col h-full w-full">
            {/* licenses types */}
            <RadioGroup
              value={licenseRightState[licenseType as string]}
              onChange={handleLicenseRightsChange}
              className="mb-7"
            >
              <div className="flex w-full rounded-full border-[1px] border-grayLight py-1 mt-5 p-1">
                {renderOptionItem("Basic")}
                {renderOptionItem("Pro")}
                {renderOptionItem("Enterprise")}
              </div>
            </RadioGroup>

            <div className="flex flex-col h-full pb-7 lg:pb-0 overflow-y-auto overflow-x-hidden">
              <div className="flex flex-col space-y-5 pr-2">
                {licenseRights !== "Enterprise" && (
                  <PricingInfoFormatsRadioGroup
                    value={formatState[licenseRights as string]}
                    onChange={handleFormatChange}
                    item={item}
                    licenseRight={licenseRights}
                  />
                )}

                {/* Uses */}
                <div className="flex items-start space-x-2">
                  <div>
                    <p className="lg:text-small text-gray font-bold font-evolventa">
                      USES:
                    </p>
                  </div>
                  <div className={`flex w-full justify-start ml-1 lg:ml-0`}>
                    <p className="lg:text-small text-gray font-evolventa">
                      {licenseRights === "Basic" &&
                        "Podcasts + Personal Video Productions"}
                      {licenseRights === "Pro" &&
                        "Commercial + Personal Video Productions, Podcasts"}
                      {licenseRights === "Enterprise" &&
                        "Publish videos for TV shows, TV ads, major video streaming platforms and cinema"}
                    </p>
                  </div>
                </div>

                {/* Monetization */}
                {licenseRights != "Enterprise" && (
                  <>
                    <div className="flex items-start space-x-2">
                      <div>
                        <p className="lg:text-small text-gray font-bold font-evolventa">
                          MONETIZATION:
                        </p>
                      </div>
                      <div className={`flex w-full justify-start ml-1 lg:ml-0`}>
                        <p className="lg:text-small text-gray font-evolventa">
                          {licenseRights === "Basic" &&
                            "1 channel per platform"}
                          {licenseRights === "Pro" && "3 channels per platform"}
                        </p>
                      </div>
                    </div>

                    {/* Rights */}
                    <div className="flex items-start space-x-2">
                      <div>
                        <p className="lg:text-small text-gray font-bold font-evolventa">
                          RIGHTS:
                        </p>
                      </div>
                    </div>

                    {/* Included */}
                    <div className="flex flex-col space-y-3">
                      <div className={`flex flex-col gap-3`}>
                        {getIncludedPackages().map((pkg, index) => (
                          <div
                            key={`package-${index}`}
                            className="flex space-x-2 items-center text-gray font-evolventa"
                          >
                            <CheckIcon height={20} className="text-green" />
                            <p className="lg:text-small">{pkg} </p>
                          </div>
                        ))}
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className="h-[143px] lg:h-0 w-full invisible"></div>
          </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>

      {/* Sticky price */}
      <div className="lg:relative fixed bottom-5 lg:bottom-0 left-0 right-0 justify-between items-center py-6 px-5 mx-4 border-t-1 border-background bg-white rounded-b-2xl">
        <div className="flex w-full justify-between items-center">
          <div className="flex flex-col">
            {(getTotalPrice() !== 0 || licenseRights === "Non-Exclusive") && (
              <h3 className="text-2xl md:text-4xl">{`$${getTotalPrice()?.toFixed(
                2
              )}`}</h3>
            )}
            {licenseRights !== "Enterprise" && (
              <button
                id="pricing-button-viewLicense"
                className="text-xs md:text-base font-evolventa mt-2 font-bold hover:underline"
                onClick={goToLicensingAgreement}
              >
                View sample license
              </button>
            )}
          </div>
          {getTotalPrice() !== 0 || licenseRights === "Non-Exclusive" ? (
            <PrimaryButton
              id="pricing-button-addCart"
              disabled={shouldDisableAddToCartButton}
              className="[&>*>p]:text-sm [&>*>p]:md:text-base [&>*]:px-4 [&>*]:md:px-7"
              text={getButtonText(isProductDetail)}
              onClick={() => {
                handleAddToCart();
                if (localStorage.getItem("dont-ask") === "true") {
                  actionOnAddToCart?.();
                }
              }}
            />
          ) : (
            <div className="h-big">
              <a
                href={mailtoUrl}
                id={`pricing-button-addCart`}
                className="flex items-center space-x-3 min-w-[100px] h-full px-7 rounded-full bg-green disabled:bg-disabled disabled:text-subtitle disabled:cursor-not-allowed"
              >
                <p className="font-bold">Request Quote</p>
              </a>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PricingInfo;
