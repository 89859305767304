import { FilterType } from "../models/enums";
import { Key } from "../models/models";
import api from "./api-config";
import { useMemo } from "react";
import { useQuery } from "react-query";

export type UseKeysParams = {
  includeAny?: boolean;
};

export const useKeys = (
  { includeAny }: UseKeysParams = { includeAny: true }
) => {
  const {
    data,
    isLoading: loading,
    error,
  } = useQuery<Key[]>(
    "/keys",
    async () => {
      const response = await getKeysAsync();
      return response.keys;
    },
    { staleTime: Infinity }
  );

  const keys = useMemo(
    () =>
      includeAny
        ? ([
            { id: "1", name: "Any", type: FilterType.keyType },
            ...(data ?? []).map((key) => ({
              ...key,
              type: FilterType.keyType,
            })),
          ] as Key[])
        : (data?.map((key) => ({ ...key, type: FilterType.keyType })) as Key[]),
    [data, includeAny]
  );

  return {
    keys,
    loading,
    error,
  };
};

export const getKeysAsync = async () => {
  try {
    const res = await api.get("/keys");

    const keys = res.data.map((key: any) => {
      return {
        id: key.id,
        name: key.name,
        type: FilterType.keyType,
        isMajor: key.isMajor,
      } as Key;
    }) as Key[];

    return {
      keys: [...keys],
    };
  } catch (error) {
    console.error(error);

    return {
      keys: [],
    };
  }
};

export const getKeyById = async (id: string) => {
  const res = await api.get(`/keys/${id}`);

  return {
    key: {
      id: res.data.id,
      name: res.data.name,
      type: FilterType.keyType,
      isMajor: res.data.isMajor,
    },
  };
};
