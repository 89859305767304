import { ClassProps } from "@/lib/types/class-props";
import React from "react";

export type BaseSkeletonProps = ClassProps;

const BaseSkeleton = ({ className }: BaseSkeletonProps) => {
  return <div className={`animate-pulse bg-gray/40 ${className}`}></div>;
};

export default BaseSkeleton;
