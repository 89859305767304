import type { AppProps } from "next/app";
import React, { ReactElement, ReactNode } from "react";
import "../styles/globals.css";
import "react-loading-skeleton/dist/skeleton.css";
import Root from "../components/root";
import TermsBanner from "../components/marketplace/terms-banner";
import Head from "next/head";
import { Session } from "next-auth";
import BaseProviders from "@/components/layout/BaseProviders";
import { NextPage } from "next";
import UserbackButton from "@/components/navigation/navbar/userback-button";
import { GoogleAnalytics } from '@next/third-parties/google'

export type NextPageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

export type InitialAppProps = {
  session: Session | null;
};

export default function App({ Component, pageProps }: AppPropsWithLayout) {
  const getLayout = Component.getLayout ?? ((page) => page);
  const gaId = process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS || "G-XJVN657T2T";

  return (
    <>
      <BaseProviders>
        {getLayout(
          <Root>
            <Head>
              <meta
                name="viewport"
                content="width=device-width, initial-scale=1.0, maximum-scale=1.0"
              />
            </Head>
            <TermsBanner />
            <Component {...pageProps} />
            <UserbackButton />
          </Root>
        )}
      </BaseProviders>
      <GoogleAnalytics gaId={gaId} />
    </>
  );
}
