import { IconType } from "../icon-props";

const Edit: IconType = (
        <>
            <g clipPath="url(#clip0_621_17972)">
            <path d="M12.9 6.85808L17.142 11.1011L7.242 21.0001H3V16.7571L12.9 6.85708V6.85808ZM14.314 5.44408L16.435 3.32208C16.6225 3.13461 16.8768 3.0293 17.142 3.0293C17.4072 3.0293 17.6615 3.13461 17.849 3.32208L20.678 6.15108C20.8655 6.33861 20.9708 6.59292 20.9708 6.85808C20.9708 7.12325 20.8655 7.37756 20.678 7.56508L18.556 9.68608L14.314 5.44408Z" />
            </g>
            <defs>
            <clipPath id="clip0_621_17972">
            <rect width="24" height="24" fill="white"/>
            </clipPath>
            </defs>
        </>
    );

export default Edit;