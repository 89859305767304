import { IconType } from "../icon-props";

const AddShoppingCart: IconType = (
  <>
    <g clipPath="url(#clip0_621_17868)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.917 7C17.9716 6.67479 18 6.34071 18 6C18 5.65929 17.9716 5.32521 17.917 5H21.72C21.872 5 22.022 5.03466 22.1586 5.10134C22.2952 5.16801 22.4148 5.26495 22.5083 5.38479C22.6019 5.50462 22.6668 5.6442 22.6983 5.79291C22.7298 5.94162 22.7269 6.09555 22.69 6.243L20.19 16.243C20.1358 16.4592 20.011 16.6512 19.8352 16.7883C19.6595 16.9255 19.4429 17 19.22 17H5C4.73478 17 4.48043 16.8946 4.29289 16.7071C4.10536 16.5196 4 16.2652 4 16V4H2V2H5C5.26522 2 5.51957 2.10536 5.70711 2.29289C5.89464 2.48043 6 2.73478 6 3V6V15H18.438L20.438 7H17.917ZM4.58579 22.4142C4.96086 22.7893 5.46957 23 6 23C6.53043 23 7.03914 22.7893 7.41421 22.4142C7.78929 22.0391 8 21.5304 8 21C8 20.4696 7.78929 19.9609 7.41421 19.5858C7.03914 19.2107 6.53043 19 6 19C5.46957 19 4.96086 19.2107 4.58579 19.5858C4.21071 19.9609 4 20.4696 4 21C4 21.5304 4.21071 22.0391 4.58579 22.4142ZM16.5858 22.4142C16.9609 22.7893 17.4696 23 18 23C18.5304 23 19.0391 22.7893 19.4142 22.4142C19.7893 22.0391 20 21.5304 20 21C20 20.4696 19.7893 19.9609 19.4142 19.5858C19.0391 19.2107 18.5304 19 18 19C17.4696 19 16.9609 19.2107 16.5858 19.5858C16.2107 19.9609 16 20.4696 16 21C16 21.5304 16.2107 22.0391 16.5858 22.4142Z"
      />
      <path d="M13 2V5H16V7H12.999L13 10H11L10.999 7H8V5H11V2H13Z" />
    </g>
    <defs>
      <clipPath id="clip0_621_17868">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </>
);

export default AddShoppingCart;
