import { FilterType } from "../../models/enums";
import { Filter } from "../../models/models";
import { useSearchFilters } from "../../src/contexts/FilterContext";
import Icon from "../icons/icon";
import Close from "../icons/lib/close";

const FilterPill = (filter: Filter, index: number) => {
  // hooks
  const { removeFilter } = useSearchFilters();

  //actions
  const updateResults = async () => {
    removeFilter(filter);
  };

  const formatFilterName = () => {
    switch (filter.type) {
      case FilterType.bpmType:
        return `bpm: ${filter.name}`;
      case FilterType.priceType:
        const splitted = filter.name.split("-");
        const min = Number(splitted[0]);
        const max = Number(splitted[1]);
        let maxShown = splitted[1];
        if (max > 1000) maxShown = "1000+";
        if (min === 0) return `< $${maxShown}`;
        else return `$${min} - $${maxShown}`;
    }
    return filter.name;
  };

  return (
    <div
      className="flex items-center h-medium space-x-3 rounded-full py-1 px-3 border-[1px] border-grayLight truncate"
      key={`applied-${index}`}
    >
      <p>{formatFilterName()}</p>
      <button>
        <Icon onClick={updateResults} icon={Close} color="gray" />
      </button>
    </div>
  );
};
export default FilterPill;
