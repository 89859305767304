import Confused from "@/components/icons/lib/confused";
import CustomAlert from "@/components/modals/custom-alert";
import Modal from "@/components/modals/modal";
import React from "react";
import { useLogoutConfirmationModal } from "./state";
import { useRouter } from "next/router";

const LogoutConfirmationModal = () => {
  const router = useRouter();
  const [open, setOpen] = useLogoutConfirmationModal();

  const handleOnDismiss = () => {
    setOpen(false);
  };

  const handleOnCancel = () => {
    setOpen(false);
  };

  const handleLogout = () => {
    setOpen(false);
    router.push("/auth/logout");
  };

  return (
    <Modal
      open={open}
      onClose={handleOnCancel}
      onDismiss={handleOnDismiss}
      className="!w-[400px]"
    >
      <CustomAlert
        title="Logging out?"
        message="Hope to see you again soon!"
        negativeText="Cancel"
        positiveText="Log out"
        onPositive={() => handleLogout()}
        onNegative={handleOnCancel}
        icon={Confused}
      />
    </Modal>
  );
};

export default LogoutConfirmationModal;
