import { Option } from "@/models/sorting-filtering";
import { useSession } from "next-auth/react";
import React from "react";
import {
  removeLabelImpersonator,
  updateLabelImpersonator,
} from "@/lib/label-manager";
import { useCookies } from "react-cookie";
import useFetchLabelArtists from "./hooks/useFetchLabelArtists";
import { useSelectedArtistContext } from "./atoms/selected-artist-atom";
import DropdownAlt from "@/components/dropdown/dropdown-alt";

const ArtistPicker = () => {
  const session = useSession();
  const [, setCookie, removeCookie] = useCookies();
  const { options } = useFetchLabelArtists();

  const [selectedArtist, setSelectedArtist] = useSelectedArtistContext();

  // Helper Functions
  const handleArtistsSelect = async (option: Option) => {
    if (!session?.data?.user?.isLabelAccount) {
      return;
    }

    if (selectedArtist?.id === option.id) {
      // Remove labelImpersonate from table in backend
      await removeLabelImpersonator(session.data?.user.accessToken as string);

      // Update Client Side Frontend API calls to include artist_id in header
      removeCookie("impersonate-artist-id");
      setSelectedArtist(option);
      sessionStorage.setItem("selectedArtist", JSON.stringify(option));
      const storageEvent = new CustomEvent("onSessionStorageChange", {
        detail: {
          key: "selectedArtist",
          newValue: option.id,
        },
      });

      // Update ServerSide Frontend API calls to include artist_id in header
      await session.update({ impersonate_artist_id: "delete" });
      window.dispatchEvent(storageEvent);
    } else {
      try {
        // Send to the impersonate endpoint
        await updateLabelImpersonator(
          option.id as string,
          session.data?.user.accessToken as string
        );
      } catch (err) {
        console.error(err);
      }

      // Update Client Side Frontend API calls to include artist_id in header
      setCookie("impersonate-artist-id", option.id);
      setSelectedArtist(option);
      sessionStorage.setItem("selectedArtist", JSON.stringify(option));
      const storageEvent = new CustomEvent("onSessionStorageChange", {
        detail: {
          key: "selectedArtist",
          newValue: option.id as string,
        },
      });
      // Update ServerSide Frontend API calls to include artist_id in header
      await session.update({ impersonate_artist_id: option.id });
      window.dispatchEvent(storageEvent);
    }
  };

  if (session?.status === "authenticated") {
    return (
      <div className={`flex text-white font-righteous`}>
        {session.data.user.isLabelAccount &&
          options !== undefined &&
          options.length > 0 && (
            <>
              <DropdownAlt
                id="label-dropdown-account-selection"
                options={options!}
                value={selectedArtist?.name}
                onItemClick={(option) => {
                  handleArtistsSelect(option);
                }}
                showArrow={true}
                dropdownMenuArrowClassname="fill-white opacity-40"
                className="max-h-[320px] overflow-y-auto scrollbar-thin scrollbar-track-transparent"
                capitalizeOptionNames={false}
                showMarqueeOnHover
              >
                Artist
              </DropdownAlt>
            </>
          )}
      </div>
    );
  } else {
    return <></>;
  }
};

export default ArtistPicker;
