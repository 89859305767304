export function parseQueryString(): Record<string, string> {
  if (typeof window !== "undefined") {
    const urlParams = new URLSearchParams(window.location.search);
    const queryParams: Record<string, string> = {};

    for (const [key, value] of urlParams.entries()) {
      queryParams[key] = value;
    }

    return queryParams;
  } else {
    return {};
  }
}
