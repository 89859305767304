export const checkIfUrlExists = (url?: string | null) => {
  return new Promise<boolean>((resolve, reject) => {
    if (!url) {
      resolve(false);
    }
    const request = new XMLHttpRequest();
    request.open("GET", url ?? "", true);
    request.send();
    request.onload = function () {
      if (request.status == 200) {
        resolve(true);
      } else {
        resolve(false);
      }
    };
  });
};
