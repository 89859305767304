import { ReactNode } from "react";
import { Size } from "../../models/enums";
import Icon from "../icons/icon";

interface SecondaryButtonProps {
  id?: string;
  onClick?: Function;
  text?: string;
  icon?: ReactNode;
  size?: Size;
  badgeNumber?: number;
  className?: string;
}

const SecondaryButton = ({
  id,
  onClick,
  text,
  icon,
  size = Size.lg,
  badgeNumber = 0,
  className,
}: SecondaryButtonProps) => {
  const getHeight = () => {
    switch (size) {
      case Size.xl:
        return "h-big";
      case Size.lg:
        return "h-medium lg:h-big";
      case Size.md:
        return "h-medium";
      case Size.sm:
        return "h-small";
    }
  };

  const getFontSize = () => {
    switch (size) {
      case Size.md:
        return "text-small";
      case Size.sm:
        return "text-smallest";
    }
  };

  const getIconSize = () => {
    switch (size) {
      case Size.lg:
        return "md";
      case Size.md:
        return "sm";
      case Size.sm:
        return "xs";
    }
  };

  return (
    <div className={`${getHeight()} lg:relative ${className}`}>
      {badgeNumber > 0 && (
        <div className="absolute rounded-full w-[24px] h-[24px] bg-black right-[-7px]">
          <p className="text-small text-center text-white">{badgeNumber}</p>
        </div>
      )}
      <button
        id={id}
        className="flex items-center space-x-3 h-full min-w-[100px] px-3 lg:px-7 rounded-full border-[1px] "
        onClick={() => onClick?.()}
      >
        {icon && <Icon icon={icon} />}
        <p className={`${getFontSize()}`}>{text}</p>
      </button>
    </div>
  );
};
export default SecondaryButton;
