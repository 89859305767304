import { atom, useAtom, useAtomValue, useSetAtom } from "jotai";
import { getTimeStamp } from "../helpers";

export const profileTimestampAtom = atom(getTimeStamp());

export const useSetProfileTimestampContext = () =>
  useSetAtom(profileTimestampAtom);
export const useGetProfileTimestampContext = () =>
  useAtomValue(profileTimestampAtom);

export const profileValidPictureAtom = atom(true);

export const useProfileValidPicture = () => useAtom(profileValidPictureAtom);
