import { IconType } from "../icon-props";

const Key: IconType = (
        <>
<g clipPath="url(#clip0_621_18056)">
<path d="M10.758 11.828L18.607 3.979L20.021 5.393L18.607 6.808L21.081 9.282L19.667 10.697L17.192 8.222L15.778 9.636L17.899 11.757L16.485 13.172L14.364 11.05L12.172 13.242C12.8524 14.276 13.1253 15.5256 12.9379 16.7491C12.7505 17.9727 12.116 19.0832 11.1572 19.8661C10.1985 20.649 8.98346 21.0486 7.74715 20.9876C6.51085 20.9267 5.34106 20.4094 4.46398 19.536C3.58501 18.6603 3.06301 17.4891 2.99943 16.25C2.93586 15.0109 3.33525 13.7925 4.11999 12.8314C4.90473 11.8704 6.01875 11.2354 7.24554 11.05C8.47233 10.8646 9.72425 11.1419 10.758 11.828ZM10.121 18.121C10.4123 17.8455 10.6454 17.5143 10.8066 17.1471C10.9677 16.7799 11.0536 16.3842 11.0592 15.9832C11.0648 15.5823 10.9899 15.1843 10.839 14.8128C10.6881 14.4412 10.4643 14.1037 10.1808 13.8202C9.89725 13.5367 9.55974 13.3128 9.18822 13.162C8.8167 13.0111 8.4187 12.9362 8.01775 12.9418C7.6168 12.9474 7.22104 13.0333 6.85386 13.1944C6.48668 13.3556 6.15552 13.5887 5.87998 13.88C5.3335 14.4458 5.03112 15.2036 5.03796 15.9902C5.04479 16.7768 5.3603 17.5292 5.91652 18.0855C6.47275 18.6417 7.22518 18.9572 8.01177 18.964C8.79836 18.9709 9.55617 18.6685 10.122 18.122L10.121 18.121Z" />
</g>
<defs>
<clipPath id="clip0_621_18056">
<rect width="24" height="24" fill="white"/>
</clipPath>
</defs>
        </>
    );

export default Key;