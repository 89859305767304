import { Menu } from "@headlessui/react";
import { ReactNode, useRef } from "react";

import Icon from "../icons/icon";
import MenuItems from "./menu-items";
import { useRouter } from "next/router";
import { Link } from "@/data/navbar-links";
import SafeImage from "../safe-image";
import { parseQueryString } from "@/lib/utils/parseQueryString";
import ArrowDown from "../icons/lib/arrow-down";
import User from "../icons/lib/user";

interface DropdownProps {
  title?: string;
  icon?: ReactNode;
  image?: string | null;
  options: ({ name: string; href: string; sublinks?: any[] } | Link)[];
  className?: string;
  dropdownClassName?: string;
  dropdownIconClassName?: string;
  position?:
    | "top-left"
    | "top-right"
    | "bottom-left"
    | "bottom-right"
    | "right-top"
    | "right-bottom"
    | "left-top"
    | "left-bottom";
  children?: ReactNode;
  useHover?: boolean;
}

const NavigationDropdown = ({
  title,
  icon,
  image,
  options,
  className,
  dropdownClassName,
  dropdownIconClassName,
  position = "bottom-right",
  children,
  useHover = false,
}: DropdownProps) => {
  const router = useRouter();

  const buttonRef = useRef<HTMLButtonElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const timeoutDuration = 200;
  let timeout: string | number | NodeJS.Timeout | undefined;

  const openMenu = () => buttonRef?.current?.click();
  const closeMenu = () =>
    dropdownRef?.current?.dispatchEvent(
      new KeyboardEvent("keydown", {
        key: "Escape",
        bubbles: true,
        cancelable: true,
      })
    );

  const onMouseEnter = (closed: boolean) => {
    clearTimeout(timeout);
    closed && openMenu();
  };

  const onMouseLeave = (open: boolean) => {
    open && (timeout = setTimeout(() => closeMenu(), timeoutDuration));
  };

  const handleItemClick = (option: any) => {
    const { search } = parseQueryString();
    const completeUrl = search
      ? `${option.href}?search=${search}`
      : option.href;
    option.onClick ? option.onClick() : router.push(completeUrl);
  };

  return (
    <Menu as="div" className="relative inline-block text-left z-[1300]">
      {({ open }) => (
        <>
          <div
            className={` ${className ?? ""} cursor-pointer`}
            onClick={openMenu}
            onMouseEnter={() => useHover && onMouseEnter(!open)}
            onMouseLeave={() => useHover && onMouseLeave(open)}
          >
            <Menu.Button
              id="navigation-dropdown-account"
              ref={buttonRef}
              as={useHover ? "a" : "button"}
              className="flex w-fit items-center justify-center rounded-md gap-1 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
            >
              {icon && <Icon icon={icon} />}
              <span className="font-righteous">{title}</span>
              {image && (
                <SafeImage
                  defaultIcon={
                    <div className="flex justify-center items-center w-[28px] h-[28px] bg-gradient-to-br from-purple to-green rounded-full">
                      <Icon
                        icon={User}
                        color="white"
                        className="h-full w-full mx-2"
                      />
                    </div>
                  }
                  src={image}
                  alt="image"
                  width={28}
                  height={28}
                  className="rounded-full w-[28px] h-[28px] object-cover"
                  blurDataURL="/assets/images/placeholder.png"
                  noCache={false}
                />
              )}
              {children}
              <Icon icon={ArrowDown} className={dropdownIconClassName} />
            </Menu.Button>
          </div>
          <MenuItems
            position={position}
            options={options}
            selectedOption={
              options.find((option) => option.href === router.pathname)?.name
            }
            onItemClick={handleItemClick}
            dropdownRef={dropdownRef}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            useHover={useHover}
            open={open}
            className={dropdownClassName}
          />
        </>
      )}
    </Menu>
  );
};

export default NavigationDropdown;
