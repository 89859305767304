import { atom, useAtom, useSetAtom } from "jotai";

export const navbarAppliedSearchQueryAtom = atom("");
export const navbarSearchTextAtom = atom("");

/**
 * Search term from the search bar inside the navbar. It will synchronize the
 * value from that search bar to be used in independent filter contexts.
 */
export const useNavbarAppliedSearchQueryContext = () =>
  useAtom(navbarAppliedSearchQueryAtom);

export const useSetNavbarAppliedSearchQuery = () =>
  useSetAtom(navbarAppliedSearchQueryAtom);

/**
 * Text that will be included inside the search text input.
 */
export const useNavbarSearchTextContext = () => useAtom(navbarSearchTextAtom);
export const useSetNavbarSearchText = () => useSetAtom(navbarSearchTextAtom);
