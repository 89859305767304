import { AssetSound } from "@/components/account/audiolab/controls/types";
import { MAX_BPM, MAX_PRICE } from "../components/filter";
import { FilterType, ItemStatus, SortOptions } from "../models/enums";
import { Artist, Filter, SoundKit, SubGenre } from "../models/models";
import api from "./api-config";
import { SearchConfig } from "./types/search/search-config";
import { SearchCountConfig } from "./types/search/search-count-config";
import { getSortingFromOptions } from "./utils/search/getSortingFromOptions";

export const createSoundkitAsync = async (
  name: string,
  artistId: string,
  token: string
) => {
  const res = await api.post(
    `/soundkits`,
    {
      name: name,
      artist_id: artistId,
      price: 0,
      is_featured: false,
      status: ItemStatus.Processing,
      is_royalty_free: true,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  return { soundkit: mapSoundKitJsonToSoundKit(res.data) };
};

export const getSoundKitsAsyncController = new AbortController();

export const getSoundKitsAsync = async (
  query?: string,
  appliedFilters?: Filter[],
  sorting?: any,
  order?: boolean,
  limit?: number,
  offset?: number,
  status?: number,
  includeAll?: boolean
) => {
  const genre = appliedFilters?.find(
    (filter) => filter.type === FilterType.genreType
  )?.id;
  const styles = appliedFilters
    ?.filter((filter) => filter.type === FilterType.styleType)
    .map((fil) => fil.id);
  const date =
    parseInt(
      appliedFilters?.find((filter) => filter.type === FilterType.recencyType)
        ?.id!
    ) - 1;
  const license = appliedFilters?.find(
    (filter) => filter.type === FilterType.licenseType
  )?.id!;
  const artists = appliedFilters
    ?.filter((filter) => filter.type === FilterType.artistType)
    .map((fil) => fil.id);
  const kinds = appliedFilters
    ?.filter((filter) => filter.type === FilterType.kindsType)
    .map((fil) => fil.id);

  let priceFrom: number = 0;
  let priceTo: number = MAX_PRICE;
  appliedFilters?.find((filter) => {
    if (filter.type === FilterType.priceType) {
      priceFrom = parseInt(filter.name.replaceAll("$", "").split("-").at(0)!);
      priceTo = parseInt(filter.name.replaceAll("$", "").split("-").at(1)!);
    }
  });

  const res = await api.post(
    "/soundkits/search",
    {
      filter: {
        name: query,
        genres: genre ? [genre] : null,
        sub_genres: styles?.length! > 0 ? styles : null,
        price_from: priceFrom === 0 && priceTo === MAX_PRICE ? null : priceFrom,
        price_to: priceTo === MAX_PRICE && priceFrom === 0 ? null : priceTo,
        date_added: date,
        kinds: kinds?.length! > 0 ? kinds : null,
        is_royalty_free: license === "4" ? true : null,
        artist_ids: artists?.length! > 0 ? artists : null,
        isFeatured: appliedFilters?.find(
          (filter) => filter.type === FilterType.featuredType
        )
          ? true
          : null,
      },
      sorting: {
        // sort_isFeatured: sorting === 'Featured' ? true : null,
        sort_isAlphabetical:
          sorting?.name === SortOptions.az
            ? true
            : sorting?.name === SortOptions.za
            ? false
            : null,
        sort_isNewest:
          sorting?.name === SortOptions.new
            ? true
            : sorting?.name === SortOptions.old
            ? false
            : null,
        sort_isCheapest:
          sorting?.name === SortOptions.cheap
            ? true
            : sorting?.name === SortOptions.expensive
            ? false
            : null,
        sort_random:
          sorting?.name === SortOptions.random ? sorting?.value : null,
      },
      limit: limit,
      offset: offset,
      includeAll,
    },
    { signal: getSoundKitsAsyncController.signal }
  );

  const soundkits = res.data.map((soundkit: any) =>
    mapSoundKitJsonToSoundKit(soundkit)
  );

  return { soundkits: soundkits };
};

/**
 * Perform a search to obtain soundkits by the given filters and sorting options.
 *
 * @param config the configuration for performing this search
 * @returns an array of {@link Beat}.
 */
export const getSoundkits = async ({
  filter,
  sorting,
  limit,
  offset,
  includeAll,
}: SearchConfig) => {
  const res = await api.post(
    "/soundkits/search",
    {
      filter,
      sorting: sorting ? sorting : getSortingFromOptions(),
      limit,
      offset,
      includeAll,
    },
    { signal: getSoundKitsAsyncController.signal }
  );

  return res.data?.map((soundkit: any) =>
    mapSoundKitJsonToSoundKit(soundkit)
  ) as SoundKit[];
};

const getSoundKitsOptimizedAsyncController = new AbortController();

/**
 * Perform a search to obtain soundkits by the given filters and sorting options.
 *
 * @param config the configuration for performing this search
 * @returns an array of {@link Beat}.
 */
export const getSoundkitsOptimized = async ({
  filter,
  sorting,
  limit,
  offset,
  includeAll,
}: SearchConfig) => {
  const res = await api.post(
    "/soundkits/search/optimized",
    {
      filter,
      sorting: sorting ? sorting : getSortingFromOptions(),
      limit,
      offset,
      includeAll,
    },
    { signal: getSoundKitsOptimizedAsyncController.signal }
  );

  return res.data?.map((soundkit: any) =>
    mapSoundKitJsonToSoundKit(soundkit)
  ) as SoundKit[];
};

export const getAudiolabSoundKitsAsyncController = new AbortController();

export type GetAudiolabSoundkitsParams = {
  token?: string | null;
} & SearchConfig;

/**
 * Perform a search to obtain audiolab soundkits by the given filters and
 * sorting options.
 *
 * @param config the configuration for performing this search
 * @returns an array of {@link Beat}.
 */
export const getAudiolabSoundkits = async ({
  filter,
  sorting,
  limit,
  offset,
  includeAll,
  token,
}: GetAudiolabSoundkitsParams) => {
  const res = await api.post(
    "/audiolab/soundkits/search",
    {
      filter,
      sorting,
      limit,
      offset,
      includeAll,
    },
    {
      signal: getAudiolabSoundKitsAsyncController.signal,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  return res.data?.map((soundkit: any) =>
    mapSoundKitJsonToSoundKit(soundkit)
  ) as SoundKit[];
};

export const getSoundKitsCountAsync = async (
  query?: string,
  appliedFilters?: Filter[]
) => {
  const genre = appliedFilters?.find(
    (filter) => filter.type === FilterType.genreType
  )?.id;
  const styles = appliedFilters
    ?.filter((filter) => filter.type === FilterType.styleType)
    .map((fil) => fil.id);
  const moods = appliedFilters
    ?.filter((filter) => filter.type === FilterType.moodsType)
    .map((fil) => fil.id);
  const date =
    parseInt(
      appliedFilters?.find((filter) => filter.type === FilterType.recencyType)
        ?.id!
    ) - 1;
  // const license = parseInt(appliedFilters?.find(filter => filter.type === FilterType.licenseType)?.id!) - 2;
  // const right = parseInt(appliedFilters?.find(filter => filter.type === FilterType.rightsType)?.id!);
  const artists = appliedFilters
    ?.filter((filter) => filter.type === FilterType.artistType)
    .map((fil) => fil.id);

  let priceFrom: number = 0;
  let priceTo: number = MAX_PRICE;
  appliedFilters?.find((filter) => {
    if (filter.type === FilterType.priceType) {
      priceFrom = parseInt(filter.name.replaceAll("$", "").split("-").at(0)!);
      priceTo = parseInt(filter.name.replaceAll("$", "").split("-").at(1)!);
    }
  });

  let bpmFrom: number = 0;
  let bpmTo: number = MAX_BPM;
  appliedFilters?.find((filter) => {
    if (filter.type === FilterType.bpmType) {
      bpmFrom = parseInt(filter.name.split("-").at(0)!);
      bpmTo = parseInt(filter.name.split("-").at(1)!);
    }
  });

  const res = await api.post("/soundkits/count", {
    filter: {
      name: query,
      genres: genre ? [genre] : null,
      sub_genres: styles?.length! > 0 ? styles : null,
      moods: moods?.length! > 0 ? moods : null,
      price_from: priceFrom === 0 && priceTo === MAX_PRICE ? null : priceFrom,
      price_to: priceTo === MAX_PRICE && priceFrom === 0 ? null : priceTo,
      bpm_from: bpmFrom === 0 && bpmTo === MAX_BPM ? null : bpmFrom,
      bpm_to: bpmTo === MAX_BPM && bpmFrom === 0 ? null : bpmTo,
      date_added: date,
      // license_type: license,
      // license_right: right,
      artist_ids: artists?.length! > 0 ? artists : null,
    },
  });

  return { count: res.data };
};

/**
 * Counts how many elements are in DB with the given filters.
 *
 * @param config is the configuration params
 * @returns the count
 */
export const getSoundkitsCount = async ({ filter }: SearchCountConfig) => {
  const res = await api.post("/soundkits/count", {
    filter,
  });
  return { count: res.data };
};

export const getSoundKitByIdAsync = async (id: string) => {
  const soundkitJson = await (await api.get(`/soundkits/${id}`)).data;

  const soundkit = mapSoundKitJsonToSoundKit(soundkitJson);

  return { soundkit: soundkit };
};

export const getSoundKitAssetsById = async (id: string) => {
  const response = await api.get<AssetSound[]>(`/soundkits/assets/${id}`);
  return response.data;
};

export const updateSoundkitByIdAsync = async (
  id: string,
  payload: any,
  token: string
) => {
  try {
    const res = await api.put(`/soundkits/${id}`, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (res) {
      return { soundkit: mapSoundKitJsonToSoundKit(res.data) };
    }
  } catch (e) {
    throw e;
  }
  return {};
};

export const mapSoundKitJsonToSoundKit = (soundkitJson: any) => {
  return {
    id: soundkitJson.id,
    cover: soundkitJson.pictureUrl,
    name: soundkitJson.name,
    price: parseFloat(soundkitJson.price),
    description: soundkitJson.description,
    genres:
      soundkitJson.sub_genres?.length > 0
        ? soundkitJson.sub_genres[0]?.genre
        : null,
    styles:
      soundkitJson.sub_genres?.map((sub: any) => {
        return {
          id: sub.id,
          name: sub.name,
          genreId: sub.genre_id,
          type: FilterType.styleType,
        } as SubGenre;
      }) ?? null,
    artist: {
      id: soundkitJson.artist?.id,
      name: soundkitJson.artist?.name,
      firstName: soundkitJson.artist?.firstName,
      lastName: soundkitJson.artist?.lastName,
      bio: soundkitJson.artist?.bio,
      createdAt: soundkitJson.artist?.createdAt,
      profilePicUrl: soundkitJson.artist?.profilePicUrl,
    } as Artist,
    royaltyFree: soundkitJson.is_royalty_free || null,
    types:
      soundkitJson.kinds?.map((k: any) => {
        return {
          ...k,
          type: FilterType.kindsType,
        };
      }) ?? null,
    soundsLike: soundkitJson.soundslike?.map((sl: any) => sl.name) ?? [],
    sample: soundkitJson.audio_preview,
    file: soundkitJson.file
      ? {
          id: soundkitJson.file.id,
          name: soundkitJson.file.name,
          size: soundkitJson.file.size,
        }
      : null,
    publishDate: soundkitJson.published_date,
    status: soundkitJson?.status,
    type: "sounds",
    assets: soundkitJson.assets,
    // waveForms: {
    //     desktopUrl: soundkitJson.wave_forms?.desktop_url,
    //     mobileUrl: soundkitJson.wave_forms?.mobile_url,
    // }
  } as SoundKit;
};
