import { getArtistsAsync } from "@/lib/artists-manager";
import { getBeatsAsync } from "@/lib/beats-manager";
import { getSongsAsync } from "@/lib/songs-manager";
import { getSoundKitsAsync } from "@/lib/soundkits-manager";
import { Filter, ViewObject } from "@/models/models";

export type GetEntitySearchParams = {
  category: string;
  query?: string;
  appliedFilters?: Filter[];
  sorting?: any;
  order?: boolean;
  limit?: number;
  offset?: number;
  status?: number;
};

/**
 * Performs a search given a category with the selected params. Unified function
 * that will help selecting the proper endpoint call.
 *
 * It would search through beats, sounds, songs and creators.
 */
export const searchEntitiesByCategory = async ({
  category,
  query,
  appliedFilters,
  sorting,
  order,
  limit,
  offset,
}: GetEntitySearchParams) => {
  try {
    switch (category.toLowerCase()) {
      case "beats":
        const { beats } = await getBeatsAsync(
          query || "",
          appliedFilters || [],
          sorting,
          order,
          limit,
          offset
        );
        return beats as ViewObject[];
      case "sounds":
        const { soundkits } = await getSoundKitsAsync(
          query || "",
          appliedFilters || [],
          sorting,
          order,
          limit,
          offset
        );
        return soundkits as ViewObject[];
      case "creators":
        const { artists } = await getArtistsAsync(
          query || "",
          [],
          sorting,
          order,
          limit,
          offset
        );
        return artists as unknown as ViewObject[];
      case "songs":
        const { songs } = await getSongsAsync(
          query || "",
          appliedFilters || [],
          sorting,
          order,
          limit,
          offset
        );
        return songs as ViewObject[];
      default:
        console.warn(
          "searchEntitiesByCategory",
          `category ${category} could not match any entity search`
        );
        return null;
    }
  } catch (error) {
    console.error(error);
    return null;
  }
};
