import { ReactNode } from "react";
import PrimaryButton from "../buttons/primary-button";
import { useRouter } from "next/router";
import Icon from "../icons/icon";
import { ClassProps } from "@/lib/types/class-props";
import { cx } from "@/lib/utils/className.utils";

interface CustomAlertProps extends ClassProps {
  title: string;
  icon?: ReactNode;
  message?: string | React.ReactElement;
  color?: string;
  positiveText: string;
  negativeText?: string;
  onPositive: Function;
  onNegative?: Function;
}

const CustomAlert = ({
  title,
  icon,
  message,
  color,
  positiveText,
  negativeText,
  onPositive,
  onNegative,
  className,
}: CustomAlertProps) => {
  // hooks
  const router = useRouter();

  return (
    <div
      className={cx(
        "flex flex-col justify-center items-center gap-5 rounded-3xl w-full bg-white p-10",
        className
      )}
    >
      {icon && (
        <div className="rounded-full shadow-normal p-10 flex items-center justify-center">
          <Icon
            icon={icon}
            className={`rounded-full w-[45px] h-[45px] fill-purple`}
            viewBox="0 0 32 32"
          />
        </div>
      )}
      <h3 className="text-center leading-snug mt-5">{title}</h3>

      <p className="text-gray text-center">{message}</p>

      <div className="flex mt-5 items-center gap-7">
        {onNegative && (
          <button className="h-full" onClick={() => onNegative()}>
            {negativeText}
          </button>
        )}
        <PrimaryButton onClick={() => onPositive?.()} text={positiveText} />
      </div>
    </div>
  );
};

export default CustomAlert;
