import { cx } from "@/lib/utils/className.utils";
import Link from "next/link";
import React, { AnchorHTMLAttributes } from "react";
import SkeletonText from "./skeleton-text";

export type AppLinkProps = {
  /**
   * If loading disables clicks and shows a spinner.
   */
  loading?: boolean;
} & AnchorHTMLAttributes<HTMLAnchorElement>;

/**
 * Simple wrapper of Next.js Link component to use also an anchor tag.
 */
const AppLink = ({ className, loading, children, ...props }: AppLinkProps) => {
  if (loading) {
    return (
      <SkeletonText
        className={cx("inline-block relative font-bold", className)}
      >
        <span className="opacity-0">{children}</span>
      </SkeletonText>
    );
  }

  return (
    <Link
      href={props.href ?? ""}
      className={cx("relative font-bold", className)}
      {...props}
    >
      {children}
    </Link>
  );
};

export default AppLink;
