import { useRouter } from "next/router";
import {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { MAX_BPM, MAX_PRICE } from "../../../components/filter";
import {
  licensingTypes,
  recency,
  rights,
  searchCategories,
} from "../../../data/dummy";
import { Categories, FilterOrder, FilterType } from "../../../models/enums";
import {
  Artist,
  Filter,
  FilterContextEntityType,
  Genre,
  RecentSearch,
  SearchCategory,
  ViewObject,
} from "../../../models/models";
import { useAudioPlayer } from "../AudioPlayerContext";
import useFilterDataContext from "../FilterDataContext";
import { first } from "lodash";
import { noop } from "lodash";
import { searchEntitiesByCategory } from "./searchEntitiesByCategory";
import { getCountEntitiesByCategory } from "./getCountEntitiesByCategory";
import { PrimitiveAtom, useAtom } from "jotai";
import { isEmpty } from "lodash";
import { AddFiltersParams } from "./types/add-filters-params";
import { uniqBy } from "lodash";
import { isFunction } from "lodash";
import {
  BPM_INFINITY_HIGH,
  BPM_INFINITY_LOW,
} from "@/components/marketplace/search-page/filter-sidebar/constants/bpm";
import {
  PRICE_INFINITY_HIGH,
  PRICE_INFINITY_LOW,
} from "@/components/marketplace/search-page/filter-sidebar/constants/price";
import {
  DURATION_INFINITY_HIGH,
  DURATION_INFINITY_LOW,
} from "@/components/marketplace/search-page/filter-sidebar/constants/duration";

const RECENT_KEY = "recentSearches";

const useFilterController = ({
  categoryAtom,
  initializeSelectedFilters = true,
  includeAny = true,
  preventAutomaticSearch = false,
}: FilterProviderProps) => {
  // hooks
  const router = useRouter();
  const { setCurrentPlaylist } = useAudioPlayer();

  // atoms
  // This atom describes the linked category that should reflect the selected
  // category of this context.
  const [linkedCategory] = useAtom(categoryAtom);

  // states
  const [refresh, setRefresh] = useState<number>(0);
  const [popupVisible, setPopupVisible] = useState(false);
  const [focusedSearchBar, setFocusedSearchBar] = useState(false);
  const [mobileSearchVisible, setMobileSearchVisible] = useState(false);
  const [query, setQuery] = useState("");
  const [lastSearchQuery, setLastSearchQuery] = useState("");
  const [recentSearches, setRecentSearches] = useState<RecentSearch[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<SearchCategory>(
    searchCategories[0]
  );
  const [hideSearchBar, setHideSearchBar] = useState(true);

  const [sortOrder, setSortOrder] = useState<boolean>(true);
  const [selectedSort, setSelectedSort] = useState<any>({
    name: "Random",
    value: Math.random() * 50,
  });
  const [selectedLicense, setSelectedLicense] = useState<Filter>(
    licensingTypes.at(0)!
  );
  const [selectedRight, setSelectedRight] = useState<Filter[]>([]);
  const [selectedRecency, setSelectedRecency] = useState<Filter>(
    recency.at(0)!
  );
  const [selectedMoods, setSelectedMoods] = useState<Filter[]>([]);
  const [selectedGenre, setSelectedGenre] = useState<Genre | null>(null);
  const [selectedGenres, setSelectedGenres] = useState<Genre[] | null>(null);
  const [selectedStyles, setSelectedStyles] = useState<Filter[]>([]);
  const [selectedKeys, setSelectedKeys] = useState<Filter[]>([]);
  const [selectedStems, setSelectedStems] = useState<Filter[]>([]);
  const [majorEnabled, setMajorEnabled] = useState(true);
  const [isSharp, setIsSharp] = useState(true);
  const [minorEnabled, setMinorEnabled] = useState(false);
  const [bpmRange, setBpmRange] = useState<number[]>([
    BPM_INFINITY_LOW,
    BPM_INFINITY_HIGH,
  ]);
  const [priceRange, setPriceRange] = useState<number[]>([
    PRICE_INFINITY_LOW,
    PRICE_INFINITY_HIGH,
  ]);
  const [durationRange, setDurationRange] = useState<number[]>([
    DURATION_INFINITY_LOW,
    DURATION_INFINITY_HIGH,
  ]);
  const [selectedKinds, setSelectedKinds] = useState<Filter[]>([]);
  const [selectedVocals, setSelectedVocals] = useState<Filter[]>([]);
  const [appliedFilters, setAppliedFilters] = useState<Filter[]>([]);
  const [results, setResults] = useState<ViewObject[]>([]);
  const [resultsCount, setResultsCount] = useState<number>(0);
  const [resultsLoading, setResultsLoading] = useState<boolean>(false);
  const [resultsCountLoading, setResultsCountLoading] = useState(false);
  const [recommendedResults, setRecommendedResults] = useState<
    ViewObject[] | Artist[]
  >([]);

  // By using this hook we ensure that we obtain the moods, keys, and kinds by
  // calling the endpoint just once by session.
  const { genres, subGenres, moods, keys, kinds } = useFilterDataContext({
    category: selectedCategory,
    selectedGenre,
    includeAny,
  });

  // Internally we use this ref to avoid depending on React useState timings
  // so we call the handleSearch only one at a time.
  const blockLoadingRef = useRef(false);

  useEffect(() => {
    clearAllFilters();

    if (initializeSelectedFilters) {
      setSelectedGenre(first(genres)!);
      setSelectedStyles([subGenres?.find((subGenre) => subGenre.id === "1")!]);
    }

    if (selectedCategory.name === Categories.songs) {
      setAppliedFilters([rights.at(2)!, licensingTypes.at(2)!]);
    }
    // TODO: need to improve this effect to simplify the deps or make the deps stable
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCategory]);

  // Auto select 'Non-Exclusive' right if user selects 'Sync' license.
  useEffect(() => {
    if (selectedLicense.name === "Sync") {
      setAppliedFilters([
        ...appliedFilters.filter(
          (fil) =>
            fil.type !== FilterType.licenseType &&
            fil.type !== FilterType.rightsType
        ),
        rights.at(2)!,
        licensingTypes.at(2)!,
      ]);
      setSelectedRight([rights.at(2)!]);
    } else if (selectedLicense.name === "Recording") {
      setAppliedFilters([
        ...appliedFilters.filter((fil) => fil.type !== FilterType.licenseType),
        licensingTypes.at(1)!,
      ]);
    } else if (selectedLicense.name === "Any") {
      removeFromType(FilterType.licenseType);
    }
    // TODO: need to improve this effect to simplify the deps or make the deps stable
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLicense]);

  useEffect(() => {
    if (preventAutomaticSearch) {
      return;
    }

    handleSearch({
      quick: false,
      category: router?.query?.category! as FilterContextEntityType,
      limit: 30,
      offset: 0,
      searchQuery: lastSearchQuery,
    });
    setCurrentPlaylist(results);
    // TODO: need to improve this effect to simplify the deps or make the deps stable
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSort, sortOrder, refresh, lastSearchQuery]);

  // Sort applied filters by type
  useEffect(() => {
    const sortedFilters = appliedFilters.sort((a, b) => {
      const orderA = FilterOrder[a.type as any] as unknown as number;
      const orderB = FilterOrder[b.type as any] as unknown as number;
      return orderA - orderB;
    });
    setAppliedFilters(sortedFilters);
  }, [appliedFilters]);

  // Set the Playlist on every 'results' update.
  useEffect(() => {
    setCurrentPlaylist(results);
  }, [results, setCurrentPlaylist]);

  // Set the subGenres array on every genre change and clean the selected styles.
  useEffect(() => {
    if (initializeSelectedFilters) {
      setSelectedStyles([subGenres?.find((subGenre) => subGenre.id === "1")!]);
      removeFromType(FilterType.styleType);
    }
    // TODO: need to improve this effect to simplify the deps or make the deps stable
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedGenre]);

  // update selected category when linked category changes.
  useEffect(() => {
    if (linkedCategory) {
      setSelectedCategory(linkedCategory);
    }
  }, [linkedCategory]);

  // actions
  const focusSearchBar = () => setFocusedSearchBar(true);
  const blurSearchBar = () => setFocusedSearchBar(false);

  const toggleMobileSearch = (value: boolean) => setMobileSearchVisible(value);

  const addNewSearch = () => {
    if (query !== "") {
      setRecentSearches([
        { id: +new Date(), name: query },
        ...(recentSearches ?? []),
      ]);
      // Save into local storage...
      localStorage.setItem(RECENT_KEY, JSON.stringify(recentSearches ?? []));
    }
  };

  const setSortAndUpdateRandom = (sort: any) => {
    const random = Math.random() * 50;
    // get a random prime number between 0 and 1000
    // const random = Math.floor(Math.random() * 1000) + 1;
    setSelectedSort({ ...sort, value: random });
  };

  const removeSearch = (search: RecentSearch) => {
    const updatedRecent = recentSearches.filter((q) => q.id !== search.id);
    setRecentSearches(updatedRecent);
    // Remove from local storage...
    localStorage.setItem(RECENT_KEY, JSON.stringify(updatedRecent));
  };

  // Multiselection is only allowed for Moods, Styles, Kinds and Rights.
  const handleMultiSelect = (filter: Filter) => {
    switch (filter.type) {
      case FilterType.styleType:
        if (
          selectedStyles.some(
            (fil) => fil?.id === filter.id && filter.id !== "1"
          )
        ) {
          setSelectedStyles(
            selectedStyles.filter((fil) => fil?.id !== filter.id)
          );
          removeFilter(filter);
        } else if (filter.id === "1") {
          setSelectedStyles([filter]);
          removeFromType(filter.type);
        } else {
          setSelectedStyles([
            ...selectedStyles.filter((fil) => fil?.id !== "1"),
            filter,
          ]);
          addFilter(filter);
        }
        break;
      case FilterType.moodsType:
        if (
          selectedMoods.some(
            (fil) => fil?.id === filter.id && filter.id !== "1"
          )
        ) {
          setSelectedMoods(
            selectedMoods.filter((fil) => fil?.id !== filter.id)
          );
          removeFilter(filter);
        } else if (filter.id === "1") {
          setSelectedMoods([filter]);
          removeFromType(filter.type);
        } else {
          setSelectedMoods([
            ...selectedMoods.filter((fil) => fil?.id !== "1"),
            filter,
          ]);
          addFilter(filter);
        }
        break;
      case FilterType.kindsType:
        if (filter.id === "1") {
          setSelectedKinds([filter]);
        } else if (selectedKinds.some((fil) => fil?.id === filter.id)) {
          setSelectedKinds(
            selectedKinds.filter((fil) => fil?.id !== filter.id)
          );
          removeFilter(filter);
        } else {
          setSelectedKinds([
            ...selectedKinds.filter((fil) => fil?.id !== "1"),
            filter,
          ]);
          addFilter(filter);
        }
        break;
      case FilterType.rightsType:
        if (
          selectedCategory.name === "Songs" ||
          selectedLicense.name === "Sync"
        ) {
          return;
        }

        if (filter.id !== "1") {
          if (selectedRight.find((fil) => fil.id === filter.id)) {
            setSelectedRight(
              selectedRight.filter((fil) => fil.id !== filter.id)
            );
            removeFilter(filter);
          } else {
            setSelectedRight([
              ...selectedRight.filter((fil) => fil.name !== "Any"),
              filter,
            ]);
            addFilter(filter);
          }
        } else {
          removeFromType(FilterType.rightsType);
          setSelectedRight([filter]);
        }
        break;
    }
  };

  const handleSingleSelect = (filter: Filter) => {
    switch (filter.type) {
      case FilterType.keyType:
        if (selectedKeys.length > 0) {
          if (selectedKeys.find((k) => k.id === filter.id)) {
            setSelectedKeys([]);
            removeFromType(FilterType.keyType);
          } else {
            setSelectedKeys([filter]);
            addFilter(filter);
          }
        } else {
          setSelectedKeys([filter]);
          addFilter(filter);
        }
        break;
    }
  };

  // Remove a filter from a type.
  const removeFromType = (type: FilterType) => {
    const updatedFilters = appliedFilters.filter((fil) => fil.type !== type);
    setAppliedFilters(updatedFilters);
    return updatedFilters;
  };

  const removeFilter = async (filter: Filter) => {
    if (!popupVisible) setRefresh(refresh + 1);
    const prevFilters = appliedFilters;
    if (
      selectedCategory.name === "Songs" &&
      (filter.type === FilterType.licenseType ||
        filter.type === FilterType.rightsType)
    ) {
      // dont allow remove
      return appliedFilters;
    } else if (
      selectedCategory.name === "Beats" &&
      selectedLicense.name === "Sync" &&
      filter.type === FilterType.rightsType
    ) {
      // dont allow remove
      return appliedFilters;
    } else {
      switch (filter.type) {
        case FilterType.licenseType:
          setSelectedLicense(licensingTypes[0]);
          break;
        case FilterType.keyType:
          setSelectedKeys([]);
          break;
        case FilterType.recencyType:
          setSelectedRecency(recency[0]);
          break;
        case FilterType.bpmType:
          setBpmRange([0, MAX_BPM]);
          break;
        case FilterType.priceType:
          setPriceRange([0, MAX_PRICE]);
          break;
        case FilterType.genreType:
          setSelectedGenre(genres?.at(0)!);
          break;
      }

      // Remove subgenres when genre is being removed
      if (filter.type === FilterType.genreType) {
        // Clear only subgenres related to this genre being removed
        const genre = genres?.find((genre) => genre.id === filter.id);
        const filtersWithoutGenre = prevFilters.filter(
          (fil) => !(filter.id === fil.id && fil.type === FilterType.genreType)
        );
        // Also remove any associated styles from the filters.
        const styleFilters = filtersWithoutGenre.filter(
          (filter) =>
            filter.type === FilterType.styleType &&
            genre?.subGenres?.some((subGenre) => subGenre.id === filter.id)
        );
        const filtersWithoutRelatedStyles = filtersWithoutGenre.filter(
          (filter) => !styleFilters.find((style) => style.id === filter.id)
        );
        setAppliedFilters(filtersWithoutRelatedStyles);
        return filtersWithoutRelatedStyles;
      } else if (
        filter.type === FilterType.styleType ||
        filter.type === FilterType.moodsType ||
        filter.type === FilterType.kindsType ||
        filter.type === FilterType.rightsType ||
        filter.type === FilterType.vocalsType
      ) {
        const updatedFilters = prevFilters.filter(
          (fil) => filter.name !== fil.name
        );

        setAppliedFilters(updatedFilters);
        setSelectedMoods(selectedMoods?.filter((f) => f?.id !== filter.id));
        setSelectedStyles(selectedStyles?.filter((f) => f?.id !== filter.id));
        setSelectedKinds(selectedKinds?.filter((f) => f?.id !== filter.id));
        setSelectedRight(selectedRight?.filter((f) => f?.id !== filter.id));
        setSelectedVocals(selectedVocals?.filter((f) => f?.id !== filter.id));

        return updatedFilters;
      } else {
        return removeFromType(filter.type);
      }
    }
  };

  /**
   * Adds a filter to the set of applied filters given the business rules.
   *
   * This is the replacement function to {@link addFilter}.
   *
   * @param config is the configuration for the filter or the function callback.
   */
  const addFilters = useCallback(
    (params: AddFiltersParams) => {
      const { filters, removeExisting = true } = isFunction(params)
        ? params(appliedFilters)
        : params;
      // All new filters should be added with exception of keys.
      const keyFilters = filters?.filter(
        (filter) => filter.type === FilterType.keyType
      );
      const newFilters = removeExisting
        ? filters.filter(
            (filter) =>
              filter.type !== FilterType.keyType &&
              !appliedFilters.find(
                (appliedFilter) =>
                  appliedFilter.type !== filter.type &&
                  appliedFilter.id !== filter.id
              )
          )
        : uniqBy(
            filters.filter((filter) => filter.type !== FilterType.keyType),
            (filter) => `${filter.id}-${filter.type}`
          );

      const filterWithKeyFilters = !isEmpty(keyFilters)
        ? [...newFilters, keyFilters.at(0)!]
        : newFilters;

      setAppliedFilters(filterWithKeyFilters);
      return filterWithKeyFilters;
    },
    [appliedFilters]
  );

  /**
   * Adds a filter to the set of applied filters given the business rules.
   *
   * @param filter is the filter to add.
   * @deprecated use {@link addFilters} function instead.
   */
  const addFilter = (filter: Filter) => {
    if (!appliedFilters.find((f) => f.type === filter.type)) {
      // Add if non included yet.
      setAppliedFilters([...appliedFilters, filter]);
    } else if (filter.id !== "1" && filter.name !== "Any") {
      // Edit if different than default except for Styles and Moods.
      if (
        filter.type === FilterType.styleType ||
        filter.type === FilterType.moodsType ||
        filter.type === FilterType.kindsType ||
        filter.type === FilterType.rightsType
      ) {
        // Add
        !appliedFilters.some((fil) => fil.name === filter.name) &&
          setAppliedFilters([...appliedFilters, filter]);
      } else {
        // Edit
        const updatedFilters = appliedFilters.filter(
          (fil) => fil.type !== filter.type
        );
        setAppliedFilters([...updatedFilters, filter]);
      }
    } else {
      // Remove filter if selected default value.
      setAppliedFilters(
        appliedFilters.filter((fil) => fil.name !== filter.name)
      );
    }
  };

  const clearAllFilters = (
    params: {
      clearSearchQuery?: boolean;
    } = { clearSearchQuery: false }
  ) => {
    const { clearSearchQuery } = params;
    setBpmRange([BPM_INFINITY_LOW, BPM_INFINITY_HIGH]);
    setPriceRange([PRICE_INFINITY_LOW, PRICE_INFINITY_HIGH]);
    setDurationRange([DURATION_INFINITY_LOW, DURATION_INFINITY_HIGH]);
    setSelectedKeys([]);
    setSelectedMoods(initializeSelectedFilters ? [first(moods)!] : []);
    setSelectedRecency(recency[0]);
    subGenres &&
      setSelectedStyles(initializeSelectedFilters ? [first(subGenres)!] : []);
    setSelectedKinds(initializeSelectedFilters ? [first(kinds)!] : []);
    setSelectedGenre(first(genres)!);
    setSelectedLicense(
      selectedCategory.name !== Categories.songs
        ? licensingTypes[0]
        : licensingTypes[2]
    );
    setSelectedRight(
      selectedCategory.name !== Categories.songs ? [rights[0]] : [rights[2]]
    );
    setSelectedVocals([]);

    setAppliedFilters([]);

    if (clearSearchQuery) {
      setQuery("");
    }
  };

  const toggleSortOrder = () => {
    setSortOrder(!sortOrder);
  };

  /**
   * This function performs the search by calling the endpoint for the selected
   * category.
   *
   * Will prevent parallel searches to make sure we only perform one at a time
   * by checking the value of the blockLoadingRef.
   *
   * __Note: this is only true per each FilterProvider instance, so wrap your
   * components only when required.__
   * @returns a Promise that will resolve when call is done.
   */
  const handleSearch = async ({
    quick,
    category,
    limit = 60,
    offset = 0,
    searchQuery = "",
    force = false,
    filters = appliedFilters,
  }: {
    quick: boolean;
    category: FilterContextEntityType;
    limit: number;
    offset: number;
    searchQuery?: string;
    force?: boolean;
    filters?: Filter[] | null;
  }) => {
    if ((blockLoadingRef.current && !force) || !category) {
      return;
    }

    let catChanged =
      selectedCategory.name.toLowerCase() !== category?.toLowerCase();

    // Clear all search if category, or search query has changed.
    // This indicates that a search should be performed in a clean results
    // array.
    if (catChanged || lastSearchQuery !== searchQuery) {
      setResults([]);
    }

    setResultsLoading(true);
    setResultsCountLoading(true);
    blockLoadingRef.current = true;

    const newResults = await searchEntitiesByCategory({
      category,
      query: searchQuery,
      appliedFilters: filters ?? [],
      sorting: selectedSort,
      order: sortOrder,
      limit,
      offset,
    });

    setLastSearchQuery(searchQuery ?? "");

    if (!quick) {
      if (!catChanged && lastSearchQuery === searchQuery && offset > 0) {
        setResults((results) => [...results, ...(newResults ?? [])]);
      } else {
        setResults(newResults ?? []);
        const count =
          (await getCountEntitiesByCategory({
            category,
            query: searchQuery || "",
            appliedFilters: filters ?? [],
          })) ?? 0;
        setResultsCount(count);
      }
    } else {
      setRecommendedResults(newResults ?? []);
    }
    setResultsLoading(false);
    setResultsCountLoading(false);
    blockLoadingRef.current = false;
  };

  // Refs for providing stable deps
  const handleSearchRef = useRef(handleSearch);

  return {
    appliedFilters,
    isSharp,
    setIsSharp,
    selectedLicense,
    selectedRight,
    selectedRecency,
    selectedMoods,
    selectedStyles,
    selectedKeys,
    selectedKinds,
    selectedVocals,
    majorEnabled,
    minorEnabled,
    bpmRange,
    priceRange,
    setSelectedKinds,
    setAppliedFilters,
    setSelectedLicense,
    setSelectedRight,
    setSelectedRecency,
    setSelectedMoods,
    setSelectedStyles,
    setSelectedKeys,
    setSelectedVocals,
    setMajorEnabled,
    setMinorEnabled,
    setBpmRange,
    setPriceRange,
    removeFilter,
    removeFiltersByType: removeFromType,
    clearAllFilters,
    addFilter,
    addFilters,
    focusedSearchBar,
    focusSearchBar,
    blurSearchBar,
    mobileSearchVisible,
    toggleMobileSearch,
    query,
    setQuery,
    selectedCategory,
    setSelectedCategory,
    selectedSort,
    setSelectedSort,
    keysOptions: keys,
    hideSearchBar,
    setHideSearchBar,
    setMoods: noop,
    setSubGenres: noop,
    moods,
    subGenres,
    kinds,
    setKinds: noop,
    results,
    setResults,
    resultsCount,
    setResultsCount,
    resultsLoading,
    setResultsLoading,
    resultsCountLoading,
    setResultsCountLoading,
    handleSingleSelect,
    handleMultiSelect,
    addNewSearch,
    recentSearches,
    removeSearch,
    recommendedResults,
    setRecommendedResults,
    handleSearch: handleSearchRef.current,
    toggleSortOrder,
    sortOrder,
    setLastSearchQuery,
    lastSearchQuery,
    setSortAndUpdateRandom,
    isFilterPopupVisible: popupVisible,
    setPopupVisible,
    genres,
    selectedGenre,
    setSelectedGenre,
    selectedGenres,
    setSelectedGenres,
    selectedStems,
    setSelectedStems,
    durationRange,
    setDurationRange,
  };
};

const FilterContext = createContext<ReturnType<typeof useFilterController>>({
  appliedFilters: [],
  selectedLicense: {
    id: "0",
    type: FilterType.licenseType,
    name: "",
  },
  selectedRight: [
    {
      id: "0",
      type: FilterType.rightsType,
      name: "",
    },
  ],
  selectedRecency: {
    id: "0",
    type: FilterType.recencyType,
    name: "",
  },
  selectedMoods: [
    {
      id: "0",
      type: FilterType.moodsType,
      name: "",
    },
  ],

  selectedStyles: [
    {
      id: "0",
      type: FilterType.styleType,
      name: "",
    },
  ],
  selectedKeys: [
    {
      id: "0",
      type: FilterType.keyType,
      name: "",
    },
  ],
  selectedVocals: [],
  majorEnabled: true,
  minorEnabled: false,
  isSharp: true,
  bpmRange: [],
  priceRange: [],
  selectedKinds: [],
  setAppliedFilters: () => {},
  setSelectedLicense: () => {},
  setSelectedRight: () => {},
  setSelectedRecency: () => {},
  setSelectedKeys: () => {},
  setSelectedVocals: noop,
  setIsSharp: () => {},
  setMajorEnabled: () => {},
  setMinorEnabled: () => {},
  setBpmRange: () => {},
  setPriceRange: () => {},
  removeFilter: async () => [],
  removeFiltersByType: () => [],
  clearAllFilters: () => {},
  addFilter: (filter: Filter) => {},
  addFilters: () => [],
  focusedSearchBar: false,
  query: "",
  selectedCategory: {} as SearchCategory,
  focusSearchBar: () => {},
  blurSearchBar: () => {},
  mobileSearchVisible: false,
  toggleMobileSearch: () => {},
  setQuery: () => {},
  setSelectedCategory: () => {},
  selectedSort: "",
  setSelectedSort: () => {},
  keysOptions: [],
  hideSearchBar: false,
  setHideSearchBar: () => {},
  setMoods: () => {},
  setSubGenres: () => {},
  moods: [],
  subGenres: [],
  setSelectedMoods: () => {},
  setSelectedStyles: () => {},
  kinds: [],
  setKinds: () => {},
  setSelectedKinds: () => {},
  results: [],
  setResults: () => {},
  resultsCount: 0,
  resultsLoading: false,
  setResultsLoading: noop,
  setResultsCount: () => {},
  resultsCountLoading: false,
  setResultsCountLoading: noop,
  handleMultiSelect: () => {},
  addNewSearch: () => {},
  removeSearch: () => {},
  recentSearches: [],
  recommendedResults: [],
  setRecommendedResults: () => {},
  handleSearch: async () => {},
  toggleSortOrder: () => {},
  sortOrder: true,
  setLastSearchQuery: () => {},
  lastSearchQuery: "",
  setSortAndUpdateRandom: () => {},
  handleSingleSelect: () => {},
  setPopupVisible: () => {},
  isFilterPopupVisible: false,
  genres: [],
  selectedGenre: {} as Genre,
  setSelectedGenre: () => {},
  selectedGenres: [] as Genre[],
  setSelectedGenres: noop,
  selectedStems: [],
  setSelectedStems: noop,
  durationRange: [],
  setDurationRange: noop,
});

FilterContext.displayName = "FilterContext";

export type FilterProviderProps = {
  /**
   * This category atom will be used to maintain in sync with internal
   * selectedCategory.
   */
  categoryAtom: PrimitiveAtom<SearchCategory | null>;

  /**
   * Indicates if the filters should be initialized with default values (Any
   * options).
   */
  initializeSelectedFilters?: boolean;

  /**
   * Indicates if the options should include the Any option.
   */
  includeAny?: boolean;

  /**
   * This setting avoids performing any endpoint call against the search API.
   * Even calling the {@link handleSearch} function will result in a no-op.
   *
   * Useful for using with {@link useBeats}, {@link useSongs},
   * {@link useSoundkits} and {@link useCreators} searching hooks.
   */
  preventAutomaticSearch?: boolean;
} & PropsWithChildren;

export const FilterProvider = ({
  children,
  initializeSelectedFilters,
  includeAny,
  categoryAtom,
}: FilterProviderProps): JSX.Element => {
  return (
    <FilterContext.Provider
      value={useFilterController({
        categoryAtom,
        initializeSelectedFilters,
        includeAny,
      })}
    >
      {children}
    </FilterContext.Provider>
  );
};

export const useSearchFilters = () => useContext(FilterContext);
