import { SearchSorting } from "@/lib/types/search/search-sorting";
import { SortOptions } from "@/models/enums";
import { Option } from "@/models/sorting-filtering";

export const getSortingFromOptions = (sorting?: Option | null) => {
  if (!sorting) {
    return {
      sort_isFeatured: null,
      sort_isAlphabetical: null,
      sort_isNewest: null,
      sort_isCheapest: null,
      sort_random: null,
    } as SearchSorting;
  }

  return {
    sort_isFeatured: sorting.name === "Featured" ? true : null,
    sort_isAlphabetical:
      sorting?.name === SortOptions.az
        ? true
        : sorting?.name === SortOptions.za
        ? false
        : null,
    sort_isNewest:
      sorting?.name === SortOptions.new
        ? true
        : sorting?.name === SortOptions.old
        ? false
        : null,
    sort_isCheapest:
      sorting?.name === SortOptions.cheap
        ? true
        : sorting?.name === SortOptions.expensive
        ? false
        : null,
    sort_random: sorting?.name === SortOptions.random ? sorting?.value : null,
  } as SearchSorting;
};
