import { MAX_BPM, MAX_PRICE } from "@/components/filter";
import { SearchFilters } from "@/lib/types/search/search-filters";
import { FilterType } from "@/models/enums";
import { Filter, VocalsType, vocalsMap } from "@/models/models";

export type GetFilterFromOptionsParams = {
  query?: string | null;
  filters?: Filter[] | null;
  status?: number | null;
};

export const getFilterFromOptions = ({
  query,
  filters,
  status,
}: GetFilterFromOptionsParams) => {
  const genre = filters?.find(
    (filter) => filter.type === FilterType.genreType
  )?.id;
  const styles = filters
    ?.filter((filter) => filter.type === FilterType.styleType)
    .map((fil) => fil.id);
  const moods = filters
    ?.filter((filter) => filter.type === FilterType.moodsType)
    .map((fil) => fil.id);
  const keys = filters
    ?.filter((filter) => filter.type === FilterType.keyType)
    .map((fil) => fil.id);
  const date =
    parseInt(
      filters?.find((filter) => filter.type === FilterType.recencyType)?.id!
    ) - 1;
  const license =
    parseInt(
      filters?.find((filter) => filter.type === FilterType.licenseType)?.id!
    ) - 2;
  const rights = filters
    ?.filter((filter) => filter.type === FilterType.rightsType)
    .map((fil) => Number(fil.id) - 1);
  const artists = filters
    ?.filter((filter) => filter.type === FilterType.artistType)
    .map((fil) => fil.id);
  const kinds = filters
    ?.filter((filter) => filter.type === FilterType.kindsType)
    .map((filter) => filter.id);
  const vocalsFilter = filters?.find(
    (filter) => filter.type === FilterType.vocalsType
  );
  const vocals = vocalsFilter
    ? vocalsMap[vocalsFilter?.id as VocalsType]
    : undefined;

  let priceFrom: number = 0;
  let priceTo: number = MAX_PRICE;
  filters?.find((filter) => {
    if (filter.type === FilterType.priceType) {
      priceFrom = parseInt(filter.name.replaceAll("$", "").split("-").at(0)!);
      priceTo = parseInt(filter.name.replaceAll("$", "").split("-").at(1)!);
    }
  });

  let bpmFrom: number = 0;
  let bpmTo: number = MAX_BPM;
  filters?.find((filter) => {
    if (filter.type === FilterType.bpmType) {
      bpmFrom = parseInt(filter.name.split("-").at(0)!);
      bpmTo = parseInt(filter.name.split("-").at(1)!);
    }
  });

  let durationFrom: number | null = null;
  let durationTo: number | null = null;
  filters?.find((filter) => {
    if (filter.type === FilterType.durationType) {
      durationFrom = parseInt(filter.name.split("-").at(0)!);
      durationTo = parseInt(filter.name.split("-").at(1)!);
    }
  });

  return {
    name: query,
    genres: genre ? [genre] : null,
    sub_genres: styles?.length! > 0 ? styles : null,
    moods: moods?.length! > 0 ? moods : null,
    price_from: priceFrom === 0 && priceTo === MAX_PRICE ? null : priceFrom,
    price_to: priceTo === MAX_PRICE && priceFrom === 0 ? null : priceTo,
    bpm_from: bpmFrom === 0 && bpmTo === MAX_BPM ? null : bpmFrom,
    bpm_to: bpmTo === MAX_BPM && bpmFrom === 0 ? null : bpmTo,
    duration_from: durationFrom,
    duration_to: durationTo,
    date_added: date,
    license_type: license,
    license_rights: rights,
    artist_ids: artists?.length! > 0 ? artists : null,
    isFeatured: filters?.some((f) => f.type == FilterType.featuredType)
      ? true
      : null,
    status: status || 0,
    kinds,
    vocals,
  } as SearchFilters;
};
