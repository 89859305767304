import { formats } from "@/data/dummy";
import { Right } from "@/data/licenses";
import { isBeat } from "@/lib/helpers";
import { Beat } from "@/models/models";
import { RadioGroup } from "@headlessui/react";
import { isNil } from "lodash";
import React from "react";

export type PricingInfoFormatsRadioGroupProps = {
  value: string | undefined;
  onChange?: (value: string) => void;
  item: Beat;
  licenseRight: Right | undefined;
};

const getFormats = (licenseRights: string | undefined) => {
  if (licenseRights === "Non-Exclusive" || licenseRights === "Basic") {
    return formats;
  }
  if (licenseRights === "Pro") {
    return formats.slice(1, 3);
  }
  if (
    licenseRights === "Buy-Out" ||
    licenseRights === "Enterprise" ||
    licenseRights === "Exclusive"
  ) {
    return [formats.at(2) as string];
  }
  return formats;
};

const isFormatInLicense = ({
  format,
  licenseRight,
  item,
}: {
  format: string;
  licenseRight: Right | undefined;
  item: Beat;
}): boolean => {
  const license = item.licenseRights?.find(
    (right) => right.name === licenseRight
  );
  const file = license?.files?.find((file) => file.type === format);
  return !isNil(file);
};

const PricingInfoFormatsRadioGroup = ({
  value,
  onChange,
  item,
  licenseRight,
}: PricingInfoFormatsRadioGroupProps) => {
  const formats = getFormats(licenseRight);

  return (
    <RadioGroup
      value={value}
      onChange={onChange}
      className="flex flex-col md:flex-row md:items-center md:space-x-10"
    >
      <div className="mb-5 md:mb-0 lg:w-32">
        <RadioGroup.Label className="lg:text-small text-gray font-bold font-evolventa">
          FORMAT:
        </RadioGroup.Label>
      </div>
      <div
        className={`flex flex-col md:flex-row w-full justify-start gap-x-6 md:gap-x-10 ml-1 md:ml-0 md:justify-start`}
      >
        {value !== "Buy-Out" ? (
          formats.map((format, index) => {
            const isDisabled =
              isBeat(item) &&
              !isFormatInLicense({ format, licenseRight, item });

            return (
              <RadioGroup.Option
                id={`pricing-radio-format-${index}`}
                key={`format-${index}`}
                value={format}
                className="cursor-pointer"
                disabled={isDisabled}
              >
                {({ checked }) => (
                  <div className="flex items-center h-8">
                    <div
                      className={`flex justify-center items-center h-[17px] w-[17px] rounded-full border-[3px] ring-2 border-white mr-3 ${
                        checked ? "ring-black bg-black" : "ring-grayLight"
                      }`}
                    />
                    <p
                      className={`lg:text-small text-gray font-evolventa ${
                        isDisabled && "text-grayLight"
                      } `}
                    >
                      {format}{" "}
                    </p>
                  </div>
                )}
              </RadioGroup.Option>
            );
          })
        ) : (
          <p className="lg:text-small">STEMS + WAV </p>
        )}
      </div>
    </RadioGroup>
  );
};

export default PricingInfoFormatsRadioGroup;
