import { IconType } from "../icon-props";

const Check: IconType = (
  <>
    <g clipPath="url(#clip0_621_17964)">
      <path d="M9.99999 15.172L19.192 5.979L20.607 7.393L9.99999 18L3.63599 11.636L5.04999 10.222L9.99999 15.172Z" />
    </g>
    <defs>
      <clipPath id="clip0_621_17964">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </>
);

export default Check;