import { cx } from "@/lib/utils/className.utils";
import Navbar from "./navigation/navbar";

const Root = ({ children }: any) => {
  return (
    <div className={cx("overflow-x-hidden w-full")}>
      <Navbar />
      <main className="pt-[70px] mt-0 font-open-sans">{children}</main>
    </div>
  );
};

export default Root;
