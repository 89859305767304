import TransparentButton from "@/components/buttons/transparent-button";
import LutelyLogoMobileIcon from "@/components/home/icons/lutely-logo-mobile";
import { cx } from "@/lib/utils/className.utils";
import React, { useState } from "react";
import CartButton from "./cart-button";
import Icon from "@/components/icons/icon";
import Menu from "@/components/icons/lib/menu";
import { ClassProps } from "@/lib/types/class-props";
import MobileMenu from "./mobile-menu";
import MobileSearch from "./mobile-search";
import Link from "next/link";
import Close from "@/components/icons/lib/close";
import Search from "@/components/icons/lib/search";

export type MobileNavbarProps = ClassProps;

const MobileNavbar = ({ className }: MobileNavbarProps) => {
  const [menuOpened, setMenuOpened] = useState(false);
  const [searchOpened, setSearchOpened] = useState(false);

  const handleClose = () => {
    if (menuOpened) {
      setMenuOpened(false);
    }

    if (searchOpened) {
      setSearchOpened(false);
    }
  };

  const handleMenuOpen = () => {
    if (searchOpened) {
      setSearchOpened(false);
    }

    setMenuOpened(true);
  };

  const handleSearchOpen = () => {
    if (menuOpened) {
      setMenuOpened(false);
    }

    setSearchOpened(true);
  };

  return (
    <>
      <div
        className={cx(
          "flex lg:hidden flex-row items-center justify-between h-[50px] px-5 bg-darkGreen text-white",
          className
        )}
      >
        <div className="flex-1">
          <Link href="/">
            <TransparentButton className="!px-0">
              <LutelyLogoMobileIcon className="fill-white" />
            </TransparentButton>
          </Link>
        </div>

        <TransparentButton className="!px-0" onClick={handleSearchOpen}>
          <Icon icon={Search} className="fill-white" />
        </TransparentButton>

        <div className="flex flex-row items-center justify-end gap-2 flex-1">
          <CartButton color="dark" />

          {menuOpened || searchOpened ? (
            <TransparentButton className="!px-0" onClick={handleClose}>
              <Icon icon={Close} className="fill-white" />
            </TransparentButton>
          ) : (
            <TransparentButton className="!px-0" onClick={handleMenuOpen}>
              <Icon icon={Menu} className="stroke-white" />
            </TransparentButton>
          )}
        </div>
      </div>

      <MobileMenu open={menuOpened} onClose={() => setMenuOpened(false)} />

      <MobileSearch
        open={searchOpened}
        onClose={() => setSearchOpened(false)}
      />
    </>
  );
};

export default MobileNavbar;
