import { IconType } from "../icon-props";

const Sad: IconType = (
  <>
    <path d="M16 2C23.7188 2 30 8.28125 30 16C30 23.7188 23.7188 30 16 30C8.28125 30 2 23.7188 2 16C2 8.28125 8.28125 2 16 2ZM29 16C29 8.83125 23.1688 3 16 3C8.83125 3 3 8.83125 3 16C3 23.1688 8.83125 29 16 29C23.1688 29 29 23.1688 29 16Z" />
    <path d="M25 19.9999C25 21.0999 24.1 21.9999 23 21.9999C21.9 21.9999 21 21.0999 21 19.9999C21 18.6812 22.4187 16.4749 22.5812 16.2249C22.675 16.0874 22.825 15.9999 22.9937 15.9999C23.1625 15.9812 23.3188 16.0749 23.4125 16.2187C23.575 16.4499 25 18.5562 25 19.9999ZM23 20.9999C23.55 20.9999 24 20.5499 24 19.9999C24 19.2937 23.4563 18.2062 23.0063 17.4499C22.5563 18.2374 22 19.3624 22 19.9999C22 20.5499 22.45 20.9999 23 20.9999Z" />
    <path d="M21.5003 15C21.7753 15 22.0003 15.225 22.0003 15.5C22.0003 15.775 21.7753 16 21.5003 16C17.5565 16 16.1378 13.8625 16.0815 13.7688C15.9315 13.5375 16.0003 13.225 16.2315 13.0813C16.4628 12.9313 16.7753 13 16.919 13.2313C16.969 13.3 18.1315 15 21.5003 15Z" />
    <path d="M19.9314 23.25C20.0689 23.4875 19.9876 23.7937 19.7501 23.9312C19.6689 23.9812 19.5876 24 19.5001 24C19.3251 24 19.1626 23.9125 19.0689 23.75C19.0314 23.6813 17.9814 22 15.0001 22C12.0189 22 10.9751 23.6813 10.9314 23.7563C10.7876 23.9938 10.4814 24.0687 10.2501 23.9312C10.0126 23.7937 9.93136 23.4875 10.0689 23.25C10.1251 23.1562 11.4189 21 15.0001 21C18.5814 21 19.8751 23.1562 19.9314 23.25Z" />
    <path d="M11.7746 13.0813C12.0059 13.2313 12.0746 13.5376 11.9246 13.7688C11.8684 13.8563 10.4496 16.0001 6.50586 16.0001C6.23086 16.0001 6.00586 15.7751 6.00586 15.5001C6.00586 15.2251 6.23086 15.0001 6.50586 15.0001C9.87461 15.0001 11.0371 13.3001 11.0871 13.2251C11.2309 13.0001 11.5434 12.9313 11.7746 13.0813Z" />
  </>
);

export default Sad;
