import Icon from "@/components/icons/icon";
import React, { useEffect, useState } from "react";
import Alert from "@/components/icons/lib/alert";
import UserbackWidgetLoader, { UserbackWidget } from "@userback/widget";

const UserbackButton = () => {
  const [showUserback, setShowUserback] = useState<boolean>(false);
  const [userback, setUserback] = useState<UserbackWidget | undefined>(
    undefined
  );

  useEffect(() => {
    const fetchUserback = async () => {
      if (showUserback) {
        if (userback) {
          userback.open();
          setShowUserback(false);
        } else {
          const userbackWidget = await UserbackWidgetLoader(
            "41547|84297|42WpydQNBLbNVDjyz2Tbg7APj"
          );
          setUserback(userbackWidget);
          userbackWidget.open();
          setShowUserback(false);
        }
      }
    };
    fetchUserback();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showUserback]);

  return (
    <div className="fixed top-1/2 right-0 transform -translate-y-1/2 right-[-2.2rem] z-[10000]">
      <div className="relative">
        <div className="z-10 -rotate-90 rounded-t-lg transition-all duration-200 ease-in-out hover:shadow-xl hover:shadow-gray">
          <button
            key="overlay-button"
            className="relative z-10 px-5 py-3 bg-purple text-white font-semibold rounded-t-lg transition-[padding] duration-200 ease-in-out hover:pb-5"
            onClick={() => setShowUserback(true)}
          >
            Support
          </button>
        </div>
      </div>
    </div>
  );
};

export default UserbackButton;
