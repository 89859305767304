import { IconType } from "../icon-props";

const Funny: IconType = (
  <>
    <g clipPath="url(#clip0_2382_38430)">
      <path d="M30.6064 9.44995C33.3002 15.4812 31.9877 22.6375 27.3127 27.3125C24.2564 30.375 20.1314 31.9937 15.9627 31.9937C13.7564 31.9937 11.5377 31.5437 9.4502 30.6124C10.1877 30.5999 10.8752 30.3562 11.4252 29.9437C16.6189 31.6312 22.4439 30.2937 26.3689 26.375C30.2877 22.45 31.6314 16.6312 29.9377 11.4312C30.3502 10.875 30.5939 10.1875 30.6064 9.44995Z" />
      <path d="M30.6068 9.3999C30.6068 9.41865 30.6068 9.43115 30.6068 9.4499C30.5818 9.39365 30.5568 9.3374 30.5318 9.28115C30.4943 9.19365 30.4256 9.1124 30.3568 9.0499C30.0818 8.8124 29.6631 8.8374 29.4256 9.11865C29.3506 9.1999 29.3068 9.2999 29.2818 9.3999C29.2818 8.5874 28.8131 7.8749 28.1318 7.53115C28.3318 7.5999 28.5568 7.5749 28.7443 7.44365C29.0443 7.23115 29.1068 6.8124 28.8943 6.5124C28.8568 6.4624 28.8256 6.41865 28.7881 6.36865C29.8631 6.94365 30.6068 8.0874 30.6068 9.3999Z" />
      <path d="M30.5314 9.28118C30.5564 9.33743 30.5814 9.39368 30.6064 9.44993C30.5939 10.1874 30.3502 10.8749 29.9439 11.4312C29.7689 10.8999 29.5689 10.3812 29.3314 9.86243C29.2939 9.79368 29.2689 9.71243 29.2627 9.63743C29.2689 9.56243 29.2752 9.48118 29.2752 9.39993C29.3002 9.29993 29.3439 9.19993 29.4189 9.11868C29.6627 8.83743 30.0752 8.81243 30.3502 9.04993C30.4252 9.11243 30.4939 9.19368 30.5314 9.28118Z" />
      <path d="M29.9441 11.4312C29.3879 12.1812 28.5379 12.6999 27.5629 12.8124C27.4754 12.4874 27.3691 12.1624 27.2504 11.8437C27.1879 11.6687 27.0566 11.5374 26.9004 11.4687C27.0066 11.4937 27.1004 11.5062 27.1816 11.5062C28.2566 11.5062 29.1441 10.6937 29.2629 9.6499C29.2691 9.73115 29.2941 9.80615 29.3316 9.8749C29.5691 10.3749 29.7691 10.8999 29.9441 11.4312Z" />
      <path
        d="M29.2758 9.3999C29.2758 9.48115 29.2696 9.5624 29.2633 9.6374C29.2508 9.55615 29.2571 9.4749 29.2758 9.3999Z"
        fill="black"
      />
      <path d="M28.8816 6.51235C29.1004 6.81235 29.0316 7.2311 28.7316 7.4436C28.5441 7.57485 28.3191 7.59985 28.1191 7.5311C28.0816 7.51235 28.0379 7.4936 28.0004 7.47485C27.9254 7.4311 27.8566 7.3686 27.8004 7.2936C27.4941 6.8686 27.1379 6.43735 26.7441 6.0186C26.9004 5.98735 27.0441 5.97485 27.1754 5.97485C27.7504 5.97485 28.2941 6.1186 28.7691 6.3686C28.8129 6.41235 28.8504 6.46235 28.8816 6.51235Z" />
      <path d="M27.3129 4.6874C27.8629 5.2374 28.3504 5.7999 28.7754 6.36865C28.3004 6.11865 27.7566 5.9749 27.1816 5.9749C27.0504 5.9749 26.9066 5.9874 26.7504 6.01865C26.6316 5.8874 26.5066 5.7624 26.3754 5.63115C20.6566 -0.0875974 11.3504 -0.0875974 5.63164 5.63115C-0.0871096 11.3499 -0.0871096 20.6562 5.63164 26.3749C5.76289 26.5062 5.88789 26.6312 6.01914 26.7499C5.98789 26.9062 5.97539 27.0499 5.97539 27.1874C5.97539 27.7624 6.11914 28.3062 6.36914 28.7812C5.80039 28.3624 5.23789 27.8687 4.68789 27.3187C-1.54961 21.0812 -1.54961 10.9312 4.68789 4.69365C10.9254 -1.55635 21.0754 -1.55635 27.3129 4.6874Z" />
      <path
        d="M28 7.47485C28.0437 7.4936 28.0812 7.51235 28.1187 7.5311C28.0812 7.51235 28.0375 7.4936 28 7.47485Z"
        fill="black"
      />
      <path d="M27.8004 7.2937C27.8566 7.3687 27.9254 7.4312 28.0004 7.47495C27.7504 7.3687 27.4691 7.3062 27.1816 7.3062C26.4191 7.3062 24.4191 8.39995 22.8629 9.39995C23.6691 9.9187 24.5941 10.4625 25.4004 10.8625C25.1754 10.7937 24.9191 10.85 24.7379 11.0312L24.0879 11.6875C22.8254 11 21.6504 10.2062 21.2816 9.94995C21.1004 9.82495 20.9941 9.6187 20.9941 9.39995C20.9941 9.1812 21.1004 8.97495 21.2816 8.84995C21.9191 8.4062 24.9816 6.3437 26.7504 6.01245C27.1379 6.43745 27.4941 6.86245 27.8004 7.2937Z" />
      <path d="M27.5633 12.8062C28.7008 16.9375 27.5445 21.425 24.4883 24.4812C22.2008 26.7687 19.1195 27.9875 15.9883 27.9875C14.9258 27.9875 13.857 27.85 12.807 27.5562C12.8195 27.4312 12.8258 27.3062 12.8258 27.175C12.8258 26.875 12.7445 26.5062 12.6133 26.1062C16.432 27.3812 20.6883 26.3937 23.5445 23.5375C26.3945 20.6875 27.3883 16.4312 26.1133 12.6062C26.5133 12.7437 26.882 12.8187 27.182 12.8187C27.3133 12.825 27.4383 12.8187 27.5633 12.8062Z" />
      <path d="M27.2503 11.8373C27.369 12.1561 27.4753 12.4811 27.5628 12.8061C27.4378 12.8186 27.3065 12.8248 27.1815 12.8248C26.8815 12.8248 26.5128 12.7436 26.1128 12.6123C26.0753 12.5061 26.044 12.3998 26.0003 12.2998C25.8753 11.9561 26.0503 11.5686 26.394 11.4436C26.4878 11.4061 26.5878 11.3936 26.6815 11.4061C26.7565 11.4311 26.8315 11.4498 26.9003 11.4623C27.0565 11.5311 27.1878 11.6623 27.2503 11.8373Z" />
      <path
        d="M26.6816 11.3999C26.7566 11.4062 26.8316 11.4249 26.9004 11.4562C26.8316 11.4437 26.7566 11.4249 26.6816 11.3999Z"
        fill="black"
      />
      <path d="M26.3937 11.4437C26.05 11.5749 25.8687 11.9562 26 12.2999C26.0375 12.4062 26.075 12.5124 26.1125 12.6124C25.8687 12.5312 25.6062 12.4312 25.3438 12.3124L25.6812 11.9749C25.9437 11.7124 25.9437 11.2937 25.6812 11.0312C25.6562 11.0062 25.625 10.9812 25.6 10.9624C26.0063 11.1562 26.3812 11.3124 26.6812 11.4062C26.5875 11.3937 26.4875 11.4062 26.3937 11.4437Z" />
      <path d="M25.6816 11.0311C25.9441 11.2936 25.9441 11.7123 25.6816 11.9748L25.3441 12.3123C24.9316 12.1311 24.5004 11.9123 24.0879 11.6873L24.7441 11.0311C24.9254 10.8498 25.1816 10.7936 25.4066 10.8623C25.4754 10.8936 25.5379 10.9311 25.6066 10.9623C25.6316 10.9811 25.6566 11.0061 25.6816 11.0311Z" />
      <path
        d="M25.6004 10.9623C25.5379 10.9311 25.4691 10.8998 25.4004 10.8623C25.4691 10.8811 25.5379 10.9186 25.6004 10.9623Z"
        fill="black"
      />
      <path d="M25.3438 12.3125L12.3125 25.3438C12.1312 24.9312 11.9125 24.5 11.6875 24.0875L24.0875 11.6875C24.5 11.9125 24.9312 12.1312 25.3438 12.3125Z" />
      <path d="M20.4256 9.26872C20.5006 9.63122 20.2631 9.98122 19.9006 10.05L15.1881 10.9937C15.1444 11.0062 15.1006 11.0062 15.0569 11.0062C14.8819 11.0062 14.7131 10.9375 14.5819 10.8125C14.4256 10.6562 14.3569 10.4312 14.4006 10.2125L15.3444 5.49997C15.4194 5.13747 15.7819 4.90622 16.1256 4.97497C16.4881 5.04997 16.7194 5.39997 16.6506 5.76247L15.9069 9.49372L19.6381 8.74997C19.9819 8.67497 20.3506 8.90622 20.4256 9.26872Z" />
      <path d="M12.8258 27.1811C12.8258 27.3123 12.8195 27.4373 12.807 27.5623C12.482 27.4748 12.157 27.3686 11.8383 27.2498C11.657 27.1811 11.5258 27.0498 11.457 26.8873C11.4445 26.8248 11.4258 26.7623 11.407 26.6936C11.3945 26.5936 11.407 26.4936 11.4445 26.3936C11.5695 26.0498 11.957 25.8748 12.3008 25.9998C12.407 26.0373 12.507 26.0748 12.6133 26.1123C12.7508 26.5123 12.8258 26.8748 12.8258 27.1811Z" />
      <path d="M12.3129 25.3436L11.9754 25.6811C11.8442 25.8123 11.6754 25.8748 11.5067 25.8748C11.3379 25.8748 11.1629 25.8061 11.0379 25.6811C11.0129 25.6561 10.9879 25.6311 10.9692 25.5998C10.9379 25.5311 10.9067 25.4686 10.8692 25.3998C10.8004 25.1748 10.8567 24.9186 11.0379 24.7373L11.6942 24.0811C11.9129 24.4998 12.1317 24.9311 12.3129 25.3436Z" />
      <path d="M11.8375 27.2499C12.1562 27.3686 12.4812 27.4686 12.8062 27.5624C12.7 28.5374 12.1812 29.3874 11.4312 29.9436C10.9125 29.7749 10.4 29.5749 9.89375 29.3436C9.81875 29.2999 9.7375 29.2686 9.65625 29.2561C10.6875 29.1311 11.4937 28.2436 11.4937 27.1749C11.4937 27.0936 11.4813 26.9936 11.4563 26.8811C11.525 27.0499 11.6625 27.1874 11.8375 27.2499Z" />
      <path d="M11.6877 24.0874L11.0314 24.7437C10.8502 24.9249 10.7939 25.1749 10.8627 25.4062C10.4627 24.5999 9.91895 23.6749 9.4002 22.8687C8.4002 24.4312 7.30645 26.4249 7.30645 27.1874C7.30645 27.4687 7.3627 27.7437 7.4627 27.9874C7.41895 27.9187 7.3627 27.8562 7.2877 27.8062C6.8627 27.4999 6.43145 27.1437 6.0127 26.7499C6.34395 24.9812 8.40645 21.9249 8.84395 21.2812C9.09395 20.9187 9.69395 20.9187 9.9377 21.2812C10.2002 21.6499 10.9939 22.8187 11.6877 24.0874Z" />
      <path
        d="M11.4062 26.6936C11.425 26.7624 11.4438 26.8249 11.4563 26.8874C11.4313 26.8249 11.4125 26.7624 11.4062 26.6936Z"
        fill="black"
      />
      <path d="M11.4441 26.3937C11.4066 26.4937 11.3941 26.6 11.4066 26.6938C11.3129 26.3875 11.1629 26.0125 10.9629 25.6C10.9816 25.625 11.0066 25.6562 11.0316 25.6812C11.1629 25.8125 11.3316 25.875 11.5004 25.875C11.6691 25.875 11.8441 25.8062 11.9691 25.6812L12.3066 25.3438C12.4254 25.6062 12.5254 25.8625 12.6066 26.1063C12.5004 26.0688 12.4004 26.0375 12.2941 25.9937C11.9566 25.875 11.5691 26.05 11.4441 26.3937Z" />
      <path d="M10.8127 14.5875C10.9689 14.7438 11.0377 14.9688 10.9939 15.1875L10.0502 19.9C9.98767 20.2188 9.70642 20.4375 9.40017 20.4375C9.35642 20.4375 9.31267 20.4313 9.26892 20.425C8.90642 20.35 8.67517 20 8.74392 19.6438L9.49392 15.9125L5.75642 16.6563C5.41267 16.7313 5.04392 16.4938 4.96892 16.1313C4.89392 15.7688 5.13142 15.4188 5.49392 15.35L10.2064 14.4063C10.4314 14.3625 10.6564 14.4313 10.8127 14.5875Z" />
      <path
        d="M10.8633 25.3999C10.9008 25.4687 10.932 25.5374 10.9633 25.5999C10.9195 25.5374 10.882 25.4687 10.8633 25.3999Z"
        fill="black"
      />
      <path d="M9.89422 29.3436C10.4005 29.5748 10.913 29.7748 11.4317 29.9436C10.8755 30.3498 10.1942 30.5998 9.45672 30.6123C9.40047 30.5873 9.34422 30.5623 9.28797 30.5373C9.23172 30.5123 9.17547 30.4748 9.12547 30.4311C8.85047 30.1873 8.81297 29.7623 9.05672 29.4873C9.15047 29.3811 9.26297 29.3123 9.38172 29.2811C9.38797 29.2811 9.39422 29.2811 9.40047 29.2811C9.48797 29.2811 9.57547 29.2748 9.65672 29.2686C9.73797 29.2686 9.81922 29.2998 9.89422 29.3436Z" />
      <path
        d="M9.38184 29.2749C9.46934 29.2499 9.56309 29.2436 9.65684 29.2624C9.57559 29.2749 9.48809 29.2749 9.40059 29.2749C9.39434 29.2749 9.38809 29.2749 9.38184 29.2749Z"
        fill="black"
      />
      <path d="M9.40039 30.6062C8.08789 30.6062 6.94414 29.8625 6.36914 28.775C6.41914 28.8125 6.46289 28.8437 6.51289 28.8812C6.63164 28.9687 6.76914 29.0062 6.90039 29.0062C7.10664 29.0062 7.30664 28.9125 7.44414 28.7312C7.57539 28.55 7.60039 28.3312 7.53789 28.1375C7.88164 28.8062 8.57539 29.2687 9.38164 29.275C9.25664 29.3062 9.14414 29.375 9.05664 29.4812C8.81289 29.7562 8.85039 30.1812 9.12539 30.4249C9.17539 30.4687 9.22539 30.5 9.28789 30.5312C9.34414 30.5562 9.40039 30.5812 9.45664 30.6062C9.43789 30.6062 9.41914 30.6062 9.40039 30.6062Z" />
      <path d="M7.53809 28.1374C7.60059 28.3312 7.56934 28.5499 7.44434 28.7312C7.31309 28.9124 7.10684 29.0062 6.90059 29.0062C6.76309 29.0062 6.62559 28.9624 6.51309 28.8812C6.46309 28.8437 6.41934 28.8124 6.36934 28.7749C6.11934 28.2999 5.97559 27.7562 5.97559 27.1812C5.97559 27.0499 5.98809 26.8999 6.01934 26.7437C6.43809 27.1374 6.86934 27.4937 7.29434 27.7999C7.36309 27.8499 7.41934 27.9124 7.46934 27.9812C7.48809 28.0374 7.51309 28.0874 7.53809 28.1374Z" />
      <path
        d="M7.46875 27.9812C7.5 28.0312 7.525 28.0812 7.54375 28.1375C7.5125 28.0875 7.4875 28.0375 7.46875 27.9812Z"
        fill="black"
      />
    </g>
    <defs>
      <clipPath id="clip0_2382_38430">
        <rect width="32" height="32" fill="white" />
      </clipPath>
    </defs>
  </>
);

export default Funny;
