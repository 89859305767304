import { IconType } from "../icon-props";

const Pause: IconType = (
  <>
    <g clipPath="url(#clip0_621_17908)">
      <path d="M6 5H8V19H6V5ZM16 5H18V19H16V5Z" />
    </g>
    <defs>
      <clipPath id="clip0_621_17908">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </>
);

export default Pause;
