import React, { PropsWithChildren } from "react";
import AudioPlayer from "../audio/audio-player";
import {
  AudioPlayerProvider,
  useAudioPlayer,
} from "@/src/contexts/AudioPlayerContext";

export type AudioPlayerLayoutProps = {} & PropsWithChildren;

const AudioPlayerLayout = ({ children }: AudioPlayerLayoutProps) => {
  const { playerVisible } = useAudioPlayer();

  return (
    <>
      {children}
      {playerVisible && <AudioPlayer />}
    </>
  );
};

export type AudioPlayerLayoutWithContextProps = PropsWithChildren;

const AudioPlayerLayoutWithContext = ({
  children,
}: AudioPlayerLayoutWithContextProps) => {
  return (
    <AudioPlayerProvider>
      <AudioPlayerLayout>{children}</AudioPlayerLayout>
    </AudioPlayerProvider>
  );
};

export default AudioPlayerLayoutWithContext;
