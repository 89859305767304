import { IconType } from "../icon-props";

const SoundkitsIcon: IconType = (
  <>
    <g clipPath="url(#clip0_621_17936)">
      <path d="M12.414 5H21C21.2652 5 21.5196 5.10536 21.7071 5.29289C21.8946 5.48043 22 5.73478 22 6V20C22 20.2652 21.8946 20.5196 21.7071 20.7071C21.5196 20.8946 21.2652 21 21 21H3C2.73478 21 2.48043 20.8946 2.29289 20.7071C2.10536 20.5196 2 20.2652 2 20V4C2 3.73478 2.10536 3.48043 2.29289 3.29289C2.48043 3.10536 2.73478 3 3 3H10.414L12.414 5ZM4 5V19H20V7H11.586L9.586 5H4ZM11 13.05V9H16V11H13V15.5C12.9999 16.0159 12.8402 16.5191 12.5428 16.9407C12.2454 17.3622 11.8249 17.6814 11.3389 17.8545C10.8529 18.0276 10.3253 18.0462 9.82839 17.9076C9.33146 17.769 8.88958 17.4801 8.56335 17.0804C8.23711 16.6808 8.0425 16.19 8.0062 15.6754C7.9699 15.1608 8.09368 14.6476 8.36059 14.2061C8.62749 13.7646 9.02443 13.4165 9.49698 13.2095C9.96953 13.0025 10.4945 12.9468 11 13.05Z" />
    </g>
    <defs>
      <clipPath id="clip0_621_17936">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </>
);

export default SoundkitsIcon;
