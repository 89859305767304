import { cx } from "@/lib/utils/className.utils";
import { IconProps } from "./icon-props";

export const Icon = ({
  id,
  icon,
  size = 18,
  color = "black",
  className,
  onClick,
  viewBox = "0 0 24 24",
  style,
}: IconProps) => {
  return (
    <svg
      id={id}
      xmlns="http://www.w3.org/2000/svg"
      viewBox={viewBox}
      width={size}
      height={size}
      fill={color}
      className={cx(`w-[${size}] h-[${size}]`, className)}
      onClick={(e) => onClick && onClick(e)}
      style={style}
    >
      {icon}
    </svg>
  );
};

export default Icon;
