import { Dialog, Transition } from "@headlessui/react";
import { Slider } from "@mui/material";
import { useRouter } from "next/router";
import { Fragment, useEffect, useRef, useState } from "react";
import {
  getViewObjectUrl,
  isBeat,
  isSong,
  isSoundkit,
  slugify,
} from "../../lib/helpers";
import { Size } from "../../models/enums";
import { useAudioPlayer } from "../../src/contexts/AudioPlayerContext";
import KitArt from "../arts/kit-art";
import TrackArt from "../arts/track-art";
import Icon from "../icons/icon";
import { useWindowSize } from "../../src/hooks";
import WaveProgressSlider from "./wave-slider";
import PricingPopup from "../detail-page/pricing-popup";
import { Beat, SoundKit, ViewObject } from "../../models/models";
import Link from "next/link";
import { cx } from "@/lib/utils/className.utils";
import AddCart from "../icons/lib/add-shopping-cart";
import Close from "../icons/lib/close";
import Next from "../icons/lib/next";
import Play from "../icons/lib/play";
import Previous from "../icons/lib/previous";
import VolumeDown from "../icons/lib/volume-down";
import VolumeUp from "../icons/lib/volume-up";
import Pause from "../icons/lib/pause";
import VolumeMute from "../icons/lib/volume-mute";

interface AudioPlayerProps {
  className?: string;
}

const AudioPlayer = ({ className }: AudioPlayerProps) => {
  // hooks
  const size = useWindowSize();
  const {
    closePlayer,
    selectedAudio,
    playing,
    setPlaying,
    currentTime,
    setCurrentTime,
    duration,
    setDuration,
    volumeLevel,
    setVolumeLevel,
    playNext,
    playPrevious,
  } = useAudioPlayer();
  const router = useRouter();

  // references
  const audioPlayer = useRef<HTMLAudioElement>(null);

  // states
  // const [duration, setDuration] = useState<number>(0);
  const [volumeVisible, setVolumeVisible] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [pricePopupVisible, setPricePopupVisible] = useState(false);

  // effects
  useEffect(() => {
    const seconds = Math.floor(audioPlayer.current?.duration || 0);
    setDuration(seconds);
    animate();
    // TODO: need to improve this effect to simplify the deps or make the deps stable
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [audioPlayer.current?.duration]);

  useEffect(() => {
    if (audioPlayer.current) {
      audioPlayer.current.currentTime = currentTime;
      if (!playing) {
        audioPlayer.current?.pause();
      }
    }
    // TODO: need to improve this effect to simplify the deps or make the deps stable
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const animate = () => {
    const timerId = setInterval(() => {
      whilePlaying();
      if (!playing) {
        clearInterval(timerId);
      }
    }, 1);
  };

  useEffect(() => {
    if (audioPlayer.current) {
      audioPlayer.current.volume = volumeLevel / 100;
    }
  }, [volumeLevel]);

  // actions
  const togglePlayPause = () => {
    if (playing) {
      audioPlayer.current?.pause();
    } else {
      audioPlayer.current?.play();
      animate();
    }
    setPlaying(!playing);
  };

  useEffect(() => {
    if (playing) {
      audioPlayer.current?.play();
      return;
    }
    audioPlayer.current?.pause();
  }, [playing]);

  const whilePlaying = () => {
    if (audioPlayer.current) {
      setCurrentTime(Math.floor(audioPlayer.current.currentTime));
    }
    if (audioPlayer.current?.ended) {
      // audioPlayer.current.pause();
      togglePlayPause();
    }
  };

  const handleChange = (value: number, active: number) => {
    if (audioPlayer.current && !isNaN(value)) {
      audioPlayer.current.currentTime = value;
      setCurrentTime(value);
    }
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const openModal = () => {
    setIsOpen(true);
  };

  const handleAddFavourite = () => {};

  const openPrice = () => {
    setPricePopupVisible(true);
  };

  const closePrice = () => {
    setPricePopupVisible(false);
  };

  return (
    <div
      className={cx(
        "w-full fixed left-0 bottom-0 z-[100] lg:px-[72px]",
        className
      )}
      onClick={() => {
        size.width! < 1024 && openModal();
      }}
    >
      <div className="flex w-full lg:px-10 items-center justify-between h-[100px] lg:h-[80px] bg-black transition-all lg:rounded-t-[40px] relative">
        <audio
          ref={audioPlayer}
          src={selectedAudio?.sample}
          autoPlay
          onError={playNext}
        />

        <PricingPopup
          item={selectedAudio!}
          isProductDetail
          isOpen={pricePopupVisible}
          closeModal={closePrice}
          closeMessage="Go back"
        />

        {/* Controls */}
        <div className="flex items-center">
          <div
            id="test-id"
            className="absolute right-0 lg:relative flex items-center space-x-5 mr-5 lg:mr-0"
          >
            <button
              id="audioplayer-button-prev"
              onClick={playPrevious}
              className="hidden lg:flex lg:mb-2 mr-2"
            >
              <Icon icon={Previous} color="white" />
            </button>

            <button
              id="audioplayer-button-playPause"
              onClick={(e: any) => {
                e.stopPropagation();
                togglePlayPause();
              }}
              className="flex justify-center items-center rounded-md bg-green h-[35px] w-[35px] rotate-45 mr-3 lg:mr-0"
            >
              <Icon
                icon={playing ? Pause : Play}
                className="-rotate-45"
                size={25}
              />
            </button>

            <button
              id="audioplayer-button-next"
              onClick={playNext}
              className="hidden lg:flex lg:mb-2"
            >
              <Icon icon={Next} color="white" />
            </button>
          </div>

          {/* Beat information */}
          <div
            id="audioplayer-div-audioInfo"
            className="absolute lg:relative flex space-x-5 items-center ml-5 lg:ml-10 cursor-pointer lg:cursor-default"
          >
            {(isBeat(selectedAudio!) || isSong(selectedAudio!)) && (
              <TrackArt
                id="audioplayer-image-trackCover"
                onClick={() =>
                  size.width! >= 1024
                    ? router.push(getViewObjectUrl(selectedAudio))
                    : null
                }
                item={selectedAudio! as Beat}
                className="cursor-pointer h-[70px] bg-gray-500 w-[70px] lg:w-[60px] lg:h-[60px] rounded-lg"
              />
            )}
            {isSoundkit(selectedAudio!) && (
              <KitArt
                id="audioplayer-image-soundkitCover"
                onClick={() =>
                  size.width! >= 1024
                    ? router.push(getViewObjectUrl(selectedAudio))
                    : null
                }
                size={Size.xs}
                item={selectedAudio! as SoundKit}
                className="cursor-pointer bg-gray-500 rounded-lg"
              />
            )}
            <div className="truncate overflow-hidden w-[200px] text-white cursor-pointer">
              <h6
                id="audioplayer-text-trackName"
                onClick={(e: any) => {
                  e.stopPropagation();
                  router.push(getViewObjectUrl(selectedAudio));
                }}
                className="hover:underline cursor-pointer"
              >
                {selectedAudio?.name}
              </h6>
              <p
                id="audioplyer-text-creatorName"
                onClick={(e: any) => {
                  e.stopPropagation();
                  router.push(
                    `/creators/${slugify(selectedAudio?.artist?.name)}/${
                      selectedAudio?.artist?.id
                    }`
                  );
                }}
                className="text-small text-grayLight hover:underline cursor-pointer"
              >
                {selectedAudio?.artist?.name}
              </p>
            </div>
          </div>
        </div>

        <Icon
          id="audioplayer-icon-close"
          icon={Close}
          color="white"
          className="flex lg:hidden absolute top-3 right-2 z-10"
          onClick={closePlayer}
        />

        {/* Waveform Progress Bar */}
        <WaveProgressSlider
          id="audioplayer-input-trackProggress"
          item={selectedAudio as ViewObject}
          duration={duration}
          onSlide={handleChange}
          thumb
          thumbLine
          className="hidden lg:flex"
        />

        {/* Action buttons */}
        <div className="hidden lg:flex relative items-center">
          {/* Volume slider */}
          {volumeVisible && (
            <div className="flex absolute bottom-0 mb-6 -ml-2 h-[150px] w-[40px] rounded-full justify-center pt-5 pb-4 bg-black">
              <Slider
                value={volumeLevel}
                onChange={(event, value) => {
                  audioPlayer.current
                    ? (audioPlayer.current.volume = (value as number) / 100)
                    : null;
                  setVolumeLevel(value as number);
                }}
                orientation="vertical"
                sx={{
                  color: "#1DF364",
                  "& .MuiSlider-rail": {
                    color: "white",
                  },
                  "& .MuiSlider-thumb": {
                    height: 10,
                    width: 10,
                    transform: "rotate(-45deg) translate(-70%)",
                    borderRadius: 0,
                    backgroundColor: "#1DF364",
                    "&:before": {
                      display: "none",
                    },
                  },
                }}
              />
            </div>
          )}
          <button
            id="audioplayer-button-volume"
            onClick={() => setVolumeVisible(!volumeVisible)}
            className="mr-5"
          >
            <Icon
              icon={
                volumeLevel === 0
                  ? VolumeMute
                  : volumeLevel > 50
                  ? VolumeUp
                  : VolumeDown
              }
              color="white"
            />
          </button>
          {/*<button className="mr-5">
          <Icon icon={Heart} color="white" />
        </button>
        */}
          {!router.pathname.includes("audiolab") && (
            <button
              id="audioplayer-button-cart"
              onClick={openPrice}
              className="mr-5"
            >
              <Icon icon={AddCart} color="white" />
            </button>
          )}

          <button id="audioplayer-button-close" onClick={closePlayer}>
            <Icon icon={Close} color="white" />
          </button>
        </div>

        {/* Track progress (mobile) */}
        <div
          className="flex lg:hidden absolute top-0 items-center space-x-3 w-full h-1 lg:ml-5 lg:mr-10 z-0"
          onClick={(e: any) => {
            e.stopPropagation();
            closePlayer();
          }}
        >
          {/* Track progress (mobile) */}
          <div
            onClick={(e: any) => {
              e.stopPropagation();
            }}
            className="flex lg:hidden absolute top-0 items-center space-x-3 w-full h-1 lg:ml-5 lg:mr-10"
          >
            <Slider
              value={currentTime}
              min={0}
              max={duration}
              onChange={(event, value, active) => {
                event.stopPropagation();
                handleChange(value as number, active);
              }}
              disableSwap
              // className="w-full"
              sx={{
                color: "#1DF364",
                "& .MuiSlider-rail": {
                  color: "white",
                },
                "& .MuiSlider-thumb": {
                  height: 10,
                  width: 10,
                  transform: "rotate(-45deg) translate(0, -70%)",
                  borderRadius: 0,
                  backgroundColor: "#1DF364",
                  "&:before": {
                    display: "none",
                  },
                },
              }}
            />
          </div>

          {/* Audio Player screen (mobile) */}
          <Transition appear show={isOpen} as={Fragment}>
            <Dialog
              as="div"
              className="lg:hidden relative z-[20000000]"
              onClose={closeModal}
            >
              <div className="fixed inset-0">
                <div className="flex w-full h-full">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                  >
                    <Dialog.Panel className="w-full h-full transform transition-all bg-black">
                      {/* Top bar */}
                      <div className="flex justify-between items-center h-[60px] border-b-[1px] border-grayDark px-5 w-full bg-black z-10">
                        <Icon
                          id="mobile-audioplayer-icon-close"
                          onClick={closeModal}
                          icon={Close}
                          color="white"
                          className="cursor-pointer z-10"
                        />
                        <p className="absolute left-0 text-center w-full text-white">
                          {selectedAudio?.name}
                        </p>
                      </div>

                      <div className="flex flex-col justify-between h-full mx-5 md:mx-section">
                        {/* Audio Image */}
                        {isBeat(selectedAudio!) && (
                          <div
                            id="mobile-audioplayer-div-trackImage"
                            onClick={() => {
                              closeModal();
                              router.push(
                                `/beats/${slugify(selectedAudio.name)}/${
                                  selectedAudio.id
                                }`
                              );
                            }}
                            className="flex w-full h-full items-center justify-center my-5 px-10 md:px-0"
                          >
                            <TrackArt
                              item={selectedAudio}
                              className="cursor-pointer rounded-3xl w-full md:w-fit md:h-full xs-h:h-[15vh] xs-h:w-[15vh] xs-h:rounded-lg"
                            />
                          </div>
                        )}
                        {isSong(selectedAudio!) && (
                          <div
                            id="mobile-audioplayer-div-songImage"
                            onClick={() => {
                              closeModal();
                              router.push(
                                `/songs/${slugify(selectedAudio.name)}/${
                                  selectedAudio.id
                                }`
                              );
                            }}
                            className="flex w-full h-full items-center justify-center my-5 px-10 md:px-0"
                          >
                            <TrackArt
                              item={selectedAudio}
                              className="cursor-pointer rounded-3xl w-full md:w-fit md:h-full xs-h:h-[15vh] xs-h:w-[15vh] xs-h:rounded-lg"
                            />
                          </div>
                        )}
                        {isSoundkit(selectedAudio!) && (
                          <div
                            id="mobile-audioplayer-div-soundkitImage"
                            onClick={() => {
                              closeModal();
                              router.push(
                                `/sounds/${slugify(selectedAudio.name)}/${
                                  selectedAudio.id
                                }`
                              );
                            }}
                            className="flex w-full h-full items-center justify-center my-5 px-10 md:px-0"
                          >
                            <KitArt
                              size={Size.lg}
                              item={selectedAudio}
                              className="cursor-pointer rounded-3xl w-full md:w-fit md:h-full xs-h:h-[15vh] xs-h:w-[15vh] xs-h:rounded-lg"
                            />
                          </div>
                        )}

                        <div className="flex h-[400px] flex-col justify-end mt-10 mb-10 xs-h:mt-0">
                          {/* Audio info */}
                          <div className="flex w-full mb-5">
                            <div className="truncate text-white">
                              <Link
                                id="mobile-audioplayer-text-trackName"
                                href={getViewObjectUrl(selectedAudio)}
                              >
                                <h4 onClick={closeModal}>
                                  {selectedAudio?.name}
                                </h4>
                              </Link>
                              <Link
                                id="mobile-audioplayer-text-trackCreator"
                                href={`/creators/${slugify(
                                  selectedAudio?.artist?.name
                                )}/${selectedAudio?.artist?.id}`}
                              >
                                <p
                                  onClick={closeModal}
                                  className="text-grayLight mt-2 text-sm"
                                >
                                  {selectedAudio?.artist?.name}
                                </p>
                              </Link>
                            </div>
                            {/*<button onClick={handleAddFavourite}>
                        <Icon icon={Heart} color="white" />
                      </button>
                      */}
                          </div>

                          {/* Waveform Progress Bar */}
                          <div className="flex w-full justify-center">
                            <WaveProgressSlider
                              id="mobile-audioplayer-trackProggress"
                              item={selectedAudio as ViewObject}
                              duration={duration}
                              onSlide={handleChange}
                              className="flex h-[90px]"
                            />
                          </div>

                          {/* Controls */}
                          <div className="mt-10 xs-h:mt-5">
                            {/* Play/Pause */}
                            <div
                              className=" flex items-center justify-center space-x-[60px]"
                              id="test-id"
                            >
                              <button
                                id="mobile-audioplayer-button-prev"
                                onClick={playPrevious}
                                className="flex h-big w-big"
                              >
                                <Icon
                                  icon={Previous}
                                  color="white"
                                  size={40}
                                  className="mb-2"
                                />
                              </button>

                              <button
                                id="mobile-audioplayer-button-playPause"
                                onClick={(e: any) => {
                                  e.stopPropagation();
                                  togglePlayPause();
                                  console.log("e: ", e.target.id);
                                }}
                                className="flex justify-center items-center rounded-md bg-green h-[60px] w-[60px] rotate-45 "
                              >
                                <Icon
                                  icon={playing ? Pause : Play}
                                  className="-rotate-45"
                                  size={40}
                                />
                              </button>
                              <button
                                id="mobile-audioplayer-button-next"
                                onClick={playNext}
                                className="flex h-big w-big"
                              >
                                <Icon
                                  icon={Next}
                                  color="white"
                                  className="mb-2"
                                  size={40}
                                />
                              </button>
                            </div>

                            {/* Actions */}
                            <div className="flex justify-center w-full">
                              <div className="flex justify-between items-center w-full h-medium mb-10 mt-10 xs-h:mt-5">
                                <Icon
                                  id="mobile-audioplayer-icon-volume"
                                  onClick={() =>
                                    setVolumeVisible(!volumeVisible)
                                  }
                                  icon={
                                    volumeLevel === 0
                                      ? VolumeMute
                                      : volumeLevel > 50
                                      ? VolumeUp
                                      : VolumeDown
                                  }
                                  color="white"
                                  className="h-[30px] w-[30px] cursor-pointer"
                                />
                                {volumeVisible && (
                                  <div className="flex w-[75%] items-center">
                                    <Slider
                                      value={volumeLevel}
                                      onChange={(event, value) => {
                                        audioPlayer.current
                                          ? (audioPlayer.current.volume =
                                              (value as number) / 100)
                                          : null;
                                        setVolumeLevel(value as number);
                                      }}
                                      sx={{
                                        color: "#1DF364",
                                        "& .MuiSlider-rail": {
                                          color: "white",
                                        },
                                        "& .MuiSlider-thumb": {
                                          height: 10,
                                          width: 10,
                                          transform:
                                            "rotate(45deg) translate(-7px, 0)",
                                          borderRadius: 0,
                                          backgroundColor: "#1DF364",
                                          "&:before": {
                                            display: "none",
                                          },
                                        },
                                      }}
                                    />
                                  </div>
                                )}

                                {!router.pathname.includes("audiolab") && (
                                  <Icon
                                    id="mobile-audioplayer-icon-cart"
                                    onClick={openPrice}
                                    icon={AddCart}
                                    color="white"
                                    className="h-[30px] w-[30px] cursor-pointer"
                                  />
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </Dialog>
          </Transition>
        </div>
      </div>
    </div>
  );
};

export default AudioPlayer;
